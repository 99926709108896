import React from 'react';
import styled from 'styled-components';

import { ChartOptionsMenuIcon } from 'components/Icons';

interface LeftOptionButtonProps {
  data?: any;
}
const LeftOptionButton = ({ data }: LeftOptionButtonProps) => {
  return (
    <LeftOptionButton.Button>
      <ChartOptionsMenuIcon />
    </LeftOptionButton.Button>
  );
};

LeftOptionButton.Button = styled.button`
  border: none;
  height: 100%;
  width: 15px;
  border-right: ${props => props.theme.borders.thingray};
  padding: 3px;
  outline: none;
`;

export default LeftOptionButton;
