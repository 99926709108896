import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';

const SPACE_RE = /\s/g;

const getRowTextMap = (rowData, titleName) => {
  if (!rowData.matched || (rowData.matched && R.isEmpty(rowData.matched)))
    return {
      beforeUnderlined: rowData[titleName],
      underlined: '',
      afterUnderlined: '',
    };
  const { matched } = rowData;
  const title = rowData[titleName];
  const beforeUnderlined = title
    .substring(0, matched[0])
    .replace(SPACE_RE, String.fromCharCode(160));
  const underlined = title
    .substring(matched[0], matched[1])
    .replace(SPACE_RE, String.fromCharCode(160));
  const afterUnderlined = title
    .substring(matched[1])
    .replace(SPACE_RE, String.fromCharCode(160));

  return { beforeUnderlined, underlined, afterUnderlined };
};

type RowProps = {
  className: string;
  index: number;
  onRowClick: (data: {
    event: MouseEvent;
    index: number;
    rowData: Record<string, any>;
  }) => void;
  rowData: Record<string, any>;
  style: { [prop: string]: string };
  firstColDataKey: string;
  secondColDataKey: string;
  hasColorEffects?: boolean;
  isHighlighted?: boolean;
  useHTMLTitle?: boolean;
};

const Row = ({
  className,
  index,
  isHighlighted,
  onRowClick,
  rowData,
  style,
  firstColDataKey,
  secondColDataKey,
  hasColorEffects,
  useHTMLTitle = true,
}: RowProps) => {
  const rowTextForAllWells = {
    beforeUnderlined: 'All',
    underlined: '',
    afterUnderlined: '',
  };
  const valueRowText =
    rowData[firstColDataKey] === 'all'
      ? rowTextForAllWells
      : getRowTextMap(rowData, firstColDataKey);
  // @ts-expect-error
  const clickHandler = onRowClick ? onRowClick : R.F;
  const rowStyle = isHighlighted
    ? style
    : { ...style, backgroundColor: rowData.color || 'transparent' };

  return (
    <div
      aria-rowindex={index}
      aria-label="row"
      tabIndex={0}
      className={className}
      role="row"
      style={rowStyle}
      onClick={event => clickHandler({ event, index, rowData })}
    >
      <Row.FirstCol
        aria-colindex="1"
        className="ReactVirtualized__Table__rowColumn"
        role="gridcell"
        title={useHTMLTitle ? rowData[firstColDataKey] : undefined}
      >
        {
          <>
            <span>{valueRowText.beforeUnderlined}</span>
            <Row.SearchResult className="ReactVirtualized__Table__rowSearchResult">
              {valueRowText.underlined}
            </Row.SearchResult>
            <span>{valueRowText.afterUnderlined}</span>
          </>
        }
      </Row.FirstCol>

      <Row.SecondCol
        aria-colindex="2"
        className="ReactVirtualized__Table__rowColumn"
        role="gridcell"
        title={useHTMLTitle ? rowData[secondColDataKey] : undefined}
        hasColorEffects={hasColorEffects}
        isHighlighted={isHighlighted}
        positive={rowData[secondColDataKey] > 0}
      >
        {hasColorEffects
          ? rowData[secondColDataKey].toFixed(1)
          : rowData[secondColDataKey]}
      </Row.SecondCol>
    </div>
  );
};

Row.Col = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: 12px;
`;

Row.FirstCol = styled(Row.Col)`
  flex: 1 1 140px;
`;

Row.SecondCol = styled(Row.Col)`
  flex: 0 0 50px;
  font-weight: ${(props: Record<string, any>) =>
    props.hasColorEffects ? (props.positive ? 'normal' : 'bold') : 'normal'};
`;

Row.SearchResult = styled.span`
  font-weight: bold;
  text-decoration: underline;
`;

Row.Span = styled.span`
  white-space: pre;
`;

export default Row;
