export const calculateVarianceTotalColumn = (
  list,
  firstColDataKey,
  secondColDataKey,
  thirdColDataKey,
  fourthColDataKey,
) => {
  return list.reduce(
    (acc, tableItem) => {
      acc[secondColDataKey] = Number(acc[secondColDataKey]);
      acc[thirdColDataKey] = acc[thirdColDataKey] + tableItem[thirdColDataKey];
      acc[fourthColDataKey] =
        acc[fourthColDataKey] + tableItem[fourthColDataKey];
      return acc;
    },
    {
      [firstColDataKey]: 'TOTAL',
      [secondColDataKey]: 0,
      [thirdColDataKey]: 0,
      [fourthColDataKey]: 0,
      color: '#ffffff',
      varianceOptionId: 'none',
    },
  );
};
