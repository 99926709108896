import { utcHour } from 'd3-time';
import * as R from 'ramda';

import type { CapacityChangeEvent } from '../models/capacityChangeEvent';

/**
 * Count the rate of capacity for certain date
 * @param {Date} date, for with capacity is calculated
 * @param {Object} capacityEvent - capacity change event, basis for calculations
 * @param {phasePrefix} string - phase name in lowercase
 * @returns {number} - capacity rate
 */
const countCapacity = (
  date: Date,
  capacityEvent: CapacityChangeEvent,
  phasePrefix: string,
): number => {
  if (!capacityEvent || R.isEmpty(capacityEvent)) return 0;
  const { dayInit } = capacityEvent;
  const rateInit = capacityEvent[`${phasePrefix}RateInit`];
  const declineInitDailyNom =
    capacityEvent[`${phasePrefix}DeclineInitDailyNom`];
  const bFactor = capacityEvent[`${phasePrefix}BFactor`];

  const daysElapsed = utcHour.count(dayInit, date) / 24;
  if (declineInitDailyNom < 0)
    return rateInit - declineInitDailyNom * daysElapsed || 0;
  if (bFactor > 0)
    return (
      rateInit /
        Math.pow(
          1 + bFactor * declineInitDailyNom * daysElapsed,
          1 / bFactor,
        ) || 0
    );

  return rateInit / Math.exp(declineInitDailyNom * daysElapsed) || 0;
};

export default countCapacity;
