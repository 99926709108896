import type { Filter } from 'modules/filter/models/filter';
import type { DrilldownTableParams } from 'modules/drilldownTable/models/drilldownTable';

export interface Settings {
  drilldownTableParams: DrilldownTableParams;
  extremeDates: { min: Date; max: Date };
  filters?: Filter;
  ui: {
    [key: string]: any;
  };
}

export const sharedUISettings = [
  'notesProductionDay',
  'allocIssuesVisibility',
  'trellises',
  'groupMode',
  'currentGroup',
  'currentWellId',
  'activePanel',
  'rightPanelDialog',
  'rightPanelDialogs',
  'rightPanelExpanded',
  'ribbons',
];
