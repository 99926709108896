import { utcDay } from 'd3-time';
import * as React from 'react';

import type { CapacityChangeEvent } from 'modules/capacityChangeEvent/models/capacityChangeEvent';
import { Y_AXIS_WIDTH } from 'modules/chart/models/chart';

interface CapacityDividerProps {
  capacityDialog: { show: boolean; index: number };
  capacityEvent: CapacityChangeEvent;
  extraPositions: number[];
  finishDrag: () => void;
  height: number;
  isEditable: boolean;
  isDragging: boolean;
  leftOffset: number;
  onCapacityDialogOpen: (index: number, eventId: string) => void;
  onDayInitChange: ({ capacityEventId: string, newDayInit: Date }) => void;
  onDividerHover: (eventId: number) => void;
  onHighlightCapacityDividerOff: () => void;
  pointIndex: number;
  startDrag: () => void;
  xScale: any;
}

const CapacityDivider = ({
  capacityDialog,
  capacityEvent,
  extraPositions,
  finishDrag,
  height,
  isEditable,
  isDragging,
  leftOffset,
  onCapacityDialogOpen,
  onDayInitChange,
  onDividerHover,
  onHighlightCapacityDividerOff,
  pointIndex,
  startDrag,
  xScale,
}: CapacityDividerProps) => {
  const handleMouseMove = React.useCallback(
    (e: MouseEvent) => {
      const pointerPosition = e.clientX - 10 - leftOffset - Y_AXIS_WIDTH;

      if (pointerPosition < extraPositions[0]) {
        const newDayInit = utcDay.round(xScale.invert(extraPositions[0]));
        onDayInitChange({ newDayInit, capacityEventId: capacityEvent.id });

        return;
      } else if (pointerPosition > extraPositions[1]) {
        const newDayInit = utcDay.round(xScale.invert(extraPositions[1]));
        onDayInitChange({ newDayInit, capacityEventId: capacityEvent.id });

        return;
      }

      const newDayInit = utcDay.round(xScale.invert(pointerPosition));
      onDayInitChange({ newDayInit, capacityEventId: capacityEvent.id });
    },
    [onDayInitChange, extraPositions, capacityEvent.id, xScale, leftOffset],
  );

  const handleMouseUp = React.useCallback(() => {
    finishDrag();
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  }, [finishDrag, handleMouseMove]);

  const handleMouseDown = React.useCallback(
    e => {
      if (isEditable) {
        startDrag();
        if (!capacityDialog.show || capacityDialog.index !== pointIndex) {
          onCapacityDialogOpen(pointIndex, capacityEvent.id);
        }
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
      } else {
        onCapacityDialogOpen(pointIndex, capacityEvent.id);
      }
    },
    [
      capacityEvent,
      capacityDialog.index,
      capacityDialog.show,
      handleMouseMove,
      handleMouseUp,
      isEditable,
      onCapacityDialogOpen,
      pointIndex,
      startDrag,
    ],
  );

  React.useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <g>
      <line
        x1={0}
        y1={0}
        x2={0}
        y2={height}
        transform={`translate(${xScale(capacityEvent.dayInit)}, 0)`}
        strokeWidth="15"
        stroke="transparent"
        onMouseMove={() => (isDragging ? null : onDividerHover(pointIndex))}
        onMouseLeave={() =>
          isDragging ? null : onHighlightCapacityDividerOff()
        }
        vectorEffect="non-scaling-stroke"
        onMouseDown={e => handleMouseDown(e)}
        cursor={isEditable ? 'ew-resize' : 'pointer'}
        className={isEditable ? 'capacity-interactive interactive' : ''}
      />
    </g>
  );
};

export default React.memo<CapacityDividerProps>(CapacityDivider);
