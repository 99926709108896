import { CircularProgress } from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';

import type { AppConfig } from 'modules/appConfig/models/appConfig';
import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';
import type { User } from 'modules/user/models/user';
import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';

import AssigneeForm from './AssigneeForm';
import CauseForm from './CauseForm';
import DateRangeForm from './DateRangeForm';
import StatusForm from './StatusForm';
import PhasesForm from './PhasesForm';

interface EditAllocIssueFormProps {
  allocIssue: AllocIssue;
  allocIssues: AllocIssue[];
  allocIssueCauses: { [id: string]: { id: string; cause: string } };
  allocIssueStatuses: { [id: string]: { id: string; status: string } };
  appConfig: AppConfig;
  disabled: boolean;
  hasDataForIssueCalc: boolean;
  onAllocIssueUpdate: ({ updatedIssue: AllocIssue, data: Object }) => void;
  users: { [userId: string]: User };
  varianceEvents: VarianceEvent[];
}

const EditAllocIssueForm = ({
  allocIssue,
  allocIssues,
  allocIssueCauses,
  allocIssueStatuses,
  appConfig,
  disabled,
  hasDataForIssueCalc,
  onAllocIssueUpdate,
  users,
  varianceEvents,
}: EditAllocIssueFormProps) => {
  const wellAllocIssues = allocIssues.filter(
    issue => issue.wellId === allocIssue.wellId,
  );
  return (
    <EditAllocIssueForm.Container>
      <EditAllocIssueForm.SectionHeader>
        Date Range
      </EditAllocIssueForm.SectionHeader>
      {hasDataForIssueCalc ? (
        <DateRangeForm
          allocIssue={allocIssue}
          allocIssues={wellAllocIssues}
          appConfig={appConfig}
          disabled={disabled}
          onAllocIssueUpdate={onAllocIssueUpdate}
          varianceEvents={varianceEvents}
        />
      ) : (
        <CircularProgress size={24} thickness={4} />
      )}
      <EditAllocIssueForm.SectionHeader>
        Issue Phases
      </EditAllocIssueForm.SectionHeader>
      {hasDataForIssueCalc ? (
        <PhasesForm
          allocIssue={allocIssue}
          disabled={disabled}
          onAllocIssueUpdate={onAllocIssueUpdate}
        />
      ) : (
        <CircularProgress size={24} thickness={4} />
      )}
      <EditAllocIssueForm.SectionHeader>
        Assignee
      </EditAllocIssueForm.SectionHeader>
      <AssigneeForm
        allocIssue={allocIssue}
        disabled={disabled}
        onAllocIssueUpdate={onAllocIssueUpdate}
        users={users}
      />
      <EditAllocIssueForm.SectionHeader>
        Status
      </EditAllocIssueForm.SectionHeader>
      <StatusForm
        allocIssue={allocIssue}
        allocIssueStatuses={allocIssueStatuses}
        disabled={disabled}
        onAllocIssueUpdate={onAllocIssueUpdate}
      />
      <CauseForm
        allocIssue={allocIssue}
        allocIssueCauses={allocIssueCauses}
        disabled={disabled}
        onAllocIssueUpdate={onAllocIssueUpdate}
      />
    </EditAllocIssueForm.Container>
  );
};

EditAllocIssueForm.Container = styled.div``;

EditAllocIssueForm.SectionHeader = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;

  &:not(:first-of-type) {
    margin-top: 15px;
  }
`;

export default React.memo<EditAllocIssueFormProps>(EditAllocIssueForm);
