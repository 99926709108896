import React from 'react';

export const ChartRectContext = React.createContext({
  width: 0,
  height: 0,
  leftOffset: 0,
  rightOffset: 0,
});

const useChartRect = () => {
  const chartRect = React.useContext(ChartRectContext);
  const { width, height, leftOffset, rightOffset } = chartRect;

  return {
    width,
    height,
    leftOffset,
    rightOffset,
  };
};

export default useChartRect;
