import { format as d3Format } from 'd3-format';

export const normalizeProductionLost = productionLost => {
  const itIsPlusNumber = productionLost >= 0;
  productionLost = Math.abs(productionLost);
  let result;
  if (productionLost < 1000) {
    result = d3Format('$,')(productionLost);
  } else if (productionLost >= 1000) {
    result = d3Format('$s')(productionLost).toUpperCase();
    result = result.replace(/^(.+\.)(\d).+(\w)$/, '$1$2$3');
  }
  return itIsPlusNumber ? result : '−' + result;
};
