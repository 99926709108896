import React from 'react';
import { throttle } from 'throttle-debounce';

const useThrottledCallback = (
  callback: (...args: any[]) => any,
  deps = [] as any[],
  time = 200,
  noTrailing = false,
) => {
  const throttled = React.useMemo(() => {
    return throttle(time, noTrailing, callback);
  }, deps);

  React.useEffect(() => {
    return () => throttled.cancel();
  }, []);

  return throttled;
};

export default useThrottledCallback;
