/**
 * Calculate decline effective from nominal
 * @param {number} declineInitDailyNom
 * @param {number} bFactor
 * @returns {number} - decline effective
 */
const convertDeclineNominalToEffective = (
  declineInitDailyNom: number,
  bFactor: number,
): number => {
  if (declineInitDailyNom < 0) return declineInitDailyNom;
  if (bFactor === 0) return (1 - Math.exp(-365 * declineInitDailyNom)) * 100;
  if (bFactor > 0)
    return (
      -1 *
      (Math.pow(365 * declineInitDailyNom * bFactor + 1, -1 / bFactor) - 1) *
      100
    );

  return declineInitDailyNom;
};

export default convertDeclineNominalToEffective;
