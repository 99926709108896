import * as React from 'react';
import styled from 'styled-components';

import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';
import type { AppConfig } from 'modules/appConfig/models/appConfig';
import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';

import DateInput from 'components/DateInput';
import { RightArrowIcon } from 'components/Icons';

import { getVarianceExtremeDates } from 'helpers';

interface DateRangeFormProps {
  appConfig: AppConfig;
  allocIssue: AllocIssue;
  allocIssues: AllocIssue[];
  disabled: boolean;
  onAllocIssueUpdate: (date: {
    updatedIssue: AllocIssue;
    data: Record<string, any>;
  }) => void;
  varianceEvents: VarianceEvent[];
}

const DateRangeForm = ({
  appConfig,
  allocIssue,
  allocIssues,
  disabled,
  onAllocIssueUpdate,
  varianceEvents,
}: DateRangeFormProps) => (
  <form>
    <DateRangeForm.Layout>
      <DateInput
        id="issueDateStart"
        disabled={disabled}
        name="issueDateStart"
        date={allocIssue.dateStart}
        min={
          getVarianceExtremeDates(
            allocIssues,
            varianceEvents,
            allocIssue.id,
            appConfig.today,
            'Allocation Issue',
          )[0]
        }
        max={allocIssue.dateEnd}
        onProcess={date =>
          onAllocIssueUpdate({
            updatedIssue: allocIssue,
            data: {
              dateStart: date,
            },
          })
        }
      />

      <RightArrowIcon />

      <DateInput
        id="issueDateEnd"
        disabled={disabled}
        name="issueDateEnd"
        date={allocIssue.dateEnd}
        min={allocIssue.dateStart}
        max={
          getVarianceExtremeDates(
            allocIssues,
            varianceEvents,
            allocIssue.id,
            appConfig.today,
            'Allocation Issue',
          )[1]
        }
        onProcess={date =>
          onAllocIssueUpdate({
            updatedIssue: allocIssue,
            data: {
              dateEnd: date,
            },
          })
        }
      />
    </DateRangeForm.Layout>
  </form>
);

DateRangeForm.Header = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 16px;
`;

DateRangeForm.Layout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
`;

export default React.memo<DateRangeFormProps>(DateRangeForm);
