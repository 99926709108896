import React from 'react';

type Callback = () => void;

type EventObject = {
  cancel: Callback;
  proceed: Callback;
};

interface Props {
  children: React.ReactNode;
  shouldIgnore: boolean;
}

interface ContextData {
  event: EventObject | null;
  dispatchEvent: (callback: Callback) => void;
}

const PreventContext = React.createContext<ContextData | undefined>(undefined);

const PreventProvider: React.FC<Props> = ({ children, shouldIgnore }) => {
  const [callback, setCallback] = React.useState<Callback | null>(null);

  const cancel = React.useCallback(() => setCallback(null), [setCallback]);

  const proceed = React.useCallback(() => {
    callback?.call(null);
    cancel();
  }, [cancel, callback]);

  const event = React.useMemo<EventObject | null>(() => {
    if (!callback) return null;

    return {
      cancel,
      proceed,
    };
  }, [callback, cancel, proceed]);

  const dispatchEvent = React.useCallback(
    (callback: Callback) => {
      if (shouldIgnore) {
        cancel();
        return callback();
      }

      setCallback(() => callback);
    },
    [cancel, setCallback, shouldIgnore],
  );

  const value = React.useMemo(
    () => ({
      event,
      dispatchEvent,
    }),
    [event, dispatchEvent],
  );

  return (
    <PreventContext.Provider value={value}>{children}</PreventContext.Provider>
  );
};

const usePreventContext = () => {
  const context = React.useContext(PreventContext);

  if (!context)
    throw new Error('PreventContext must be used within a PreventProvider!');

  return context;
};

export { PreventProvider, usePreventContext };
