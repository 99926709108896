import React from 'react';
import spotfireInputStorage from '../utils/spotfireInputStorage';

const useSpotfireInputStorage = () => {
  const [currentData, setCurrentData] = React.useState(
    spotfireInputStorage.get(),
  );

  React.useEffect(() => {
    return spotfireInputStorage.subscribe(data => {
      setCurrentData(data);
    });
  }, []);

  return {
    data: currentData,
    setData: spotfireInputStorage.set,
  };
};

export default useSpotfireInputStorage;
