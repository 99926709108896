import { LatestEventNote } from '../models';

export const normalizeLatestEventNotes = (notes: LatestEventNote[]) => {
  return notes.map(n => ({
    ...n,
    eventId: n.eventId.toString(),
    noteTimestamp: new Date(n.noteTimestamp),
    dateStart: new Date(n.dateStart),
    dateEnd: n.dateEnd ? new Date(n.dateEnd) : null,
  }));
};

export const areNotesEqual = (
  note1: LatestEventNote,
  note2: LatestEventNote,
) => {
  return note1.eventId === note2.eventId && note1.eventType === note2.eventType;
};

export const createRange = (from: number, to: number) =>
  Array.from({ length: to - from + 1 }, (_, idx) => idx + from);
