import * as React from 'react';
import styled from 'styled-components';

import { ChartOption } from '../models';
import RemoveCoreSeriesButton from './RemoveCoreseriesButton';
import ShowTrellisButton from './ShowTrellisButton';

interface CavSeriesMenuProps {
  series: ChartOption[];
  onToggleCavSeries: (data: { isShow: boolean; optionId: string }) => void;
  onRemoveCoreeries: (data: {
    isAvailable: boolean;
    isShow: boolean;
    optionId: string;
  }) => void;
}
const CavSeriesMenu = ({
  series,
  onToggleCavSeries,
  onRemoveCoreeries,
}: CavSeriesMenuProps) => {
  return (
    <>
      <CavSeriesMenu.Wrapper>
        <CavSeriesMenu.Container>
          {series.length > 0 ? (
            <CavSeriesMenu.OptionWrapper>
              {series.map((option, i) => (
                <CavSeriesMenu.ItemWrapper key={option.id} isFirst={i === 0}>
                  <CavSeriesMenu.RibbonName>
                    {option.title}
                  </CavSeriesMenu.RibbonName>
                  <CavSeriesMenu.ItemLabel>Core</CavSeriesMenu.ItemLabel>
                  <ShowTrellisButton
                    show={option.isShow}
                    onShowClick={() =>
                      onToggleCavSeries({
                        optionId: option.id,
                        isShow: !option.isShow,
                      })
                    }
                  />
                  <RemoveCoreSeriesButton
                    onClick={() =>
                      onRemoveCoreeries({
                        optionId: option.id,
                        isAvailable: !option.isAvailable,
                        isShow: false,
                      })
                    }
                  />
                </CavSeriesMenu.ItemWrapper>
              ))}
            </CavSeriesMenu.OptionWrapper>
          ) : null}
        </CavSeriesMenu.Container>
      </CavSeriesMenu.Wrapper>
    </>
  );
};

CavSeriesMenu.TrellisMenuLegend = styled.legend`
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin: 10px 0 7px;
`;

CavSeriesMenu.Container = styled.div`
  border: ${props =>
    props.isDragging ? props.theme.borders.thingray : 'none'};
  margin: ${props => (props.isDragging ? '0' : '1px 0')};
`;

CavSeriesMenu.ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 50px 25px 25px;
  border-top: ${props =>
    props.isFirst ? 'none' : props.theme.borders.thingray};
  align-items: center;
`;
CavSeriesMenu.Button = styled.button`
  padding: 0;
  min-height: 20px;
  height: 100%;
  border: none;
  border-left: ${props => props.theme.borders.thingray};
  outline: none;

  > svg {
    width: 15px;
  }
`;

CavSeriesMenu.OptionWrapper = styled.div`
  border: ${p => p.theme.borders.thingray};
  background-color: #efefef;
`;

CavSeriesMenu.RibbonName = styled.span`
  margin-left: 5px;
`;

CavSeriesMenu.Wrapper = styled.div`
  align-self: center;
  padding: 16px 16px 0 16px;
`;

CavSeriesMenu.ItemLabel = styled.span`
  color: #9a9a9a;
  margin-right: 5px;
  text-align: right;
`;

export default CavSeriesMenu;
