import { getDefaulDrilldownDates } from '../utils';

const checkIfDrilldownTableDatesAreDefaul = (
  startDate: Date,
  endDate: Date,
): boolean => {
  const dates = getDefaulDrilldownDates();
  return (
    new Date(startDate).getTime() === dates.minDate.getTime() &&
    new Date(endDate).getTime() === dates.maxDate.getTime()
  );
};
export default checkIfDrilldownTableDatesAreDefaul;
