import React from 'react';
import { TabInfo } from './Model';

interface TabsContextData {
  activeTab: string | null;
  open: (id: string) => void;
  setTabInfo: (id: string, info: TabInfo) => void;
}

const TabsContext = React.createContext<TabsContextData | undefined>(undefined);

export default TabsContext;
