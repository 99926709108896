import * as R from 'ramda';
import { NormalizedSeriesMapping } from 'modules/series/models';
import { ChartOption } from '../models';

const getSeriestColor = (
  option: ChartOption,
  seriesMapping: NormalizedSeriesMapping,
): string =>
  option.customColor || R.pathOr('#000', [option.id, 'color'], seriesMapping);

export default getSeriestColor;
