const isBetweenX = (
  clientX: number,
  xCoords: { x1: number; x2: number },
): boolean => {
  const { x1, x2 } = xCoords;

  return clientX < x2 && clientX > x1;
};

export default isBetweenX;
