import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';

import { linkify } from 'helpers';

import { END_TEXT_MENTION_REG_EXP } from 'modules/mentionNotification/models/mentionNotification';
import MentionWindow from 'modules/notes/components/MentionWindow';
import type { User } from 'modules/user/models/user';

import Button from 'components/Button';

const ENTER_KEYCODE = 13;

interface DetailsOverlayTextInputProps {
  onSubmit: (noteText: string) => Promise<any> | void;
  noteText: string;
  usersByEmailName: { [emailName: string]: User };
  setText: (text: string) => void;
  initialText: string;
  isEditing: boolean;
  setIsEditing: (data: boolean) => void;
}

const DetailsOverlayTextInput = ({
  onSubmit,
  noteText,
  usersByEmailName,
  setText,
  initialText,
  setIsEditing,
  isEditing,
}: DetailsOverlayTextInputProps) => {
  const textAreaEl = React.useRef<HTMLTextAreaElement>(null);
  const [mentionInput, setMentionInput] = React.useState('');
  const [mentionAnchorEl, setMentionAnchorEl] =
    React.useState<HTMLElement | null>(null);

  const [isShowEditButton, setIsShowEditButton] = React.useState(false);

  const handleTextInput = React.useCallback(
    (e: React.MouseEvent) => {
      const { target } = e;
      if (!(target instanceof window.HTMLTextAreaElement)) {
        return;
      }
      const { value } = target;
      const positionOfCaret = target.selectionStart;
      const textBeforeCaret = value.slice(0, positionOfCaret);

      if (END_TEXT_MENTION_REG_EXP.test(textBeforeCaret)) {
        const matchedStringArray = textBeforeCaret.match(
          END_TEXT_MENTION_REG_EXP,
        );
        setMentionAnchorEl(target);
        setText(value);
        setMentionInput(R.last(matchedStringArray));
      } else {
        setText(value);
        setMentionInput('');
        setMentionAnchorEl(null);
      }
    },
    [setText],
  );

  const handleMentionWindowClose = React.useCallback(() => {
    setMentionAnchorEl(null);
    if (textAreaEl.current && textAreaEl.current.setSelectionRange) {
      const positionOfCaret = textAreaEl.current.selectionStart;
      textAreaEl.current.setSelectionRange(positionOfCaret, positionOfCaret);
    }
  }, [setMentionAnchorEl]);

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      const { keyCode, ctrlKey } = e;
      if (keyCode === ENTER_KEYCODE && ctrlKey) onSubmit(noteText);
    },
    [noteText, onSubmit],
  );

  const handleMentionChoose = React.useCallback(
    async (emailName: string) => {
      if (textAreaEl.current) {
        const positionOfCaret = textAreaEl.current.selectionStart;
        const textBeforeCaret = noteText.slice(0, positionOfCaret);
        const textAfterCaret = noteText.slice(positionOfCaret);
        const newTextAreaValueBeforeCaret = textBeforeCaret.replace(
          END_TEXT_MENTION_REG_EXP,
          `@${emailName} `,
        );
        const newTextAreaValue = newTextAreaValueBeforeCaret + textAfterCaret;
        await setText(newTextAreaValue);
        setMentionAnchorEl(null);
        const newCaretPos =
          newTextAreaValue.search(emailName) + emailName.length + 1;
        if (textAreaEl.current && textAreaEl.current.setSelectionRange) {
          textAreaEl.current.setSelectionRange(newCaretPos, newCaretPos);
        }
      }
    },
    [noteText, setText, textAreaEl],
  );

  React.useEffect(() => {
    textAreaEl?.current?.focus();
    textAreaEl?.current?.setSelectionRange(
      textAreaEl.current.value.length,
      textAreaEl?.current?.value.length,
    );
  }, []);

  const handleSubmitWithCheck = React.useCallback(() => {
    onSubmit(noteText);
    textAreaEl.current?.blur();

    setIsEditing(false);
  }, [noteText, onSubmit, textAreaEl, setIsEditing]);

  const onBlurHandler = React.useCallback(() => {
    if (noteText === initialText) {
    }
    if (noteText.trim() === '') {
      setIsEditing(false);
    }
  }, [noteText, setIsEditing, initialText]);

  return (
    <DetailsOverlayTextInput.Container
      onMouseEnter={() => {
        !isShowEditButton && setIsShowEditButton(true);
      }}
      onMouseLeave={() => {
        isShowEditButton && setIsShowEditButton(false);
      }}
    >
      <DetailsOverlayTextInput.Form>
        <DetailsOverlayTextInput.FormWrapper>
          {isEditing ? (
            <DetailsOverlayTextInput.Textarea
              ref={textAreaEl}
              maxLength="2000"
              rows="1"
              value={noteText}
              onChange={handleTextInput}
              onKeyDown={e => handleKeyDown(e)}
              placeholder="Write a note..."
              onFocus={() => setIsEditing(true)}
              onBlur={onBlurHandler}
              disabled={!isEditing}
            />
          ) : (
            <DetailsOverlayTextInput.Text
              dangerouslySetInnerHTML={{ __html: linkify(noteText) }}
            />
          )}
          {noteText !== '' && isEditing ? (
            <DetailsOverlayTextInput.Controls>
              <DetailsOverlayTextInput.Button
                width={70}
                onClick={() => {
                  handleSubmitWithCheck();
                }}
                disabled={noteText === '' || noteText === initialText}
              >
                Save Note
              </DetailsOverlayTextInput.Button>
            </DetailsOverlayTextInput.Controls>
          ) : null}
        </DetailsOverlayTextInput.FormWrapper>
        {mentionAnchorEl && (
          <MentionWindow
            mentionInput={mentionInput}
            usersByEmailName={usersByEmailName}
            anchorEl={mentionAnchorEl}
            handleClose={handleMentionWindowClose}
            handleMentionChoose={handleMentionChoose}
            isShowOnRight
          />
        )}
      </DetailsOverlayTextInput.Form>
    </DetailsOverlayTextInput.Container>
  );
};

DetailsOverlayTextInput.Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

DetailsOverlayTextInput.FormWrapper = styled.div`
  background: #fff;
  border: 1px solid #c1c1c1;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
`;

DetailsOverlayTextInput.Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Lato', sans-serif;
  position: relative;
  height: 100%;
`;

DetailsOverlayTextInput.EditButtonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

DetailsOverlayTextInput.EditButton = styled.div`
  position: absolute;
  top: 7px;
  right: 12px;
`;

DetailsOverlayTextInput.Textarea = styled.textarea`
  padding: 10px 10px 6px;
  width: 100%;
  height: 0px;
  height: 100%;
  border: none;
  resize: none;
  max-height: 70px;
  :disabled {
    background: white;
  }
  :focus {
    outline: 0;
  }
`;

DetailsOverlayTextInput.Text = styled.div`
  padding: 10px 10px 6px;
  width: 100%;
  height: 0px;
  height: 100%;
  border: none;
  resize: none;
  max-height: 55px;
  > a {
    color: #1890ff !important;
    text-decoration: auto;
  }
`;

DetailsOverlayTextInput.Controls = styled.div`
  width: 100%;
  padding: 0 5px 5px 0;
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
`;

DetailsOverlayTextInput.Button = styled(Button)`
  font-size: 11px;
  max-height: 25px;
`;

export default DetailsOverlayTextInput;
