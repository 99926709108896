import { matchPath } from 'react-router-dom';
import history from './history';

// prettier-ignore
const paths = {
  AUTH_CALLBACK: () => '/auth_callback',
  AUTH_LOGIN: () => '/login',
  AUTH_LOGIN_INTERNAL: () => '/login-internal',
  AUTH_LOGOUT: () => '/logout',
  AUTH_PASSWORD_CHANGE: () => '/changePassword',
  AUTH_PASSWORD_RESET: (token = ':token?') => `/accounts/reset/${token}`,
  SHARE: (shareId = ':shareId') => `/share/${shareId}`,
  DASHBOARD: () => '/dashboard',
  DASHBOARD_FOR_WELL: (wellId = ':wellId') => `/dashboard/${wellId}`,
  DASHBOARD_FOR_GROUP: (subject = ':groupSubject', item = ':groupItem') => `/dashboard/${subject}/${item}`,
  FILTER: () => '/filter',
  BACKLOG: () => '/backlog',
  USER_MANAGEMENT: () => '/user-management',
  SPOTFIRE_DASHBOARDS: () => '/analytics/dashboards',
  SPOTFIRE_DASHBOARD: (id = ':id') => `/analytics/dashboards/${id}`,
};

const pathMatches = (
  pathKey: keyof typeof paths,
  options?: { exact?: boolean; args?: string[] },
) => {
  const pathStringOrFunc = paths[pathKey];
  const shouldMatchExactly = options?.exact ?? true;

  let path: string;
  if (typeof pathStringOrFunc === 'string') {
    path = pathStringOrFunc;
  } else if (typeof pathStringOrFunc === 'function') {
    path = pathStringOrFunc(...(options?.args || []));
  } else {
    return false;
  }

  const result = matchPath(path, history.location.pathname);

  if (!result) return false;
  if (shouldMatchExactly) return result.isExact;
  return true;
};

export { pathMatches };
export default paths;
