import styled from 'styled-components';

const TitledDivider = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  padding-bottom: 7px;
  border-bottom: 1px solid #c1c1c1;
  margin: 20px 0 12px;
`;

export default TitledDivider;
