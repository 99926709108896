export type Phase = {
  id: string;
  phaseName: string;
};

export const OIL = 'Oil';
export const GAS = 'Gas';
export const WATER = 'Water';

export const BOE = 'BOE';
export const GOR = 'GOR';
export const WATERCUT = 'Watercut';
export const TOTAL_LIQUID = 'Total_Liquid';

export const GLR = 'GLR';

export const INDICATORS = {
  [BOE]: [OIL, GAS],
  [GOR]: [OIL, GAS],
  [WATERCUT]: [OIL, WATER],
  [GLR]: [OIL, GAS, WATER],
  [TOTAL_LIQUID]: [OIL, WATER],
};

export const ALL_PHASES_LIST = [OIL, GAS, WATER];
export const ALL_PHASES_LIST_LOWERCASE = [
  OIL.toLowerCase(),
  GAS.toLowerCase(),
  WATER.toLowerCase(),
];
