import { utcHour } from 'd3-time';
import * as R from 'ramda';

import { OIL, GAS, WATER } from 'modules/phase/models/phase';

import type { CapacityChangeEvent } from '../models/capacityChangeEvent';
import countCapRateDecline from './countCapRateDecline';

const modifyCapacityChangeEventDay = ({
  sourceCapEvent,
  newDayInit,
  defaultRate,
  defaultCategoryId,
}: {
  sourceCapEvent: CapacityChangeEvent;
  newDayInit: Date;
  defaultRate: Record<string, any>;
  defaultCategoryId: string;
}): CapacityChangeEvent => {
  const {
    oilDeclineInitDailyNom,
    oilRateInit,
    oilBFactor,
    gasDeclineInitDailyNom,
    gasRateInit,
    gasBFactor,
    waterDeclineInitDailyNom,
    waterRateInit,
    waterBFactor,
    dayInit,
  } = sourceCapEvent;
  const isLockSlopeCapacityLine = R.pathOr<
    Record<string, any>,
    Record<string, any>,
    Record<string, any>,
    Record<string, any>
  >(false, ['isLockSlopeCapacityLine'], sourceCapEvent);
  const daysElapsed = utcHour.count(dayInit, newDayInit) / 24;

  const oilCapParams = countCapRateDecline({
    daysElapsed,
    rateInit: oilRateInit,
    declineInitDailyNom: oilDeclineInitDailyNom,
    bFactor: oilBFactor,
    defaultRate: defaultRate[OIL],
  });
  const gasCapParams = countCapRateDecline({
    daysElapsed,
    rateInit: gasRateInit,
    declineInitDailyNom: gasDeclineInitDailyNom,
    bFactor: gasBFactor,
    defaultRate: defaultRate[GAS],
  });
  const waterCapParams = countCapRateDecline({
    daysElapsed,
    rateInit: waterRateInit,
    declineInitDailyNom: waterDeclineInitDailyNom,
    bFactor: waterBFactor,
    defaultRate: defaultRate[WATER],
  });

  return {
    ...sourceCapEvent,
    capacityChangeOptionId: defaultCategoryId,
    extraInputsData: null,
    dayInit: newDayInit,
    oilRateInit: oilCapParams.rateInit,
    gasRateInit: gasCapParams.rateInit,
    waterRateInit: waterCapParams.rateInit,
    oilDeclineInitDailyNom: oilCapParams.declineInitDailyNom,
    gasDeclineInitDailyNom: gasCapParams.declineInitDailyNom,
    waterDeclineInitDailyNom: waterCapParams.declineInitDailyNom,
    notes: '',
    isLockSlopeCapacityLine,
  };
};

export default modifyCapacityChangeEventDay;
