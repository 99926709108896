import { CountableTimeInterval, utcMillisecond } from 'd3-time';

const handleXAxisScaling = (
  e: WheelEvent,
  svgEl: { current: Element | null } | null,
  extremeDates: { min: Date; max: Date },
  changeTwoExtremeDates: (dates: { min: Date; max: Date }) => void,
  xScale: any,
  timeInterval: CountableTimeInterval,
) => {
  if (svgEl && svgEl.current) {
    const chartSizes = svgEl.current.getBoundingClientRect();
    const { pageX } = e;
    const cursorPosition = pageX - chartSizes.left;

    const delta = e.deltaY || e.deltaX;
    const coefficient = cursorPosition / chartSizes.width;
    const sign = Math.sign(delta);
    const offset =
      sign *
      (Math.abs(
        utcMillisecond.count(
          xScale.invert(0),
          xScale.invert(chartSizes.width * 0.2),
        ),
      ) +
        1);
    const minDateOffset = -Math.round(offset * coefficient);
    const maxDateOffset = Math.round(offset - offset * coefficient);

    const newMinDate = utcMillisecond.offset(extremeDates.min, minDateOffset);
    const newMaxDate = utcMillisecond.offset(extremeDates.max, maxDateOffset);
    const dif = timeInterval.count(newMinDate, newMaxDate);
    if (dif <= 0) {
      return;
    }
    changeTwoExtremeDates({ min: newMinDate, max: newMaxDate });
  }
};

export default handleXAxisScaling;
