import { RibbonEventData } from 'modules/ribbon/models';

const stackRibbonEventsVertically = (ribbonEvents: RibbonEventData[]) => {
  return ribbonEvents
    .sort((a, b) => a.currentBounds.x1 - b.currentBounds.x1)
    .reduce((acc, item) => {
      while (intersects(item, acc)) item.currentBounds.yLevel += 1;
      return acc.concat(item);
    }, [] as RibbonEventData[]);
};

const intersects = (item: RibbonEventData, stacks: RibbonEventData[]) =>
  !stacks.every(
    stackedItem =>
      stackedItem.currentBounds.yLevel !== item.currentBounds.yLevel ||
      stackedItem.currentBounds.x2 <= item.currentBounds.x1 ||
      stackedItem.currentBounds.x1 >= item.currentBounds.x2,
  );

export default stackRibbonEventsVertically;
