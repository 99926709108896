import React from 'react';
import styled from 'styled-components';

type DragHandleProps = {
  top?: number;
  isDragging?: boolean;
  onMouseDown?: () => void;
  data?: Record<string, string | number | undefined>;
};

const DragHandle = ({
  top = 0,
  isDragging,
  onMouseDown,
  data = {},
}: DragHandleProps) => {
  const dataAttrs = React.useMemo(() => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      acc[`data-${key}`] = value;
      return acc;
    }, {});
  }, [data]);

  return (
    <DragHandle.Container
      onMouseDown={onMouseDown}
      top={top}
      isDragging={isDragging}
      {...dataAttrs}
    >
      <DragHandle.Indicator />
    </DragHandle.Container>
  );
};

DragHandle.Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: ${({ top }) => top + 'px'};
  transform: translate(-50%, -50%);
  width: 54px;
  height: 12px;
  background: linear-gradient(180deg, #f3f3f3 0%, #e1e1e1 100%);
  border: 1px solid #c1c1c1;
  cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : 'grab')};
`;

DragHandle.Indicator = styled.div`
  width: 20px;
  height: 4px;
  border-bottom: 1px solid #000;
  border-top: 1px solid #000;
`;

export default DragHandle;
