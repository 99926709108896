import React from 'react';
import styled from 'styled-components';
import useComponentSize from './useComponentSize';

interface Draggable {
  item: HTMLElement | null;
  index: number;
  id: string;
}

interface Item {
  index: number;
  id: string;
}

interface EndDragData {
  source: Item;
  target: Item;
}

interface DragContextData {
  backlightX: number;
  backlightWidth: number;
  dropIndex: number;
  dropData: Item | null;
  draggable: Draggable | null;
  mouseX: number;
  scrollX: number;
  shouldDisplayIndicator: boolean;
  setShouldDisplayIndicator: React.Dispatch<React.SetStateAction<boolean>>;
  setDraggable: React.Dispatch<React.SetStateAction<Draggable | null>>;
  setDropData: React.Dispatch<React.SetStateAction<Item | null>>;
  setDropIndex: React.Dispatch<React.SetStateAction<number>>;
  setIndicatorData: React.Dispatch<
    React.SetStateAction<{ index: number; x: number }>
  >;
  setPivotX: React.Dispatch<React.SetStateAction<number>>;
  isDragging: boolean;
}
const DragContext = React.createContext<DragContextData | undefined>(undefined);

interface ShadowDragProps {
  canDrag?: boolean;
  end?: (data: EndDragData) => void;
  item: Item;
  start?: (data: Draggable) => void;
}

export const useShadowDrag = (
  { canDrag = true, end, item, start }: ShadowDragProps,
  deps: any[] | undefined = undefined,
) => {
  const draggable = React.useRef<HTMLElement | null>(null);
  const dragHandle = React.useRef<HTMLElement | null>(null);
  const dragContext = React.useContext(DragContext);
  const [isDragging, setIsDragging] = React.useState(false);
  const [isMousePressed, setIsMousePressed] = React.useState(false);
  const pivotOffset = React.useMemo(() => {
    if (!draggable.current) return;
    const containerLeft = draggable.current.getBoundingClientRect().left;
    const handleLeft = dragHandle.current?.getBoundingClientRect()?.left;

    if (!handleLeft) return 0;
    return handleLeft - containerLeft;
  }, [dragHandle.current, draggable.current]);
  if (!dragContext)
    throw new Error('useShadowDrag should be used within ShadowDragArea!');

  const mouseUpHandler = React.useCallback(() => {
    setIsMousePressed(false);
    if (!isDragging) return;
    setIsDragging(false);

    end?.call(null, {
      source: item,
      target: dragContext.dropData ?? { id: '', index: -1 },
    });
    dragContext.setDraggable(null);
    dragContext.setShouldDisplayIndicator(false);
  }, [
    item,
    dragContext.dropData,
    dragContext.setDraggable,
    isDragging,
    end,
    setIsDragging,
    setIsMousePressed,
    dragContext.setShouldDisplayIndicator,
  ]);

  const mouseDownHandler = React.useCallback(
    e => {
      if (e.button !== 0) return;
      setIsMousePressed(true);
    },
    [setIsMousePressed],
  );

  const mouseMoveHandler = React.useCallback(
    e => {
      if (!draggable.current) return;
      start?.call(null, { item: draggable.current, ...item });
      dragContext.setDraggable({ item: draggable.current, ...item });
      dragContext.setPivotX(e.offsetX + pivotOffset);
      setIsDragging(true);
    },
    [
      item,
      draggable.current,
      dragContext.setPivotX,
      dragContext.setDraggable,
      pivotOffset,
      setIsDragging,
      start,
      mouseUpHandler,
    ],
  );

  React.useEffect(() => {
    if (!draggable.current || !dragContext.isDragging) return;

    const itemX = draggable.current.offsetLeft;
    const itemWidth = draggable.current.offsetWidth;
    const focusedX = dragContext.mouseX;

    if (focusedX >= itemX && focusedX < itemX + itemWidth) {
      const difference = focusedX - itemX;
      const isCursorAtLeftPart = difference <= itemWidth / 2;
      const newIndicatorX = isCursorAtLeftPart ? itemX : itemX + itemWidth;
      const newIndicatorIndex = isCursorAtLeftPart
        ? item.index
        : item.index + 1;
      if (dragContext.shouldDisplayIndicator) {
        dragContext.setIndicatorData({
          index: newIndicatorIndex,
          x: newIndicatorX,
        });
      }

      dragContext.setDropIndex(prev => {
        if (prev === -1) return item.index;
        if (!dragContext.draggable) return -1;
        if (item.index < dragContext.draggable.index) {
          return isCursorAtLeftPart ? item.index : item.index + 1;
        } else if (item.index > dragContext.draggable.index) {
          return isCursorAtLeftPart ? item.index - 1 : item.index;
        }

        return prev;
      });
    }
  }, [
    item,
    dragContext.draggable,
    dragContext.mouseX,
    dragContext.isDragging,
    dragContext.dropIndex,
    dragContext.setDropIndex,
    dragContext.setIndicatorData,
    dragContext.shouldDisplayIndicator,
  ]);

  React.useEffect(() => {
    if (
      !isDragging ||
      !dragContext.isDragging ||
      dragContext.shouldDisplayIndicator ||
      !draggable.current ||
      !dragContext.draggable
    )
      return;

    if (
      dragContext.mouseX >= draggable.current.offsetLeft &&
      dragContext.mouseX <=
        draggable.current.offsetLeft + draggable.current.offsetWidth
    )
      return;

    dragContext.setShouldDisplayIndicator(true);
  }, [
    dragContext.mouseX,
    draggable,
    dragContext.draggable,
    dragContext.isDragging,
    isDragging,
    dragContext.shouldDisplayIndicator,
    dragContext.setShouldDisplayIndicator,
  ]);

  React.useEffect(() => {
    if (item.index !== dragContext.dropIndex) return;
    dragContext.setDropData(item);
  }, [item.index, dragContext.dropIndex, dragContext.setDropData]);

  React.useEffect(() => {
    if (isDragging) return;
    document.addEventListener('mousemove', mouseMoveHandler);

    return () => document.removeEventListener('mousemove', mouseMoveHandler);
  }, [isDragging, mouseMoveHandler]);

  React.useEffect(() => {
    if (!isMousePressed) return;
    document.addEventListener('mouseup', mouseUpHandler);

    return () => document.removeEventListener('mouseup', mouseUpHandler);
  }, [isMousePressed, mouseUpHandler]);

  React.useEffect(() => {
    if (!isMousePressed)
      document.removeEventListener('mousemove', mouseMoveHandler);
  }, [isMousePressed, mouseMoveHandler]);

  React.useEffect(() => {
    if (!draggable.current || !canDrag) return;
    const handle = dragHandle?.current ?? draggable.current;
    handle.addEventListener('mousedown', mouseDownHandler);

    return () => {
      if (!handle) return;
      handle.removeEventListener('mousedown', mouseDownHandler);
    };
  }, [canDrag, draggable.current, dragHandle.current, mouseDownHandler]);

  return { draggable, dragHandle, isDragging };
};

interface ShadowDragAreaProps {
  children: React.ReactNode;
  backlightStyle?: React.CSSProperties;
  indicatorStyle?: React.CSSProperties;
  scrollX?: number;
  onDropIndexChange?: (data: {
    dropIndex: number;
    indicatorIndex: number;
    shouldDisplayIndicator: boolean;
  }) => void;
  onScrollContainer?: (movementX: number) => void;
}
export const ShadowDragArea = ({
  children,
  backlightStyle = {},
  indicatorStyle = {},
  onScrollContainer,
  onDropIndexChange,
  scrollX = 0,
}: ShadowDragAreaProps) => {
  const [draggable, setDraggable] = React.useState<Draggable | null>(null);
  const [offsetLeft, setOffsetLeft] = React.useState(-1);
  const [dropIndex, setDropIndex] = React.useState(-1);
  const [dropData, setDropData] = React.useState<Item | null>(null);
  const [indicatorData, setIndicatorData] = React.useState({
    index: -1,
    x: -1,
  });
  const [isDragging, setIsDragging] = React.useState(false);
  const [mouseX, setMouseX] = React.useState(-1);
  const [pivotX, setPivotX] = React.useState(-1);
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const containerSize = useComponentSize(containerRef);
  const [containerLeft, setContainerLeft] = React.useState(0);
  const [shouldDisplayIndicator, setShouldDisplayIndicator] =
    React.useState(false);
  const limiterContainer = React.useMemo(() => {
    if (!containerRef.current) return null;

    const finder: { width: number; element: HTMLElement } = {
      width: containerRef.current.clientWidth,
      element: containerRef.current,
    };
    while (true) {
      if (!finder.element || !finder.element.parentElement) break;
      finder.element = finder.element.parentElement;
      if (finder.element.clientWidth >= finder.width) continue;
      break;
    }
    return finder.element;
  }, [containerRef.current]);

  const mouseMoveHandler = React.useCallback(
    e => {
      if (!containerSize || !draggable?.item) return;

      const itemWidth = draggable.item.clientWidth;
      const newMouseX = e.pageX - containerLeft;
      const newMouseXWithScrollOffset = newMouseX + scrollX;
      setMouseX(newMouseXWithScrollOffset);

      if (limiterContainer) {
        if (newMouseX <= 50) onScrollContainer?.call(null, -30);
        else if (newMouseX >= limiterContainer.offsetWidth - 50)
          onScrollContainer?.call(null, 30);
      }

      if (
        (e.movementX > 0 && newMouseXWithScrollOffset < itemWidth / 2) ||
        (e.movementX < 0 &&
          newMouseXWithScrollOffset >=
            containerLeft + containerSize.width - itemWidth / 2)
      ) {
        return;
      }

      const containerWidth = containerSize.width;
      const mouseXWithPivotOffset = newMouseXWithScrollOffset - pivotX;
      if (mouseXWithPivotOffset >= containerWidth - itemWidth)
        setOffsetLeft(containerWidth - itemWidth);
      else if (mouseXWithPivotOffset < 0) setOffsetLeft(0);
      else setOffsetLeft(newMouseXWithScrollOffset - pivotX);
    },
    [
      containerLeft,
      containerSize,
      draggable,
      limiterContainer,
      pivotX,
      scrollX,
      setOffsetLeft,
      setMouseX,
    ],
  );

  React.useEffect(
    () =>
      onDropIndexChange?.call(null, {
        dropIndex: dropData?.index ?? -1,
        indicatorIndex: indicatorData.index,
        shouldDisplayIndicator,
      }),
    [dropData, indicatorData, shouldDisplayIndicator],
  );

  React.useEffect(() => {
    if (containerSize.x < 0 || containerSize.x === containerLeft) return;
    setContainerLeft(containerSize.x);
  }, [containerSize.x, containerLeft, setContainerLeft]);

  React.useEffect(() => {
    setIsDragging(!!draggable?.item);
    setMouseX(draggable?.item?.offsetLeft ?? -1);
    if (!draggable) setIndicatorData({ index: -1, x: -1 });
  }, [draggable, setIsDragging, setMouseX]);

  React.useEffect(() => {
    if (!draggable || !draggable.item) return;
    setOffsetLeft(draggable.item.offsetLeft);

    document.addEventListener('mousemove', mouseMoveHandler);
    return () => document.removeEventListener('mousemove', mouseMoveHandler);
  }, [draggable, setOffsetLeft, mouseMoveHandler]);

  const contextData = React.useMemo(
    () => ({
      backlightWidth: draggable?.item?.clientWidth ?? 0,
      backlightX: offsetLeft,
      draggable,
      dropIndex,
      dropData,
      mouseX,
      scrollX,
      shouldDisplayIndicator,
      setShouldDisplayIndicator,
      setDraggable,
      setDropIndex,
      setDropData,
      setIndicatorData,
      setPivotX,
      isDragging,
    }),
    [
      draggable,
      offsetLeft,
      dropIndex,
      dropData,
      isDragging,
      mouseX,
      scrollX,
      shouldDisplayIndicator,
      setShouldDisplayIndicator,
      setDraggable,
      setDropData,
      setDropIndex,
      setIndicatorData,
      setPivotX,
    ],
  );

  return (
    <ShadowDragArea.Container ref={containerRef}>
      <DragContext.Provider value={contextData}>
        {children}

        <ShadowDragArea.Backlight
          className="shadowdrag-backlight"
          style={{
            display: draggable !== null ? 'block' : 'none',
            width: (draggable?.item?.offsetWidth ?? 0) + 'px',
            left: offsetLeft + 'px',
            ...backlightStyle,
          }}
          offsetTop={offsetLeft}
        />
        <ShadowDragArea.Indicator
          className="shadowdrag-indicator"
          style={{
            display:
              draggable !== null && indicatorData.x >= 0 ? 'block' : 'none',
            left: indicatorData.x + 'px',
            ...indicatorStyle,
          }}
        />
      </DragContext.Provider>
    </ShadowDragArea.Container>
  );
};

ShadowDragArea.Container = styled.div`
  position: relative;
  height: 100%;
`;

ShadowDragArea.Backlight = styled.div`
  position: absolute;
  height: 100%;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
`;

ShadowDragArea.Indicator = styled.div`
  position: absolute;
  left: 0;
  height: 100%;
  width: 4px;
  background-color: #000;
  z-index: 2;
`;
