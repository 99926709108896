import type { Action } from 'store/models';

import type {
  SnackbarNotification,
  SnackbarNotificationPayload,
} from './models/snackbar-notification';

export const namespace = 'notification';
export const PUSH = `${namespace}/PUSH`;
export const POP = `${namespace}/POP`;
export const CLEAR = `${namespace}/CLEAR`;
export const OPEN = `${namespace}/OPEN`;
export const HIDE = `${namespace}/HIDE`;

type pushNotificationAC = (payload: SnackbarNotificationPayload) => Action;

export const pushNotification: pushNotificationAC = payload => ({
  type: PUSH,
  payload,
});

export const popNotification = () => ({
  type: POP,
});

export const clearNotifications = () => ({
  type: CLEAR,
});

type openNotificationAC = (payload: SnackbarNotification) => Action;

export const openNotification: openNotificationAC = payload => ({
  type: OPEN,
  payload,
});

export const hideNotification = () => ({
  type: HIDE,
});
