import PouchDB from 'pouchdb';

import type { ProductionPoint } from 'modules/production/models/production';

const db = { production: new PouchDB('production') };

const getWellProduction = (wellId: string) => {
  try {
    return db.production
      .get(wellId)
      .catch(err => {
        //eslint-disable-next-line no-console
        if (err.message !== 'missing') console.error(err);
        return [];
      })
      .then(doc =>
        doc && doc.data
          ? doc.data.map(item => ({ ...item, day: new Date(item.day) }))
          : [],
      );
  } catch {
    return [];
  }
};

const checkWellProductionExists = (wellId: string) => {
  try {
    return db.production
      .get(wellId)
      .catch(err => err)
      .then(doc => {
        return doc && doc.data && doc.data.length !== 0;
      });
  } catch {
    return false;
  }
};

const saveWellProduction = (wellId: string, production: ProductionPoint[]) => {
  return db.production
    .get(wellId)
    .then(doc => {
      if (!doc) return;
      return db.production.put({ ...doc, data: production });
    })
    .catch(err => {
      if (err.name === 'not_found') {
        return db.production.put({ _id: wellId, data: production });
      }
    });
};

const destroy = () =>
  db.production
    .destroy()
    .then(res => {
      //eslint-disable-next-line no-console
      console.log('DB destroyed');
    })
    .catch(err => {
      //eslint-disable-next-line no-console
      console.log(err);
    });

const clearWellProduction = () => {
  destroy().then(() => {
    db.production = new PouchDB('production');
  });
};

const dbActions = {
  clearWellProduction,
  getWellProduction,
  saveWellProduction,
  destroy,
  checkWellProductionExists,
};

export default dbActions;
