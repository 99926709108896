import { utcFormat, utcParse } from 'd3-time-format';

const stringifyDate = utcFormat('%-m/%-d/%Y');
const parseDate = utcParse('%-m/%-d/%Y');

const spotfireDate = {
  stringify: (date: Date) => {
    return stringifyDate(date);
  },
  parse: (dateString: string): Date | null => {
    return parseDate(dateString);
  },
};

export default spotfireDate;
