import * as React from 'react';
import { AppSettingType, currencyConverter } from 'modules/appSettings/model';
import { AppSettingWrapper } from 'modules/appSettings/components/setting/AppSettingWrapper';
import { DateAppSetting } from 'modules/appSettings/components/setting/DateAppSetting';
import { NumericAppSetting } from './setting/NumericAppSetting';

interface Props {
  appSettings: AppSettingType<any>[];
  tempSettings: { [key: string]: any };
  setLocally: (key: string, value: any) => void;
}

export const SettingInputs = ({
  appSettings,
  tempSettings,
  setLocally,
}: Props) => {
  const getAppSettingInput = React.useCallback(
    (setting: AppSettingType<any>) => {
      const value =
        tempSettings[setting.key] !== undefined
          ? tempSettings[setting.key]
          : setting.current;

      const commonProps = {
        onChange: value => setLocally(setting.key, value),
        settingValue: value,
      };

      if (value instanceof Date) return <DateAppSetting {...commonProps} />;

      if (typeof value === 'number')
        return (
          <NumericAppSetting {...commonProps} format={currencyConverter} />
        );

      return;
    },
    [setLocally, tempSettings],
  );

  return (
    <>
      {appSettings.map(setting => (
        <AppSettingWrapper
          reset={() => setLocally(setting.key, setting.default)}
          title={setting.name}
          key={setting.key}
        >
          {getAppSettingInput(setting)}
        </AppSettingWrapper>
      ))}
    </>
  );
};
