import gql from 'graphql-tag';

import type { GraphqlMutationAction, GraphqlQueryAction } from 'store/models';

import type { Settings } from './models/settings';

const namespace = 'SETTINGS';
export const FETCH_SETTINGS = `${namespace}/FETCH_SETTINGS`;
export const INIT_SHARE_SETTINGS = `${namespace}/INIT_SHARE_SETTINGS`;
export const SHARE_SETTINGS = `${namespace}/SHARE_SETTINGS`;
export const CLEAR_SHARE_ID = `${namespace}/CLEAR_SHARE_ID`;

const fetchSettingsQuery = gql`
  query ($shareId: ID!) {
    getSettings(id: $shareId) {
      id
      settings
    }
  }
`;

type fetchSettingsAC = (shareId: string) => GraphqlQueryAction;

export const fetchSettings: fetchSettingsAC = shareId => ({
  type: FETCH_SETTINGS,
  payload: {
    key: 'getSettings',
    graphql: {
      query: fetchSettingsQuery,
      variables: {
        shareId,
      },
    },
  },
});

export const initShareSettings = () => ({
  type: INIT_SHARE_SETTINGS,
});

const shareSettingsMutation = gql`
  mutation ($payload: JSON!) {
    setSettings(data: $payload) {
      id
      settings
    }
  }
`;

type shareSettingsAC = (payload: Settings) => GraphqlMutationAction;

export const shareSettings: shareSettingsAC = payload => ({
  type: SHARE_SETTINGS,
  payload: {
    key: 'setSettings',
    graphql: {
      mutation: shareSettingsMutation,
      variables: {
        payload: payload,
      },
    },
  },
});

export const clearShareId = () => ({
  type: CLEAR_SHARE_ID,
});
