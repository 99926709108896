import React from 'react';
import styled from 'styled-components';

import { CloseIcon } from 'components/Icons';

interface RemoveCoreSeriesButtonProps {
  onClick: () => void;
}
const RemoveCoreSeriesButton = ({ onClick }: RemoveCoreSeriesButtonProps) => {
  return (
    <RemoveCoreSeriesButton.Button onClick={onClick}>
      <CloseIcon />
    </RemoveCoreSeriesButton.Button>
  );
};

RemoveCoreSeriesButton.Button = styled.button`
  height: 20px;
  border-radius: 0;
  border: none;
  border-left: ${p => p.theme.borders.thingray};
  padding: 0;
  min-width: 23px;
  outline: none;
`;

export default RemoveCoreSeriesButton;
