import * as React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import type { User } from 'modules/user/models/user';
import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';

interface AssigneeFormProps {
  allocIssue: AllocIssue;
  disabled: boolean;
  onAllocIssueUpdate: (data: {
    updatedIssue: AllocIssue;
    data: Record<string, any>;
  }) => void;
  users: { [userId: string]: User };
}

const AssigneeForm = ({
  allocIssue,
  disabled,
  onAllocIssueUpdate,
  users,
}: AssigneeFormProps) => {
  const sortedUsers = R.values(users).sort((userA, userB) =>
    userA.firstName.localeCompare(userB.firstName),
  );

  return (
    <AssigneeForm.Wrapper>
      <select
        id="assignee"
        disabled={disabled}
        name="assignee"
        value={allocIssue.assigneeId}
        onChange={e => {
          onAllocIssueUpdate({
            updatedIssue: allocIssue,
            data: {
              assigneeId: e.target.value,
            },
          });
          e.target.blur();
        }}
      >
        {sortedUsers.map(user => (
          <option key={user.id} value={user.id}>
            {user.firstName + ' ' + user.lastName}
          </option>
        ))}
      </select>
    </AssigneeForm.Wrapper>
  );
};

AssigneeForm.Wrapper = styled.div`
  & select {
    width: 100%;
  }
`;

export default React.memo<AssigneeFormProps>(AssigneeForm);
