import { utcDay } from 'd3-time';
import * as React from 'react';

import { getVarianceExtremeDates } from 'helpers';

import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';
import type { CapacityChangeEvent } from 'modules/capacityChangeEvent/models/capacityChangeEvent';
import type { IdIndexDialog } from 'modules/ui/models/ui';
import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';

import { getDividerExtraPositionsFromPoints } from '../utils';

import AllocIssueDivider from './allocIssue/AllocIssueDivider';
import CapacityDivider from './capacityEvent/CapacityDivider';
import EventDivider from './varianceEvent/EventDivider';
import VarianceEventClickableArea from './varianceEvent/VarianceEventClickableArea';

interface SVGVarianceInteractionProps {
  allocIssueDialog: IdIndexDialog;
  onAllocIssueUpdate: ({ updatedIssue: AllocIssue, data: Object }) => void;
  allocIssuesVisibility: boolean;
  onLocalAllocIssueUpdate: (allocData: {
    updatedIssue: AllocIssue;
    data: Record<string, any>;
  }) => void;
  allWellAllocIssues: AllocIssue[];
  capacity: CapacityChangeEvent[];
  capacityDialog: { show: boolean; index: number };
  finishDrag: () => void;
  height: number;
  isAxisDragging: boolean;
  isDragging: boolean;
  isPossibleEditAlloc: boolean;
  isPossibleEditCapacity: boolean;
  isPossibleEditVariance: boolean;
  leftOffset: number;
  onAllocIssueDialogOpen: ({ index: number, id: string }) => void;
  onAllocIssueDividerHover: (eventId: string) => void;
  onCapacityDialogOpen: (index: number, eventId: string) => void;
  onCapacityDividerHover: (eventId: number) => void;
  onDayInitChange: ({ capacityEventId: string, newDayInit: Date }) => void;
  onEventDividerHover: (eventId: number) => void;
  onXAxisScaling: (
    e: MouseEvent,
    svgEl: { current: Element | null } | null,
  ) => void;
  onHighlightAllocIssueDividerOff: () => void;
  onHighlightCapacityDividerOff: () => void;
  onHighlightEventDividerOff: () => void;
  onVarianceDialogOpen: (index: number, eventId: string) => void;
  onVarianceEventUpdate: (varianceData: {
    dates: Date[];
    varianceEventId: string;
  }) => void;
  startDrag: () => void;
  today: Date;
  trellisTitle: string;
  varianceData: { date: Date; variance: number }[];
  varianceDialog: { show: boolean; index: number };
  varianceEvents: VarianceEvent[];
  width: number;
  xScale: any;
  yScale: any;
}

const SVGVarianceInteraction = ({
  allocIssueDialog,
  allWellAllocIssues,
  allocIssuesVisibility,
  capacity,
  capacityDialog,
  finishDrag,
  height,
  isAxisDragging,
  isDragging,
  isPossibleEditAlloc,
  isPossibleEditCapacity,
  isPossibleEditVariance,
  leftOffset,
  onAllocIssueDialogOpen,
  onAllocIssueDividerHover,
  onAllocIssueUpdate,
  onCapacityDialogOpen,
  onCapacityDividerHover,
  onDayInitChange,
  onEventDividerHover,
  onXAxisScaling,
  onHighlightAllocIssueDividerOff,
  onHighlightCapacityDividerOff,
  onHighlightEventDividerOff,
  onLocalAllocIssueUpdate,
  onVarianceDialogOpen,
  onVarianceEventUpdate,
  today,
  trellisTitle,
  startDrag,
  varianceData,
  varianceDialog,
  varianceEvents,
  width,
  xScale,
  yScale,
}: SVGVarianceInteractionProps) => {
  const svgEl = React.useRef(null);
  const noOpenDialog =
    !varianceDialog.show && !capacityDialog.show && !allocIssueDialog.show;
  const onWheelHandler = React.useCallback(
    e => {
      onXAxisScaling(e, svgEl);
    },
    [svgEl, onXAxisScaling],
  );

  return (
    <svg
      ref={svgEl}
      height="100%"
      width={width}
      preserveAspectRatio="none"
      viewBox={`0 0 ${width} ${height}`}
      className="panInteraction"
      onWheel={onWheelHandler}
    >
      {noOpenDialog &&
        capacity.map(
          (capacityEvent, i) =>
            capacityEvent.id && (
              <CapacityDivider
                key={`capacityDivider_${capacityEvent.id}`}
                capacityDialog={capacityDialog}
                capacityEvent={capacityEvent}
                extraPositions={getDividerExtraPositionsFromPoints(
                  capacity,
                  i,
                  xScale,
                )}
                finishDrag={finishDrag}
                height={height}
                isEditable={false}
                isDragging={isDragging}
                leftOffset={leftOffset}
                onCapacityDialogOpen={onCapacityDialogOpen}
                onDayInitChange={onDayInitChange}
                onDividerHover={onCapacityDividerHover}
                onHighlightCapacityDividerOff={onHighlightCapacityDividerOff}
                pointIndex={i}
                startDrag={startDrag}
                xScale={xScale}
              />
            ),
        )}
      {isPossibleEditCapacity && capacityDialog.show && (
        <CapacityDivider
          key={`capacityDivider_${capacity[capacityDialog.index].id}`}
          capacityDialog={capacityDialog}
          capacityEvent={capacity[capacityDialog.index]}
          extraPositions={getDividerExtraPositionsFromPoints(
            capacity,
            capacityDialog.index,
            xScale,
          )}
          finishDrag={finishDrag}
          height={height}
          isEditable={true}
          isDragging={isDragging}
          leftOffset={leftOffset}
          onCapacityDialogOpen={onCapacityDialogOpen}
          onDayInitChange={onDayInitChange}
          onDividerHover={onCapacityDividerHover}
          onHighlightCapacityDividerOff={onHighlightCapacityDividerOff}
          pointIndex={capacityDialog.index}
          startDrag={startDrag}
          xScale={xScale}
        />
      )}
      {noOpenDialog &&
        varianceEvents.map((varianceEvent, i) => {
          return (
            <EventDivider
              key={`eventDivider_${varianceEvent.id}`}
              varianceEvent={varianceEvent}
              varianceDialog={varianceDialog}
              eventIndex={i}
              extremeEventDates={getVarianceExtremeDates(
                allWellAllocIssues,
                varianceEvents,
                varianceEvent.id,
                today,
                'Variance Event',
              )}
              finishDrag={finishDrag}
              height={height}
              isAxisDragging={isAxisDragging}
              isEditable={false}
              isDragging={isDragging}
              leftOffset={leftOffset}
              onVarianceDialogOpen={onVarianceDialogOpen}
              onDividerHover={onEventDividerHover}
              onHighlightEventDividerOff={onHighlightEventDividerOff}
              onVarianceEventUpdate={onVarianceEventUpdate}
              startDrag={startDrag}
              xScale={xScale}
            />
          );
        })}
      {noOpenDialog &&
        varianceEvents.map((varianceEvent, i) => {
          return (
            <VarianceEventClickableArea
              key={varianceEvent.id}
              varianceData={varianceData}
              eventIndex={i}
              height={height}
              onVarianceDialogOpen={onVarianceDialogOpen}
              title={trellisTitle}
              varianceEvent={varianceEvent}
              xScale={xScale}
              yScale={yScale}
            />
          );
        })}
      {varianceDialog.show &&
        isPossibleEditVariance &&
        varianceEvents[varianceDialog.index] && (
          <>
            <VarianceEventClickableArea
              key={varianceEvents[varianceDialog.index].id}
              varianceData={varianceData}
              eventIndex={varianceDialog.index}
              height={height}
              onVarianceDialogOpen={onVarianceDialogOpen}
              title={trellisTitle}
              varianceEvent={varianceEvents[varianceDialog.index]}
              xScale={xScale}
              yScale={yScale}
            />
            <EventDivider
              key={`eventDivider_${varianceEvents[varianceDialog.index].id}`}
              varianceEvent={varianceEvents[varianceDialog.index]}
              varianceDialog={varianceDialog}
              eventIndex={varianceDialog.index}
              extremeEventDates={getVarianceExtremeDates(
                allWellAllocIssues,
                varianceEvents,
                varianceEvents[varianceDialog.index].id,
                today,
                'Variance Event',
              )}
              finishDrag={finishDrag}
              height={height}
              isAxisDragging={isAxisDragging}
              isEditable={true}
              isDragging={isDragging}
              leftOffset={leftOffset}
              onVarianceDialogOpen={onVarianceDialogOpen}
              onDividerHover={onEventDividerHover}
              onHighlightEventDividerOff={onHighlightEventDividerOff}
              onVarianceEventUpdate={onVarianceEventUpdate}
              startDrag={startDrag}
              xScale={xScale}
            />
          </>
        )}
      {noOpenDialog &&
        allocIssuesVisibility &&
        allWellAllocIssues.map((issue, i) => {
          const startPos = xScale(issue.dateStart);
          const endPos = xScale(utcDay.offset(issue.dateEnd, 1));

          return (
            <polygon
              key={issue.id}
              points={`${startPos},0 ${endPos},0 ${endPos},${height} ${startPos},${height}`}
              onClick={() => onAllocIssueDialogOpen({ index: i, id: issue.id })}
              fill="transparent"
              cursor={isAxisDragging ? 'grabbing' : 'pointer'}
              className="alloc-interactive interactive panInteraction"
            />
          );
        })}

      {noOpenDialog &&
        allocIssuesVisibility &&
        allWellAllocIssues.map((issue, i) => (
          <AllocIssueDivider
            key={`allocIssueDivider_${issue.id}`}
            allocIssue={issue}
            issueIndex={i}
            extremeAllocDates={getVarianceExtremeDates(
              allWellAllocIssues,
              varianceEvents,
              issue.id,
              today,
              'Allocation Issue',
            )}
            finishDrag={finishDrag}
            height={height}
            isAxisDragging={isAxisDragging}
            isDragging={isDragging}
            isEditable={false}
            leftOffset={leftOffset}
            onAllocIssueUpdate={onAllocIssueUpdate}
            onLocalAllocIssueUpdate={onLocalAllocIssueUpdate}
            onAllocIssueDividerHover={onAllocIssueDividerHover}
            onAllocIssueDialogOpen={onAllocIssueDialogOpen}
            onHighlightAllocIssueDividerOff={onHighlightAllocIssueDividerOff}
            startDrag={startDrag}
            xScale={xScale}
          />
        ))}
      {allocIssueDialog.show &&
        allocIssuesVisibility &&
        isPossibleEditAlloc &&
        allWellAllocIssues[allocIssueDialog.index] && (
          <>
            <AllocIssueDivider
              allocIssue={allWellAllocIssues[allocIssueDialog.index]}
              issueIndex={allocIssueDialog.index}
              extremeAllocDates={getVarianceExtremeDates(
                allWellAllocIssues,
                varianceEvents,
                allWellAllocIssues[allocIssueDialog.index].id,
                today,
                'Allocation Issue',
              )}
              finishDrag={finishDrag}
              height={height}
              isAxisDragging={isAxisDragging}
              isDragging={isDragging}
              isEditable={true}
              leftOffset={leftOffset}
              onAllocIssueUpdate={onAllocIssueUpdate}
              onLocalAllocIssueUpdate={onLocalAllocIssueUpdate}
              onAllocIssueDividerHover={onAllocIssueDividerHover}
              onAllocIssueDialogOpen={onAllocIssueDialogOpen}
              onHighlightAllocIssueDividerOff={onHighlightAllocIssueDividerOff}
              startDrag={startDrag}
              xScale={xScale}
            />
            <polygon
              points={`${xScale(
                allWellAllocIssues[allocIssueDialog.index].dateStart,
              )},0 ${xScale(
                utcDay.offset(
                  allWellAllocIssues[allocIssueDialog.index].dateEnd,
                  1,
                ),
              )},0 ${xScale(
                utcDay.offset(
                  allWellAllocIssues[allocIssueDialog.index].dateEnd,
                  1,
                ),
              )},${height} ${xScale(
                allWellAllocIssues[allocIssueDialog.index].dateStart,
              )},${height}`}
              onClick={() =>
                onAllocIssueDialogOpen({
                  index: allocIssueDialog.index,
                  id: allWellAllocIssues[allocIssueDialog.index].id,
                })
              }
              fill="transparent"
              cursor={isAxisDragging ? 'grabbing' : 'pointer'}
              className="alloc-interactive interactive panInteraction"
            />
          </>
        )}
    </svg>
  );
};

export default SVGVarianceInteraction;
