import * as React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useNonInputKeydown } from 'hooks/useKeydown';
import Button from 'components/Button';
import RightPanel from 'modules/dashboard/components/RightPanel';
import { getInfoForHelpRequest } from 'modules/auth/AuthReducer';
import useRightPanel from 'modules/ui/hooks/useRightPanel';
import { requestHelp, setShowSuccessMessage } from '../RequestHelpActions';
import { getShowSuccessMessage } from '../RequestHelpReducer';

const EMAIL = 'support@wiserocksoftware.com';

const RequestHelpPanel = () => {
  const rightPanel = useRightPanel();
  const [message, setMessage] = React.useState('');
  const dispatch = useDispatch();
  const showSuccessMessage = useSelector(getShowSuccessMessage);

  const onMessageChange = React.useCallback(
    e => {
      setMessage(e.currentTarget.value);
    },
    [setMessage],
  );

  const closePanel = React.useCallback(() => {
    setMessage('');
    dispatch(setShowSuccessMessage(false));
    rightPanel.setDialog({ type: 'Shortcuts' });
  }, [setMessage, rightPanel.setDialog, dispatch]);

  const userData = useSelector(getInfoForHelpRequest, shallowEqual);

  const onSubmit = React.useCallback(() => {
    if (message.trim().length === 0) return;
    dispatch(requestHelp({ message }));
  }, [message, userData, dispatch]);

  useNonInputKeydown(
    ({ keyName }) => {
      if (!rightPanel.isDialogOfType('RequestHelp')) return;
      if (keyName === 'ESCAPE') return closePanel();
    },
    [closePanel, rightPanel.isDialogOfType],
  );

  return (
    <RightPanel
      isShown
      title="Submit Support Request"
      onDialogClose={closePanel}
    >
      <RequestHelpPanel.Container>
        <RequestHelpPanel.Description>
          Feel free to use the form below, or send us an email at
          <br />
          <RequestHelpPanel.LinkWrapper>
            <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
          </RequestHelpPanel.LinkWrapper>
        </RequestHelpPanel.Description>
        <RequestHelpPanel.HR />

        {!showSuccessMessage && (
          <>
            <RequestHelpPanel.Subtitle>Message</RequestHelpPanel.Subtitle>
            <RequestHelpPanel.Input
              placeholder="Write a message..."
              onChange={onMessageChange}
              value={message}
            />
            <RequestHelpPanel.Controls>
              <RequestHelpPanel.Button
                onClick={closePanel}
                color="#C10000"
                width={65}
              >
                Cancel
              </RequestHelpPanel.Button>
              <RequestHelpPanel.Button
                onClick={onSubmit}
                disabled={message.trim().length === 0}
                width={65}
              >
                Submit
              </RequestHelpPanel.Button>
            </RequestHelpPanel.Controls>
          </>
        )}

        {showSuccessMessage && (
          <RequestHelpPanel.SuccessMessageContainer>
            <RequestHelpPanel.SuccessMessage>
              Thank you, your support request has been received
            </RequestHelpPanel.SuccessMessage>
            <RequestHelpPanel.ButtonClose onClick={closePanel} width={65}>
              Close
            </RequestHelpPanel.ButtonClose>
          </RequestHelpPanel.SuccessMessageContainer>
        )}
      </RequestHelpPanel.Container>
    </RightPanel>
  );
};

RequestHelpPanel.Container = styled.div`
  font-weight: bold;
  font-family: 'Lato', sans-serif;
  color: #3f3f3f;
  font-size: 14px;
  padding: 10px;
`;

RequestHelpPanel.Description = styled.p`
  line-height: 2;
  text-align: center;
`;

RequestHelpPanel.LinkWrapper = styled.span`
  color: #41ab5d;
  text-decoration: underline;
`;

RequestHelpPanel.HR = styled.hr`
  border: 0;
  display: block;
  height: 1px;
  background-color: #cccccc;
  margin: 0;
`;

RequestHelpPanel.Subtitle = styled.div`
  margin-top: 10px;
`;

RequestHelpPanel.Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
`;

RequestHelpPanel.Button = styled(Button)`
  color: ${({ color, disabled }) =>
    disabled ? '#cccccc' : color ?? 'inherit'};
`;

RequestHelpPanel.ButtonClose = styled(Button)`
  align-self: flex-end;
`;

RequestHelpPanel.SuccessMessage = styled.p`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

RequestHelpPanel.SuccessMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 35px;
`;

RequestHelpPanel.Input = styled(TextareaAutosize)`
  resize: none;
  width: 100%;
  min-height: 100px;
  padding: 16px 10px;
  border: 1px solid #c1c1c1;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  margin-top: 10px;
  outline: none;
`;

export default RequestHelpPanel;
