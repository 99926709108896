import type { TabInfo } from '../Model';

export const Actions = Object.freeze({
  setActiveTab: (payload: string) => ({
    type: 'SET_ACTIVE_TAB' as const,
    payload,
  }),
  setTabInfo: (payload: { id: string; info: TabInfo }) => ({
    type: 'SET_TAB_INFO' as const,
    payload,
  }),
  unload: () => ({ type: 'UNLOAD' as const }),
});
