import gql from 'graphql-tag';

import type { Action } from 'store/models';

import type { AllocIssue, AllocIssuePhase } from './models/allocIssue';

export const namespace = 'ALLOC_ISSUE';
export const FETCH_ALL_ALLOC_ISSUES = `${namespace}/FETCH_ALL_ALLOC_ISSUES`;
export const POPULATE_ALLOC_ISSUES = `${namespace}/POPULATE_ALLOC_ISSUES`;
export const CREATE_REMOTE_ALLOC_ISSUE = `${namespace}/CREATE_REMOTE_ALLOC_ISSUE`;
export const DELETE_ALLOC_ISSUE = `${namespace}/DELETE_ALLOC_ISSUE`;
export const UPDATE_REMOTE_ALLOC_ISSUE = `${namespace}/UPDATE_REMOTE_ALLOC_ISSUE`;
export const CREATE_LOCAL_ALLOC_ISSUE = `${namespace}/CREATE_LOCAL_ALLOC_ISSUE`;
export const UPDATE_LOCAL_ALLOC_ISSUE = `${namespace}/UPDATE_LOCAL_ALLOC_ISSUE`;
export const DELETE_LOCAL_ALLOC_ISSUE = `${namespace}/DELETE_LOCAL_ALLOC_ISSUE`;
export const INIT_CREATE_ALLOC_ISSUE = `${namespace}/INIT_CREATE_ALLOC_ISSUE`;

const FETCH_ALL_ALLOC_ISSUES_QUERY = gql`
  query {
    listAllocIssues {
      id
      wellId
      dateStart
      dateEnd
      assigneeId
      editorId
      statusId
      phases {
        phaseId
        actualVolume
        capacityVolume
        checked
      }
      causes
      notes
    }
  }
`;

export const fetchAllAllocationIssues = () => ({
  type: FETCH_ALL_ALLOC_ISSUES,
  payload: {
    key: 'listAllocIssues',
    graphql: {
      query: FETCH_ALL_ALLOC_ISSUES_QUERY,
    },
  },
});

type populateAllocIssuesAC = (payload: {
  [wellId: string]: Record<string, any>[];
}) => Action;

export const populateAllocIssues: populateAllocIssuesAC = payload => ({
  type: POPULATE_ALLOC_ISSUES,
  payload,
});

const UPDATE_REMOTE_ALLOC_ISSUE_MUTATION = gql`
  mutation ($id: ID!, $data: AllocIssueInput!) {
    updateAllocIssue(id: $id, data: $data) {
      id
      wellId
      dateStart
      dateEnd
      assigneeId
      editorId
      statusId
      phases {
        phaseId
        actualVolume
        capacityVolume
        checked
      }
      causes
      notes
    }
  }
`;

type updateRemoteAllocIssueAC = (payload: {
  id: string;
  data: {
    dateStart?: Date;
    dateEnd?: Date;
    assigneeId?: string;
    statusId?: string;
    phases?: string[];
    causes?: string[];
  };
}) => Action;

export const updateRemoteAllocIssue: updateRemoteAllocIssueAC = ({
  id,
  data,
}) => ({
  type: UPDATE_REMOTE_ALLOC_ISSUE,
  payload: {
    key: 'updateAllocIssue',
    graphql: {
      mutation: UPDATE_REMOTE_ALLOC_ISSUE_MUTATION,
      variables: {
        id,
        data,
      },
    },
  },
});

const DELETE_ALLOC_ISSUE_MUTATION = gql`
  mutation ($id: ID!) {
    deleteAllocIssue(id: $id)
  }
`;

type deleteAllocIssueAC = (id: string) => Action;

export const deleteAllocIssue: deleteAllocIssueAC = id => ({
  type: DELETE_ALLOC_ISSUE,
  payload: {
    key: 'deleteAllocIssue',
    graphql: {
      mutation: DELETE_ALLOC_ISSUE_MUTATION,
      variables: {
        id,
      },
    },
  },
});

const CREATE_REMOTE_ALLOC_ISSUE_MUTATION = gql`
  mutation ($payload: AllocIssueInput!) {
    createAllocIssue(data: $payload) {
      id
      wellId
      statusId
      phases {
        phaseId
        actualVolume
        capacityVolume
        checked
      }
      causes
      assigneeId
      editorId
      dateStart
      dateEnd
      notes
    }
  }
`;

type createRemoteAllocIssueAC = (payload: {
  wellId: string;
  statusId: string;
  phases: AllocIssuePhase[];
  causes: string[];
  assigneeId: string;
  dateStart: Date;
  dateEnd: Date;
}) => Action;

export const createRemoteAllocIssue: createRemoteAllocIssueAC = payload => ({
  type: CREATE_REMOTE_ALLOC_ISSUE,
  payload: {
    key: 'createAllocIssue',
    graphql: {
      mutation: CREATE_REMOTE_ALLOC_ISSUE_MUTATION,
      variables: { payload },
    },
  },
});

type createLocalAllocIssueAC = ({ wellId: string, date: Date }) => Action;

export const createLocalAllocIssue: createLocalAllocIssueAC = payload => ({
  type: CREATE_LOCAL_ALLOC_ISSUE,
  payload,
});

type updateLocalAllocIssueAC = ({
  updatedIssue: AllocIssue,
  data: Object,
  interactive: boolean,
}) => Action;

export const updateLocalAllocIssue: updateLocalAllocIssueAC = payload => ({
  type: UPDATE_LOCAL_ALLOC_ISSUE,
  payload,
});

type deleteLocalAllocIssueAC = (payload: {
  wellId: string;
  id: string;
}) => Action;

export const deleteLocalAllocIssue: deleteLocalAllocIssueAC = payload => ({
  type: DELETE_LOCAL_ALLOC_ISSUE,
  payload,
});

type initCreateAllocIssueAC = (localIssue: AllocIssue) => Action;

export const initCreateAllocIssue: initCreateAllocIssueAC = payload => ({
  type: INIT_CREATE_ALLOC_ISSUE,
  payload,
});
