import { utcDay } from 'd3-time';
import * as React from 'react';

import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';
import { getVarianceExtremeDates } from 'helpers';
import type { CapacityChangeEvent } from 'modules/capacityChangeEvent/models/capacityChangeEvent';
import type { ProductionPoint } from 'modules/production/models/production';
import type { IdIndexDialog } from 'modules/ui/models/ui';
import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';

import { getDividerExtraPositionsFromPoints } from '../utils';
import AllocIssueDivider from './allocIssue/AllocIssueDivider';
import CapacityDivider from './capacityEvent/CapacityDivider';
import EventClickableArea from './varianceEvent/EventClickableArea';
import EventDivider from './varianceEvent/EventDivider';

interface SVGIndicatorTrellisInteractionProps {
  allocIssueDialog: IdIndexDialog;
  allocIssuesVisibility: boolean;
  allWellAllocIssues: AllocIssue[];
  capacityDialog: {
    show: boolean;
    index: number;
  };
  eventCapacityData: { date: Date; capacity: number }[][];
  height: number;
  isAxisDragging: boolean;
  width: number;
  capacity: CapacityChangeEvent[];
  varianceEvents: VarianceEvent[];
  trellisTitle: string;
  xScale: any;
  startDrag: () => void;
  finishDrag: () => void;
  isDragging: boolean;
  leftOffset: number;
  onAllocIssueUpdate: ({ updatedIssue: AllocIssue, data: Object }) => void;
  onAllocIssueDialogOpen: ({ index: number, id: string }) => void;
  onCapacityDialogOpen: (index: number, eventId: string) => void;
  onDayInitChange: (dayData: {
    capacityEventId: string;
    newDayInit: Date;
  }) => void;
  onCapacityDividerHover: (eventId: number) => void;
  onHighlightCapacityDividerOff: () => void;
  onVarianceDialogOpen: (index: number, eventId: string) => void;
  onEventDividerHover: (eventId: number) => void;
  onHighlightEventDividerOff: () => void;
  onVarianceEventUpdate: (varianceData: {
    dates: Date[];
    varianceEventId: string;
  }) => void;
  onLocalAllocIssueUpdate: (allocData: {
    updatedIssue: AllocIssue;
    data: Record<string, any>;
  }) => void;
  onAllocIssueDividerHover: (eventId: string) => void;
  onHighlightAllocIssueDividerOff: () => void;
  production: ProductionPoint[];
  permissions: { [permission: string]: boolean };
  today: Date;
  varianceDialog: {
    show: boolean;
    index: number;
  };
  yScale: any;
  onXAxisScaling: (
    e: MouseEvent,
    svgEl: { current: Element | null } | null,
  ) => void;
}

const SVGIndicatorTrellisInteraction = ({
  allocIssueDialog,
  allocIssuesVisibility,
  allWellAllocIssues,
  capacityDialog,
  eventCapacityData,
  height,
  isAxisDragging,
  width,
  capacity,
  varianceEvents,
  trellisTitle,
  xScale,
  startDrag,
  finishDrag,
  isDragging,
  leftOffset,
  onAllocIssueUpdate,
  onAllocIssueDialogOpen,
  onCapacityDialogOpen,
  onCapacityDividerHover,
  onDayInitChange,
  onHighlightCapacityDividerOff,
  onVarianceDialogOpen,
  onEventDividerHover,
  onXAxisScaling,
  onHighlightEventDividerOff,
  onVarianceEventUpdate,
  onLocalAllocIssueUpdate,
  onAllocIssueDividerHover,
  onHighlightAllocIssueDividerOff,
  production,
  permissions,
  today,
  varianceDialog,
  yScale,
}: SVGIndicatorTrellisInteractionProps) => {
  const svgEl = React.useRef(null);
  const noOpenDialog = React.useMemo(
    () =>
      !capacityDialog.show && !varianceDialog.show && !allocIssueDialog.show,
    [capacityDialog.show, varianceDialog.show, allocIssueDialog.show],
  );
  const onWheelHandler = React.useCallback(
    e => {
      onXAxisScaling(e, svgEl);
    },
    [svgEl, onXAxisScaling],
  );

  return (
    <svg
      ref={svgEl}
      height={height}
      width={width}
      preserveAspectRatio="none"
      viewBox={`0 0 ${width} ${height}`}
      className="panInteraction"
      onWheel={onWheelHandler}
    >
      {noOpenDialog &&
        allocIssuesVisibility &&
        allWellAllocIssues.map((issue, i) => {
          const startPos = xScale(issue.dateStart);
          const endPos = xScale(utcDay.offset(issue.dateEnd, 1));

          return (
            <polygon
              key={issue.id}
              points={`${startPos},0 ${endPos},0 ${endPos},${height} ${startPos},${height}`}
              onClick={() => onAllocIssueDialogOpen({ index: i, id: issue.id })}
              fill="transparent"
              cursor={isAxisDragging ? 'grabbing' : 'pointer'}
              className="alloc-interactive interactive panInteraction"
            />
          );
        })}

      {noOpenDialog &&
        capacity.map(
          (capacityEvent, i) =>
            capacityEvent.id && (
              <CapacityDivider
                key={`capacityDivider_${capacityEvent.id}`}
                capacityDialog={capacityDialog}
                capacityEvent={capacityEvent}
                extraPositions={getDividerExtraPositionsFromPoints(
                  capacity,
                  i,
                  xScale,
                )}
                finishDrag={finishDrag}
                height={height}
                isEditable={false}
                isDragging={isDragging}
                leftOffset={leftOffset}
                onCapacityDialogOpen={onCapacityDialogOpen}
                onDayInitChange={onDayInitChange}
                onDividerHover={onCapacityDividerHover}
                onHighlightCapacityDividerOff={onHighlightCapacityDividerOff}
                pointIndex={i}
                startDrag={startDrag}
                xScale={xScale}
              />
            ),
        )}

      {capacityDialog.show && permissions.isAllowedEditCapChanges && (
        <CapacityDivider
          key={`capacityDivider_${capacity[capacityDialog.index].id}`}
          capacityDialog={capacityDialog}
          capacityEvent={capacity[capacityDialog.index]}
          extraPositions={getDividerExtraPositionsFromPoints(
            capacity,
            capacityDialog.index,
            xScale,
          )}
          finishDrag={finishDrag}
          height={height}
          isEditable={true}
          isDragging={isDragging}
          leftOffset={leftOffset}
          onCapacityDialogOpen={onCapacityDialogOpen}
          onDayInitChange={onDayInitChange}
          onDividerHover={onCapacityDividerHover}
          onHighlightCapacityDividerOff={onHighlightCapacityDividerOff}
          pointIndex={capacityDialog.index}
          startDrag={startDrag}
          xScale={xScale}
        />
      )}

      {noOpenDialog &&
        varianceEvents.map((event, i) => (
          <EventDivider
            key={`eventDivider_${event.id}`}
            varianceEvent={event}
            eventIndex={i}
            extremeEventDates={getVarianceExtremeDates(
              allWellAllocIssues,
              varianceEvents,
              event.id,
              today,
              'Variance Event',
            )}
            finishDrag={finishDrag}
            height={height}
            isAxisDragging={isAxisDragging}
            isEditable={false}
            isDragging={isDragging}
            leftOffset={leftOffset}
            onVarianceDialogOpen={onVarianceDialogOpen}
            onDividerHover={onEventDividerHover}
            onHighlightEventDividerOff={onHighlightEventDividerOff}
            onVarianceEventUpdate={onVarianceEventUpdate}
            startDrag={startDrag}
            varianceDialog={varianceDialog}
            xScale={xScale}
          />
        ))}

      {varianceDialog.show &&
        permissions.isAllowedEditVarEvents &&
        varianceEvents[varianceDialog.index] && (
          <>
            <EventDivider
              key={`eventDivider_${varianceEvents[varianceDialog.index].id}`}
              varianceEvent={varianceEvents[varianceDialog.index]}
              eventIndex={varianceDialog.index}
              extremeEventDates={getVarianceExtremeDates(
                allWellAllocIssues,
                varianceEvents,
                varianceEvents[varianceDialog.index].id,
                today,
                'Variance Event',
              )}
              finishDrag={finishDrag}
              height={height}
              isAxisDragging={isAxisDragging}
              isEditable={true}
              isDragging={isDragging}
              leftOffset={leftOffset}
              onVarianceDialogOpen={onVarianceDialogOpen}
              onDividerHover={onEventDividerHover}
              onHighlightEventDividerOff={onHighlightEventDividerOff}
              onVarianceEventUpdate={onVarianceEventUpdate}
              startDrag={startDrag}
              varianceDialog={varianceDialog}
              xScale={xScale}
            />
            {trellisTitle === 'BOE' && (
              <EventClickableArea
                eventProductionData={production}
                eventCapacityData={eventCapacityData[varianceDialog.index]}
                eventIndex={varianceDialog.index}
                isAxisDragging={isAxisDragging}
                onVarianceDialogOpen={onVarianceDialogOpen}
                productionKey={trellisTitle.toLowerCase()}
                title={trellisTitle}
                varianceEvent={varianceEvents[varianceDialog.index]}
                xScale={xScale}
                yScale={yScale}
              />
            )}
          </>
        )}

      {trellisTitle === 'BOE' &&
        noOpenDialog &&
        varianceEvents.map((varianceEvent, i) => {
          return (
            <EventClickableArea
              key={varianceEvent.id}
              eventProductionData={production}
              eventCapacityData={eventCapacityData[i]}
              eventIndex={i}
              isAxisDragging={isAxisDragging}
              onVarianceDialogOpen={onVarianceDialogOpen}
              productionKey={trellisTitle.toLowerCase()}
              title={trellisTitle}
              varianceEvent={varianceEvent}
              xScale={xScale}
              yScale={yScale}
            />
          );
        })}

      {noOpenDialog &&
        allocIssuesVisibility &&
        allWellAllocIssues.map((issue, i) => (
          <AllocIssueDivider
            key={`allocIssueDivider_${issue.id}`}
            allocIssue={issue}
            issueIndex={i}
            extremeAllocDates={getVarianceExtremeDates(
              allWellAllocIssues,
              varianceEvents,
              issue.id,
              today,
              'Allocation Issue',
            )}
            finishDrag={finishDrag}
            height={height}
            isAxisDragging={isAxisDragging}
            isEditable={false}
            isDragging={isDragging}
            leftOffset={leftOffset}
            onAllocIssueUpdate={onAllocIssueUpdate}
            onLocalAllocIssueUpdate={onLocalAllocIssueUpdate}
            onAllocIssueDividerHover={onAllocIssueDividerHover}
            onAllocIssueDialogOpen={onAllocIssueDialogOpen}
            onHighlightAllocIssueDividerOff={onHighlightAllocIssueDividerOff}
            startDrag={startDrag}
            xScale={xScale}
          />
        ))}

      {allocIssueDialog.show &&
        allocIssuesVisibility &&
        permissions.isAllowedEditAllocIssues &&
        allWellAllocIssues[allocIssueDialog.index] && (
          <AllocIssueDivider
            key={`allocIssueDivider_${
              allWellAllocIssues[allocIssueDialog.index].id
            }`}
            allocIssue={allWellAllocIssues[allocIssueDialog.index]}
            issueIndex={allocIssueDialog.index}
            extremeAllocDates={getVarianceExtremeDates(
              allWellAllocIssues,
              varianceEvents,
              allWellAllocIssues[allocIssueDialog.index].id,
              today,
              'Allocation Issue',
            )}
            finishDrag={finishDrag}
            height={height}
            isAxisDragging={isAxisDragging}
            isEditable={true}
            isDragging={isDragging}
            leftOffset={leftOffset}
            onAllocIssueUpdate={onAllocIssueUpdate}
            onLocalAllocIssueUpdate={onLocalAllocIssueUpdate}
            onAllocIssueDividerHover={onAllocIssueDividerHover}
            onAllocIssueDialogOpen={onAllocIssueDialogOpen}
            onHighlightAllocIssueDividerOff={onHighlightAllocIssueDividerOff}
            startDrag={startDrag}
            xScale={xScale}
          />
        )}
    </svg>
  );
};

export default SVGIndicatorTrellisInteraction;
