import Button from 'components/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const ENTER_KEYCODE = 13;

type ActionType = 'save' | 'edit';

interface LayoutDialogWindowProps {
  onClose: () => void;
  onSubmit: any;
  action: ActionType;
  oldName?: string;
  layoutId?: string;
}

const LayoutDialogWindow = ({
  onClose,
  onSubmit,
  action,
  oldName,
  layoutId,
}: LayoutDialogWindowProps) => {
  const [name, setName] = React.useState(oldName ? oldName : '');
  const text = React.useMemo(
    () =>
      action === 'save'
        ? 'Enter a name for this saved layout:'
        : `Rename the "${oldName}" layout:`,
    [],
  );

  const buttonLabel = React.useMemo(
    () => (action === 'save' ? 'Save' : `Rename`),
    [],
  );
  const handleKeyDown = React.useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      const { keyCode } = e;
      if (keyCode === ENTER_KEYCODE && !(name.length < 3 || oldName === name)) {
        if (action === 'save') {
          onSubmit(name);
        } else {
          onSubmit(layoutId, name);
        }
      }
    },
    [onSubmit, name],
  );

  const onChange = e => {
    const value = e.target.value;
    if (value.length > 23) return;
    setName(value);
  };

  React.useEffect(() => {
    document.addEventListener('keyup', handleKeyDown);
    return () => document.removeEventListener('keyup', handleKeyDown);
  }, [handleKeyDown]);

  return (
    <>
      <Dialog
        open={true}
        onEscapeKeyDown={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <LayoutDialogWindow.TitleBlock>
            <h2>{text}</h2>
          </LayoutDialogWindow.TitleBlock>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            type="text"
            fullWidth
            value={name}
            onChange={onChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={name.length < 3 || oldName === name}
            onClick={
              action === 'save'
                ? () => onSubmit(name)
                : () => onSubmit(layoutId, name)
            }
            width={70}
          >
            {buttonLabel}
          </Button>
          <Button onClick={onClose} width={70}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

LayoutDialogWindow.TitleBlock = styled.div`
  font-size: 1.25rem;
  font-family: 'Lato', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  line-height: 1.6;
  padding: 16px 0 5px;
`;

export default LayoutDialogWindow;
