import React from 'react';
import styled from 'styled-components';
import { ExtendedTabInfo, TabInfo } from './Model';
import { Actions } from './state/Actions';

import TabsContext from './TabsContext';
import { initialState, TabsReducer } from './state';

interface Props {
  children: (tabs: ExtendedTabInfo[]) => React.ReactNode;
  onTabChange?: (id: string) => void;
  defaultActive?: string;
}

export const TabsProvider = ({
  children,
  defaultActive,
  onTabChange,
}: Props) => {
  const [state, dispatch] = React.useReducer(TabsReducer, initialState);

  const context = React.useMemo(
    () => ({
      activeTab: state.activeTab,
      open: (id: string) => {
        dispatch(Actions.setActiveTab(id));
        onTabChange?.(id);
      },
      setTabInfo: (id: string, info: TabInfo) =>
        dispatch(Actions.setTabInfo({ id, info })),
    }),
    [state.activeTab, dispatch],
  );

  const tabs = React.useMemo(
    () =>
      Object.entries(state.tabs).map(([id, info]) => ({
        id,
        ...info,
        openTab: () => context.open(id),
      })),
    [context.open, state.tabs],
  );

  React.useEffect(() => {
    if (defaultActive) {
      dispatch(Actions.setActiveTab(defaultActive));
    }

    return () => dispatch(Actions.unload());
  }, []);

  return (
    <TabsContext.Provider value={context}>
      <TabsProvider.Container>{children(tabs)}</TabsProvider.Container>
    </TabsContext.Provider>
  );
};

TabsProvider.Container = styled.div`
  width: 100%;
  height: 100%;
`;
