import * as React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import Modal from 'components/ModalWindowV2';
import { AppSettingType } from 'modules/appSettings/model';
import { setAppConfigLocally } from 'modules/appConfig/AppConfigActions';
import Button from 'components/Button';
import { SettingInputs } from './SettingInputs';

interface Props {
  isOpen: boolean;
  close: () => void;
  appSettings: AppSettingType<Date | number>[];
}

export const AppSettingsModal = ({ appSettings, isOpen, close }: Props) => {
  const dispatch = useDispatch();
  const [tempSettings, setTempSettings] = React.useState({});

  const setLocally = React.useCallback((setting: string, value: any) => {
    setTempSettings(prev => ({
      ...prev,
      [setting]: value,
    }));
  }, []);

  const saveAppSettings = React.useCallback(() => {
    if (Object.keys(tempSettings).length)
      dispatch(setAppConfigLocally(tempSettings));

    close();
  }, [close, dispatch, tempSettings]);

  const dismissAppSettings = React.useCallback(() => {
    setTempSettings({});
    close();
  }, [close]);

  React.useEffect(() => {
    if (isOpen) return;

    setTempSettings({});
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} handleClose={close} title="App Settings" width={450}>
      <AppSettingsModal.Container>
        <SettingInputs
          appSettings={appSettings}
          tempSettings={tempSettings}
          setLocally={setLocally}
        />

        <AppSettingsModal.Description>
          Settings changed on this page will only affect you, and will be reset
          when the browser is refreshed or you log out and back in. To request a
          change to these default values for your company, please email{' '}
          <AppSettingsModal.Email
            href="mailto:support@wiserocksoftware.com"
            target="_blank"
          >
            support@wiserocksoftware.com
          </AppSettingsModal.Email>
        </AppSettingsModal.Description>

        <AppSettingsModal.ControlButtons>
          <Button width={84} height={30} onClick={saveAppSettings}>
            Save
          </Button>

          <Button width={84} height={30} onClick={dismissAppSettings}>
            Cancel
          </Button>
        </AppSettingsModal.ControlButtons>
      </AppSettingsModal.Container>
    </Modal>
  );
};

AppSettingsModal.Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

AppSettingsModal.Description = styled.p`
  font-size: 12px;
  line-height: 16px;
  width: 400px;
`;

AppSettingsModal.Email = styled.a`
  color: #41ab5d !important;
`;

AppSettingsModal.ControlButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;
