import gql from 'graphql-tag';

export const namespace = 'ALLOC_ISSUE_CAUSE';
export const FETCH_ALLOC_ISSUE_CAUSE = `${namespace}/FETCH_ALLOC_ISSUE_CAUSE`;

const FETCH_ALLOC_ISSUE_CAUSE_QUERY = gql`
  query {
    listAllocCauses {
      id
      cause
    }
  }
`;

export const fetchAllocIssueCause = () => ({
  type: FETCH_ALLOC_ISSUE_CAUSE,
  payload: {
    key: 'listAllocCauses',
    graphql: {
      query: FETCH_ALLOC_ISSUE_CAUSE_QUERY,
    },
  },
});
