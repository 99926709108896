const isBetweenY = (
  clientY: number,
  yCoords: { y1: number; y2: number },
): boolean => {
  const { y1, y2 } = yCoords;

  return clientY < y2 && clientY > y1;
};

export default isBetweenY;
