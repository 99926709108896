import React from 'react';
import styled from 'styled-components';

import { LineChartIcon, BarChartIcon } from 'components/Icons';
import { STEP_LINE_CHART } from '../models';

interface ChartTypeButtonProps {
  color: any;
  onChangeChartType: (event: MouseEvent) => void;
  type: string;
}
const ChartTypeButton = ({
  color,
  onChangeChartType,
  type,
}: ChartTypeButtonProps) => {
  return (
    <ChartTypeButton.Button onClick={onChangeChartType} color={color}>
      {type === STEP_LINE_CHART ? <LineChartIcon /> : <BarChartIcon />}
    </ChartTypeButton.Button>
  );
};

ChartTypeButton.Button = styled.button`
  padding: 0;
  border: none;
  border-left: ${props => props.theme.borders.thingray};
  outline: none;
  min-width: 23px;
  max-height: 20px;
  overflow: hidden;
  > svg {
    fill: ${props => props.color};
    width: 18px;
  }
`;

export default ChartTypeButton;
