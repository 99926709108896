import * as R from 'ramda';
import { all, call, delay, put, takeLatest, select } from 'redux-saga/effects';

import { getMinDate, getMaxDate, isIdNew } from 'helpers';
import { getDefaultAllocIssueStatus } from 'modules/allocIssueStatus/AllocIssueStatusReducer';
import { getAppConfig } from 'modules/appConfig/AppConfigReducer';
import { getId } from 'modules/auth/AuthReducer';
import { getWellCapacityDataByPhasePerEvent } from 'modules/capacityChangeEvent/CapacityChangeEventReducer';
import { FETCH_PHASES } from 'modules/phase/PhaseActions';
import { getPhasesById, getPhasesByName } from 'modules/phase/PhaseReducer';
import { getProduction } from 'modules/production/ProductionReducer';
import { setRightPanelDialog } from 'modules/ui/UIActions';
import { getCurrentWellId } from 'modules/ui/UIReducer';
import { getGraphqlPayload } from 'store/helpers';
import DB from 'database';

import {
  createRemoteAllocIssue,
  fetchAllAllocationIssues,
  populateAllocIssues,
  updateRemoteAllocIssue,
  FETCH_ALL_ALLOC_ISSUES,
  CREATE_REMOTE_ALLOC_ISSUE,
  CREATE_LOCAL_ALLOC_ISSUE,
  UPDATE_REMOTE_ALLOC_ISSUE,
  DELETE_ALLOC_ISSUE,
  INIT_CREATE_ALLOC_ISSUE,
  UPDATE_LOCAL_ALLOC_ISSUE,
} from './AllocIssueActions';
import { getWellAllocIssues } from './AllocIssueReducer';
import {
  normalizeAllocIssues,
  createNewAllocIssue,
  updateIssuePhaseVolumes,
} from './utils';

/**
 * Fetches all existed allocation issues for all wells
 */
function* fetchAllocIssueSaga(): Generator<any, any, any> {
  yield put(fetchAllAllocationIssues());
}

/**
 * Parses dates, inserts phase names, sorts by date and groups by well id.
 * @param {Object} action
 */
function* normalizeAllocIssuesSaga(action): Generator<any, any, any> {
  const rawIssues = getGraphqlPayload(action);
  const phasesById = yield select(getPhasesById);
  const normalizedIssues = normalizeAllocIssues(rawIssues, phasesById);

  yield put(populateAllocIssues(normalizedIssues));
}

/**
 * Prepares alloc issue for updating at api:
 * removes phase names, counts phase variance (if needed)
 * @param {Object} action
 */

function* updateRemoteAllocIssueSaga(action): Generator<any, any, any> {
  yield delay(700);
  const { updatedIssue, data } = action.payload;
  if (isIdNew(updatedIssue.id)) return;
  const phasesByName = yield select(getPhasesByName);
  const wellId = updatedIssue.wellId;
  const production = yield call(DB.getWellProduction, wellId);
  const appConfig = yield select(getAppConfig);
  const capacity = yield select(getWellCapacityDataByPhasePerEvent, {
    wellId,
    lastDate: appConfig.today,
  });
  const dataKeys = R.keys(data);
  const needToUpdateVolumes = dataKeys.some(
    key => key === 'phases' || key === 'dateStart' || key === 'dateEnd',
  );
  const mergedIssue = R.mergeRight(updatedIssue, data);
  const newIssueWithVolumes = needToUpdateVolumes
    ? updateIssuePhaseVolumes(mergedIssue, capacity, production)
    : mergedIssue;

  const normalizedPhases = newIssueWithVolumes.phases.map(issuePhase => {
    const { phaseName } = issuePhase;
    const phaseId = phasesByName[phaseName].id;

    return R.compose(
      R.assoc('phaseId', phaseId),
      R.omit(['phaseName']),
    )(issuePhase);
  });
  const newIssueWithoutPhaseNames = R.assoc(
    'phases',
    normalizedPhases,
    newIssueWithVolumes,
  );
  const updateData = R.omit(
    ['id', 'wellId', 'editorId'],
    newIssueWithoutPhaseNames,
  );

  yield put(updateRemoteAllocIssue({ id: updatedIssue.id, data: updateData }));
}

/**
 * Generates an Allocation issue or creating at api.
 * @param {Object} action
 */
function* createRemoteAllocIssueSaga(action): Generator<any, any, any> {
  const { dateStart, dateEnd, phases }: Record<string, any> = action.payload;
  const currentWellId = yield select(getCurrentWellId);
  const currentUserId: string = yield select(getId);
  const defaultAllocIssueStatus = yield select(getDefaultAllocIssueStatus);
  const createData = {
    wellId: currentWellId,
    statusId: defaultAllocIssueStatus.id,
    phases: R.map(issuePhase => R.omit(['phaseName'], issuePhase), phases),
    assigneeId: currentUserId,
    dateStart,
    dateEnd,
    causes: [],
  };

  yield put(createRemoteAllocIssue(createData));
}

/**
 * Generates an alloc issue for optimistic update and inserts it in store
 * @param {Object} action
 */
function* createLocalAllocIssueSaga(action): Generator<any, any, any> {
  const { date } = action.payload;
  const currentWellId = yield select(getCurrentWellId);
  const currentUserId = yield select(getId);
  const issues = yield select(getWellAllocIssues, { wellId: currentWellId });
  const phasesByName = yield select(getPhasesByName);
  const production = yield select(getProduction);
  const appConfig = yield select(getAppConfig);
  const capacity = yield select(getWellCapacityDataByPhasePerEvent, {
    wellId: currentWellId,
    lastDate: appConfig.today,
  });
  const newIssue = createNewAllocIssue(
    date,
    production,
    capacity,
    phasesByName,
    currentWellId,
    currentUserId,
  );
  const newIssues = R.compose(
    R.sort((a, b) => a.dateStart.getTime() - b.dateStart.getTime()),
    R.append(newIssue),
  )(issues);

  yield put(populateAllocIssues({ [currentWellId]: newIssues }));

  const index = newIssues.findIndex(issue => issue.id === newIssue.id);
  const data = { index, id: newIssue.id };
  yield put(setRightPanelDialog({ type: 'AllocationIssue', data }));
}

/**
 * Updates allocation issue locally for optimstic update:
 * counts phase variance, normalizes dates.
 * @param {Object} action
 */
function* updateLocalAllocIssueSaga(action): Generator<any, any, any> {
  const { updatedIssue, data, interactive } = action.payload;
  const wellId = updatedIssue.wellId;
  const issues = yield select(getWellAllocIssues, { wellId });
  const production = interactive
    ? yield select(getProduction)
    : yield call(DB.getWellProduction, wellId);
  const appConfig = yield select(getAppConfig);
  const capacity = yield select(getWellCapacityDataByPhasePerEvent, {
    wellId,
    lastDate: appConfig.today,
  });
  const dataKeys = R.keys(data);
  const needToUpdateVolumes = dataKeys.some(
    key => key === 'phases' || key === 'dateStart' || key === 'dateEnd',
  );
  const newIssues = issues.map(issue => {
    if (issue.id !== updatedIssue.id) return issue;
    const newIssue = R.mergeDeepRight(updatedIssue, data);
    const newIssueWithNormalizedDates = R.compose(
      R.assoc('dateStart', getMinDate(newIssue.dateEnd, newIssue.dateStart)),
      R.assoc('dateEnd', getMaxDate(newIssue.dateEnd, newIssue.dateStart)),
    )(newIssue);
    if (needToUpdateVolumes) {
      const newIssueWithUpdatedVolumes = updateIssuePhaseVolumes(
        newIssueWithNormalizedDates,
        capacity,
        production,
      );

      return newIssueWithUpdatedVolumes;
    }

    return newIssueWithNormalizedDates;
  });

  yield put(populateAllocIssues({ [wellId]: newIssues }));
}

// /**
//  * Updates local alloc issue with id, returned from api after update or creation
//  * @param {Object} action
//  */
// function* updateNewAllocIssueIdSaga(action): Generator<any,any,any> {
//   const newIssue = getGraphqlPayload(action);
//   const wellId = newIssue.wellId;
//   const wellIssues = yield select(getWellAllocIssues, { wellId });
//   const localIssueIndex = wellIssues.findIndex(issue => isIdNew(issue.id));
//   const updatedIssue = R.assoc('id', newIssue.id, wellIssues[localIssueIndex]);
//   const newWellIssues = R.update(localIssueIndex, updatedIssue, wellIssues);

//   yield put(populateAllocIssues({ [wellId]: newWellIssues }));
// }

function* allocIssueSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(`${FETCH_PHASES}_SUCCESS`, fetchAllocIssueSaga),
    takeLatest(`${FETCH_ALL_ALLOC_ISSUES}_SUCCESS`, normalizeAllocIssuesSaga),
    takeLatest(INIT_CREATE_ALLOC_ISSUE, createRemoteAllocIssueSaga),
    takeLatest(
      [
        `${CREATE_REMOTE_ALLOC_ISSUE}_FAIL`,
        `${DELETE_ALLOC_ISSUE}_FAIL`,
        `${UPDATE_REMOTE_ALLOC_ISSUE}_FAIL`,
      ],
      fetchAllocIssueSaga,
    ),
    // takeLatest(
    //   `${CREATE_REMOTE_ALLOC_ISSUE}_SUCCESS`,
    //   updateNewAllocIssueIdSaga,
    // ),
    takeLatest(CREATE_LOCAL_ALLOC_ISSUE, createLocalAllocIssueSaga),
    takeLatest(UPDATE_LOCAL_ALLOC_ISSUE, updateLocalAllocIssueSaga),
    takeLatest(UPDATE_LOCAL_ALLOC_ISSUE, updateRemoteAllocIssueSaga),
  ]);
}

export default allocIssueSagas;
