import React from 'react';
import styled from 'styled-components';

import Button from 'components/Button';
import { DeleteIcon, EditIcon } from 'components/Icons';
import { SeriesLayoutOption } from '../models/seriesLayouts';

interface LayaoutOptionControlButtonsProps {
  onDeleteLayaout: any;
  onRenameLayout: any;
  layout: SeriesLayoutOption;
}

const LayaoutOptionControlButtons = ({
  onDeleteLayaout,
  onRenameLayout,
  layout,
}: LayaoutOptionControlButtonsProps) => {
  return (
    <>
      <LayaoutOptionControlButtons.Wrapper>
        <Button
          transparent
          width={18}
          height={13}
          onClick={e => {
            e.stopPropagation();
            onRenameLayout(layout.id);
          }}
        >
          <EditIcon color={'#000000'} />
        </Button>

        <Button
          transparent
          width={18}
          height={13}
          onClick={e => {
            e.stopPropagation();
            onDeleteLayaout(layout.id);
          }}
        >
          <DeleteIcon color="#000000" />
        </Button>
      </LayaoutOptionControlButtons.Wrapper>
    </>
  );
};

LayaoutOptionControlButtons.Wrapper = styled.div`
  display: grid;
  grid-template-columns: 20px 20px;
  gap: 2px;
  padding: 2px 0 3px;
`;

export default LayaoutOptionControlButtons;
