import { utcDay } from 'd3-time';
import * as R from 'ramda';

import { Phase, OIL, GAS, WATER } from 'modules/phase/models/phase';

import type { AllocIssue } from '../models/allocIssue';

const defaultPhasesMap = {
  '1': { id: '1', phaseName: GAS },
  '2': { id: '2', phaseName: OIL },
  '3': { id: '3', phaseName: WATER },
};

export const normalizeAllocIssues = (
  rawIssues: Record<string, any>[],
  phasesById: { [id: string]: Phase } = defaultPhasesMap,
) => {
  const withDates = R.map<AllocIssue, Record<string, any>>(
    rawIssue =>
      R.compose(
        R.assoc<Date, AllocIssue>(
          'dateStart',
          utcDay.round(new Date(rawIssue.dateStart)),
        ),
        R.assoc<AllocIssue, Record<string, any>>(
          'dateEnd',
          utcDay.round(new Date(rawIssue.dateEnd)),
        ),
        R.assoc(
          'phases',
          rawIssue.phases.map(allocIssuePhase =>
            R.assoc(
              'phaseName',
              phasesById[allocIssuePhase.phaseId].phaseName,
              allocIssuePhase,
            ),
          ),
        ),
      )(rawIssue),
    rawIssues,
  );

  const grouped = R.groupBy<AllocIssue>(R.prop('wellId'), withDates);
  const sorted = R.map<AllocIssue[], AllocIssue[]>(
    issues =>
      issues.sort((a, b) => a.dateStart.getTime() - b.dateStart.getTime()),
    grouped,
  );

  return sorted;
};

export default normalizeAllocIssues;
