import type { TrellisTooltipData } from 'modules/ui/models/ui';

const getDataForTooltip = (
  value: number,
  pointerData: TrellisTooltipData,
  varianceData?: { date: Date; variance: number }[],
): TrellisTooltipData | null => {
  if (
    !varianceData &&
    pointerData &&
    (value <= pointerData.production || value <= pointerData.capacity)
  ) {
    return pointerData;
  } else if (
    varianceData &&
    pointerData.variance !== null &&
    pointerData.variance !== undefined
  ) {
    if (
      (value >= 0 && value <= pointerData.variance) ||
      (value < 0 && value > pointerData.variance)
    )
      return pointerData;
  }
  return null;
};
export default getDataForTooltip;
