import * as R from 'ramda';

import { CHARTS_ORDER } from '../models/chart';

const orderChartsNames = (showChartsMap: {
  [ket: string]: boolean;
}): string[] => {
  const displayedCharts = R.compose(
    R.keys,
    R.pickBy(val => val === true),
  )(showChartsMap);
  return displayedCharts.sort((a, b) => CHARTS_ORDER[a] - CHARTS_ORDER[b]);
};

export default orderChartsNames;
