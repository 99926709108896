import ButtonDropdownSplit from 'components/ButtonDropdownSplit';

import useFiltersLayouts from '../hooks/useFiltersLayouts';

type FilterLayoutsDropdownButtonProps = {
  onClick: () => any;
};

const FilterLayoutsDropdownButton = ({
  onClick,
}: FilterLayoutsDropdownButtonProps) => {
  const {
    baseFilterLayouts,
    userFilterLayouts,
    currentFilterLayout,
    setCurrentFilterLayout,
  } = useFiltersLayouts();

  const hasBaseLayouts = Boolean(baseFilterLayouts.length);
  const hasUserLayouts = Boolean(userFilterLayouts.length);

  return (
    <ButtonDropdownSplit
      buttonProps={{ width: 144 }}
      buttonAction={onClick}
      buttonChildren="Edit Table Layout"
    >
      {(hasBaseLayouts || hasUserLayouts) && (
        <>
          {hasBaseLayouts && (
            <ButtonDropdownSplit.MenuTitle>
              Shared Layouts:
            </ButtonDropdownSplit.MenuTitle>
          )}
          {baseFilterLayouts.map(layout => (
            <ButtonDropdownSplit.MenuItem
              isActive={layout.id === currentFilterLayout.id}
              key={layout.name}
              onClick={() => setCurrentFilterLayout(layout)}
            >
              {layout.name}
            </ButtonDropdownSplit.MenuItem>
          ))}

          {hasUserLayouts && (
            <ButtonDropdownSplit.MenuTitle>
              Your Layouts:
            </ButtonDropdownSplit.MenuTitle>
          )}
          {userFilterLayouts.map(layout => (
            <ButtonDropdownSplit.MenuItem
              isActive={layout.id === currentFilterLayout.id}
              key={layout.name}
              onClick={() => setCurrentFilterLayout(layout)}
            >
              {layout.name}
            </ButtonDropdownSplit.MenuItem>
          ))}
        </>
      )}
    </ButtonDropdownSplit>
  );
};

export default FilterLayoutsDropdownButton;
