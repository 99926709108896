import isBetweenX from './isBetweenX';
import isBetweenY from './isBetweenY';

const isInside = (
  mouseCoords: { clientX: number; clientY: number },
  areaCoords: { x1: number; x2: number; y1: number; y2: number },
): boolean => {
  const { clientX, clientY } = mouseCoords;

  return isBetweenX(clientX, areaCoords) && isBetweenY(clientY, areaCoords);
};

export default isInside;
