import * as React from 'react';
import styled from 'styled-components';
import { RiArrowDropDownLine } from 'react-icons/ri';

import ButtonDropdownSplit from '../ButtonDropdownSplit';
import ClickOutside from '../ClickOutside';

type ButtonDropdownProps = {
  children: React.ReactNode;
  title: React.ReactNode;
};

const ButtonDropdown = ({ title, children }: ButtonDropdownProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <ClickOutside action={() => setIsOpen(false)}>
      <ButtonDropdown.Wrapper className="nuept">
        <ButtonDropdown.ToggleButton
          onClick={() => setIsOpen(!isOpen)}
          isOpen={isOpen}
        >
          <ButtonDropdown.ToggleButtonTitle>
            {title}
          </ButtonDropdown.ToggleButtonTitle>
          <ButtonDropdown.ToggleButtonArrow />
        </ButtonDropdown.ToggleButton>

        {isOpen && (
          <ButtonDropdown.Menu onClick={() => setIsOpen(false)}>
            {children}
          </ButtonDropdown.Menu>
        )}
      </ButtonDropdown.Wrapper>
    </ClickOutside>
  );
};

ButtonDropdown.Wrapper = styled.div`
  position: relative;
`;

ButtonDropdown.ToggleButton = styled.div`
  display: grid;
  grid-template-columns: auto 25px;

  padding-top: auto;
  height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: linear-gradient(180deg, #f3f3f3 0%, #e1e1e1 100%);
  border: 1px solid #c1c1c1;
  box-shadow: 0 2px 4px rgb(0 0 0 / 15%);
  font-family: 'Lato', sans-serif;
  color: #484848;

  :hover {
    cursor: pointer;
  }
`;

ButtonDropdown.ToggleButtonTitle = styled.span`
  padding-left: 5px;
  padding-top: 7px;
`;

ButtonDropdown.ToggleButtonArrow = styled(RiArrowDropDownLine)`
  font-size: 30px;
`;

ButtonDropdown.Menu = styled(ButtonDropdownSplit.Menu)`
  right: 0;
  width: 100%;
`;
ButtonDropdown.MenuTitle = ButtonDropdownSplit.MenuTitle;
ButtonDropdown.MenuItem = ButtonDropdownSplit.MenuItem;

export default ButtonDropdown;
