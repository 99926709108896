import gql from 'graphql-tag';

import type { GraphqlQueryAction } from 'store/models';

export const namespace = 'CATEGORY';
export const FETCH_CAPACITY_CATEGORIES = `${namespace}/FETCH_CAPACITY_CATEGORIES`;
export const FETCH_VARIANCE_CATEGORIES = `${namespace}/FETCH_VARIANCE_CATEGORIES`;

const FETCH_CAPACITY_CATEGORIES_QUERY = gql`
  query {
    listCapacityChangeOptions {
      id
      planType
      cause
      subCause
      color
      extraInputsType
      order
    }
  }
`;
type fetchCapacityCategoriesAC = () => GraphqlQueryAction;

export const fetchCapacityCategories: fetchCapacityCategoriesAC = () => ({
  type: FETCH_CAPACITY_CATEGORIES,
  payload: {
    key: 'listCapacityChangeOptions',
    graphql: {
      query: FETCH_CAPACITY_CATEGORIES_QUERY,
    },
  },
});

const FETCH_VARIANCE_CATEGORIES_QUERY = gql`
  query {
    listVarianceOptions {
      id
      planType
      cause
      subCause
      color
      extraInputsType
      order
    }
  }
`;
type fetchVarianceCategoriesAC = () => GraphqlQueryAction;

export const fetchVarianceCategories: fetchVarianceCategoriesAC = () => ({
  type: FETCH_VARIANCE_CATEGORIES,
  payload: {
    key: 'listVarianceOptions',
    graphql: {
      query: FETCH_VARIANCE_CATEGORIES_QUERY,
    },
  },
});
