import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as React from 'react';

import Button from 'components/Button';
import Input from 'components/Input';

const ENTER_KEYCODE = 13;
const ESC_KEYCODE = 27;
const C_KEYCODE = 67;

type ShareLinkWindowProps = {
  handleClose: () => void;
  shareId: string;
};

const ShareLinkWindow = ({ handleClose, shareId }: ShareLinkWindowProps) => {
  const inputEl: React.RefObject<HTMLInputElement | null> =
    React.useRef<HTMLInputElement>(null);

  const copyToClipboard = React.useCallback(() => {
    if (inputEl && inputEl.current) {
      inputEl.current.select();
      document.execCommand('copy');
    }
  }, [inputEl]);

  const handleKeyDownInput = React.useCallback(
    e => {
      if (e.keyCode === C_KEYCODE && (e.metaKey || e.ctrlKey)) {
        copyToClipboard();
      }
    },
    [copyToClipboard],
  );

  React.useEffect(() => {
    const handleKCloseOnEsc = (e: KeyboardEvent) => {
      e.preventDefault();
      const { keyCode } = e;
      if (keyCode === ENTER_KEYCODE || keyCode === ESC_KEYCODE) handleClose();
    };
    document.addEventListener('keydown', handleKCloseOnEsc);
    return () => document.removeEventListener('keydown', handleKCloseOnEsc);
  }, [handleClose]);

  return (
    <Dialog
      open={true}
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <DialogTitle>Share Link to the Current Application View</DialogTitle>
      <DialogContent>
        <Input
          //@ts-expect-error
          ref={inputEl}
          value={`${window.location.origin}/share/${shareId}`}
          readOnly
          style={{ width: '600px', marginBottom: '10px' }}
          isValid
          onFocus={e => e.target.select()}
          onKeyDown={e => handleKeyDownInput(e)}
        />
        <Button onClick={copyToClipboard} width={150}>
          Copy to clipboard
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} width={70}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareLinkWindow;
