import { SensorSeriesMapping, NormalizedSensorSeriesMapping } from '../models';

const normalizeSensorSeriesMapping = (
  mapping: SensorSeriesMapping[],
): NormalizedSensorSeriesMapping => {
  const normalizedMapping = mapping.reduce<NormalizedSensorSeriesMapping>(
    (acc, data) => {
      acc[`s${data.sensorSeriesId}`] = {
        name: data.name,
        sensorSeriesId: `s${data.sensorSeriesId}`,
        source: data.source,
        color: data.color,
        units: data.units,
        tags: data.wellSensorSeries.map(t => ({
          tagId: t.tagId,
          wellId: t.wellId,
        })),
      };
      return acc;
    },
    {},
  );

  return normalizedMapping;
};

export default normalizeSensorSeriesMapping;
