import { useRouteMatch } from 'react-router-dom';

import paths from 'modules/router/utils/paths';
import InputPersistor from './DashboardsInputPersistor';
import OutputPersistor from './DashboardsOutputPersistor';

const DashboardsPersistor = () => {
  const match = useRouteMatch(paths.SPOTFIRE_DASHBOARD());

  if (match) return null;

  return (
    <>
      <InputPersistor />
      <OutputPersistor />
    </>
  );
};

export default DashboardsPersistor;
