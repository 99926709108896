import React from 'react';
import { debounce } from 'throttle-debounce';

const useDebouncedCallback = (
  callback: (...args: any[]) => any,
  deps = [] as any[],
  time = 200,
  atBegin = false,
) => {
  const debounced = React.useMemo(() => {
    return debounce(time, atBegin, callback);
  }, deps);

  React.useEffect(() => {
    return () => debounced.cancel();
  }, []);

  return debounced;
};

export default useDebouncedCallback;
