import { Reducer } from 'react';
import type { IAction, TabState } from '../Model';

export const initialState: TabState = {
  activeTab: null,
  tabs: {},
};

export const TabsReducer: Reducer<TabState, IAction> = (state, action) => {
  switch (action.type) {
    case 'SET_TAB_INFO':
      const { id, info } = action.payload;
      return { ...state, tabs: { ...state.tabs, [id]: info } };
    case 'SET_ACTIVE_TAB': {
      return {
        ...state,
        activeTab: action.payload,
      };
    }
    case 'UNLOAD':
      return initialState;
    default:
      return state;
  }
};
