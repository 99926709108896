import { all, put, select, take, takeLatest } from 'redux-saga/effects';

import { fetchNotes } from 'modules/notes/NotesActions';
import { SET_CURRENT_WELL_ID } from 'modules/ui/UIActions';
import { getCurrentWellId } from 'modules/ui/UIReducer';
import { INIT_FETCH_SECONDARY_CHART_DATA } from 'modules/chart/ChartActions';

function* fetchNotesForCurrentWellSaga(): Generator<any, any, any> {
  yield take(INIT_FETCH_SECONDARY_CHART_DATA);

  if (
    window.location.pathname.includes('/dashboard') ||
    window.location.pathname.includes('/filter') ||
    window.location.pathname.includes('/share')
  ) {
    const currentWellId = yield select(getCurrentWellId);
    yield put(fetchNotes(currentWellId));
  }
}

function* notesSagas(): Generator<any, any, any> {
  yield all([takeLatest(SET_CURRENT_WELL_ID, fetchNotesForCurrentWellSaga)]);
}

export default notesSagas;
