import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';
import { throttle } from 'throttle-debounce';

import { isInside } from 'modules/chart/utils';
import { NormalizedSeriesMapping } from 'modules/series/models';
import useMenu from 'hooks/useMenu';

import {
  AvailableDataSeries,
  ChartOptionsGroup,
  ListChartOptions,
} from '../models';
import ChartOptionsSection from './ChartOptionsSection';
import ColorPicker from './ColorPicker';

interface DragAreaProps {
  // availableOptios: AvailableOptionsList;
  chartOptions: ListChartOptions;
  chartOptionsGroups: ChartOptionsGroup[];
  onAddOptionToAvailable: (
    groupId: string,
    option: AvailableDataSeries,
  ) => void;
  onAddOptionToGroup: (data: {
    groupId: string;
    optionId: string;
    newOptionIndex: number;
    previousGroupId?: string;
  }) => void;
  onCreateNewGroup: (data: {
    optionId: string;
    newGroupIndex?: number;
    groupId?: string;
  }) => void;
  onRemoveGroup: (data: ChartOptionsGroup) => void;
  onSetNewGroupOrder: (group: ChartOptionsGroup[]) => void;
  onSetNewOptionsOrder: (option: ChartOptionsGroup) => void;
  onSwitchChartType: (optionId: string, type: string) => void;
  onSwitchOptionVisibility: (optionId: string) => void;
  seriesMapping: NormalizedSeriesMapping;
  onSetCustomColor: (id: string, color: string) => void;
}
const DragArea = ({
  chartOptions,
  chartOptionsGroups,
  onAddOptionToAvailable,
  onAddOptionToGroup,
  onCreateNewGroup,
  onSetCustomColor,
  onSetNewGroupOrder,
  onSetNewOptionsOrder,
  onSwitchChartType,
  onSwitchOptionVisibility,
  seriesMapping,
}: DragAreaProps) => {
  const [groups, setGroups] = React.useState(chartOptionsGroups);
  const [hoveredGroupIndex, setHoveredGroupIndex] = React.useState(null);
  const [hoveredOptionIndex, setHoveredOptionIndex] = React.useState(null);
  const [tempGroupIndex, setTempGroupIndex] = React.useState(null);
  const [isShowOriginOption, setIsShowOriginOption] = React.useState(true);
  const [isInsideDragArea, setIsInsideDragArea] = React.useState(false);

  const dragAreaEl = React.useRef<HTMLElement>(null);

  const onSetIsShowOriginOption = React.useCallback(
    (value: boolean) => {
      setIsShowOriginOption(value);
    },
    [setIsShowOriginOption],
  );

  const onSetTempGroupIndex = React.useCallback(
    index => {
      setTempGroupIndex(index);
    },
    [setTempGroupIndex],
  );
  const onSetHoveredGroupIndex = React.useCallback(
    index => {
      setHoveredGroupIndex(index);
    },
    [setHoveredGroupIndex],
  );
  const onSetHoveredOptionIndex = React.useCallback(
    index => {
      if (index !== hoveredOptionIndex) {
        setHoveredOptionIndex(index);
      }
    },
    [setHoveredOptionIndex, hoveredOptionIndex],
  );
  const onStopHovering = React.useCallback(() => {
    setHoveredGroupIndex(null);
    setHoveredOptionIndex(null);
    onSetTempGroupIndex(null);
  }, [setHoveredGroupIndex, setHoveredOptionIndex, onSetTempGroupIndex]);

  const onMoveGroup = React.useCallback(
    (groupIndex: number, hoveredGroupIndex: number) => {
      const currentEl = groups[groupIndex];
      const newArr = [...groups];
      newArr.splice(groupIndex, 1);
      newArr.splice(hoveredGroupIndex, 0, currentEl);
      setGroups(newArr);
    },
    [groups, setGroups],
  );

  const onStopGroupMoving = React.useCallback(() => {
    onSetNewGroupOrder(groups);
  }, [onSetNewGroupOrder, groups]);

  const throttleSetHoveredOptionIndex = React.useCallback(
    throttle(100, true, index => onSetHoveredOptionIndex(index)),
    [hoveredOptionIndex],
  );
  const throttleSetTempGroupIndex = React.useCallback(
    throttle(100, true, index => onSetTempGroupIndex(index)),
    [],
  );

  const throttleSetHoveredGroupIndex = React.useCallback(
    throttle(100, true, index => onSetHoveredGroupIndex(index)),
    [hoveredGroupIndex],
  );

  const colorPickerEl = React.useRef(null);
  const [isMenuOpen, onMenuHover, onMenuLeave, onTargetClick, closeMenu] =
    useMenu(colorPickerEl);
  const [colorPickerPosition, setColorPickerPosition] = React.useState(0);
  const [selectedOptionId, setSelectedOptionId] = React.useState('');

  const onChartTypeMenuOpen = React.useCallback(
    ({ optionId, elemRect, event }) => {
      if (isMenuOpen) {
        closeMenu();
      }
      onTargetClick(event);
      setColorPickerPosition(elemRect.bottom - 3);
      setSelectedOptionId(optionId);
    },
    [
      closeMenu,
      isMenuOpen,
      onTargetClick,
      setColorPickerPosition,
      setSelectedOptionId,
    ],
  );
  const onColorChange = React.useCallback(
    (color: string) => {
      onSetCustomColor(selectedOptionId, color);
    },
    [onSetCustomColor, selectedOptionId],
  );

  React.useEffect(() => {
    setGroups(chartOptionsGroups);
  }, [chartOptionsGroups, setGroups]);

  const handleDrag = React.useCallback(
    (e: DragEvent) => {
      const { clientX, clientY } = e;
      const currentPointerPosition = {
        clientX,
        clientY,
      };
      if (dragAreaEl && dragAreaEl.current) {
        const dragAreaRect = dragAreaEl.current.getBoundingClientRect();
        const dragAreaCoordinates = {
          x1: dragAreaRect.x,
          x2: dragAreaRect.x + dragAreaRect.width,
          y1: dragAreaRect.y,
          y2: dragAreaRect.y + dragAreaRect.height,
        };
        const tempIsInsideDragArea = isInside(
          currentPointerPosition,
          dragAreaCoordinates,
        );
        if (tempIsInsideDragArea !== isInsideDragArea) {
          setIsInsideDragArea(tempIsInsideDragArea);
        }
      }
    },
    [dragAreaEl, isInsideDragArea, setIsInsideDragArea],
  );

  React.useEffect(() => {
    document.addEventListener('drag', handleDrag);
    return () => {
      document.removeEventListener('drag', handleDrag);
    };
  }, [handleDrag]);

  return (
    <DragArea.Wrapper ref={dragAreaEl}>
      <DragArea.AvailableWrapper>
        <ChartOptionsSection
          groups={groups}
          onAddOptionToGroup={onAddOptionToGroup}
          itemType="selected"
          hoveredGroupIndex={hoveredGroupIndex}
          hoveredOptionIndex={hoveredOptionIndex}
          onChartTypeMenuOpen={onChartTypeMenuOpen}
          onCreateNewGroup={onCreateNewGroup}
          onStopGroupMoving={onStopGroupMoving}
          onMoveGroup={onMoveGroup}
          onSetHoveredGroupIndex={throttleSetHoveredGroupIndex}
          onSetHoveredOptionIndex={throttleSetHoveredOptionIndex}
          onSetNewOptionsOrder={onSetNewOptionsOrder}
          onStopHovering={onStopHovering}
          onSetTempGroupIndex={throttleSetTempGroupIndex}
          onSwitchOptionVisibility={onSwitchOptionVisibility}
          onRemoveOption={onAddOptionToAvailable}
          options={chartOptions}
          seriesMapping={seriesMapping}
          tempGroupIndex={tempGroupIndex}
          isInsideAvaliableArea={false}
          isInsideDragArea={isInsideDragArea}
          onSetIsShowOriginOption={onSetIsShowOriginOption}
          isShowOriginOption={isShowOriginOption}
        />
      </DragArea.AvailableWrapper>
      {isMenuOpen && (
        <DragArea.ColorPickerWrapper
          ref={colorPickerEl}
          top={colorPickerPosition}
        >
          <ColorPicker
            top={colorPickerPosition}
            color={R.pathOr('#000', [selectedOptionId, 'color'], seriesMapping)}
            customColor={R.pathOr(
              '',
              [selectedOptionId, 'customColor'],
              chartOptions,
            )}
            onColorChange={onColorChange}
            onMenuLeave={onMenuLeave}
            onMenuHover={onMenuHover}
            option={chartOptions[selectedOptionId]}
            onSetChartType={onSwitchChartType}
          />
        </DragArea.ColorPickerWrapper>
      )}
    </DragArea.Wrapper>
  );
};

DragArea.Wrapper = styled.div``;
DragArea.AvailableWrapper = styled.div`
  height: 100%;
`;

DragArea.ColorPickerWrapper = styled.div`
  position: fixed;
  top: ${props => props.top}px;
  left: 30px;
  z-index: 100;
`;

export default DragArea;
