import * as React from 'react';
import { CapacityVarianceContext } from './CapacityVarianceContext';
import { Phase } from './model';

interface Props {
  eventId: string;
}

export const useCapacityChange = ({ eventId }: Props) => {
  const context = React.useContext(CapacityVarianceContext);

  if (!context)
    throw new Error(
      'CapacityVarianceContext must be used within a CapacityVarianceProvider!',
    );

  const actions = React.useMemo(
    () => ({
      changeBFactor: (phase: Phase, date: Date, rate: number) =>
        context.changeBFactor(eventId, phase, date, rate),
      changeDecline: (phase: Phase, date: Date, rate: number) =>
        context.changeDecline(eventId, phase, date, rate),
      changeInitialRate: (phase: Phase, rate: number) =>
        context.changeInitialRate(eventId, phase, rate),
      finishEditingCurve: () => context.finishEditingCurve(eventId),
    }),
    [context.changeBFactor, context.changeDecline, context.changeInitialRate],
  );

  return actions;
};
