import * as R from 'ramda';

import type { Well } from 'modules/well/models/well';
import { countBoeValue } from 'modules/production/utils';

import { VarianceDrilldownTableItem } from '../models/drilldownTable';
import populateEntryWithGroupInfo from './populateEntryWithGroupInfo';

const normalizeForecastDrilldownTable = (
  rawDrilldownTable: Record<string, any>[],
  wells: { [wellId: string]: Well },
  additionalKeys: string[],
): VarianceDrilldownTableItem[] => {
  const withoutGroups = rawDrilldownTable.map(data => {
    const oilForecast = R.pathOr(null, ['oilForecast'], data);
    const gasForecast = R.pathOr(null, ['gasForecast'], data);
    const waterForecast = R.pathOr(null, ['waterForecast'], data);
    const oilCapacity = R.pathOr(0, ['oilCapacity'], data);
    const gasCapacity = R.pathOr(0, ['gasCapacity'], data);
    const waterCapacity = R.pathOr(0, ['waterCapacity'], data);
    const oilVariance = R.pathOr(0, ['oilVariance'], data);
    const gasVariance = R.pathOr(0, ['gasVariance'], data);
    const waterVariance = R.pathOr(0, ['waterVariance'], data);

    const newOilVariance =
      (R.isNil(oilVariance) || oilVariance === 0) &&
      (R.isNil(oilForecast) || oilForecast === 0) &&
      !R.isNil(oilCapacity)
        ? oilCapacity
        : oilVariance;
    const newGasVariance =
      (R.isNil(gasVariance) || gasVariance === 0) &&
      (R.isNil(gasForecast) || gasForecast === 0) &&
      !R.isNil(gasCapacity)
        ? gasCapacity
        : gasVariance;
    const newWaterVariance =
      (R.isNil(waterVariance) || waterVariance === 0) &&
      (R.isNil(waterForecast) || waterForecast === 0) &&
      !R.isNil(waterCapacity)
        ? waterCapacity
        : waterVariance;

    return R.compose(
      R.assoc<string | null, VarianceDrilldownTableItem>(
        'wellId',
        data.wellId ? data.wellId.toString() : null,
      ),
      R.assoc<string | null, Record<string, any>>(
        'well',
        data.wellId && wells[data.wellId] ? wells[data.wellId].LEASE : null,
      ),
      R.assoc('oilVariance', newOilVariance),
      R.assoc('gasVariance', newGasVariance),
      R.assoc('waterVariance', newWaterVariance),
      R.assoc('boeVariance', countBoeValue(newOilVariance, newGasVariance)),
      R.assoc('boeForecast', countBoeValue(oilForecast, gasForecast)),
      R.assoc('boeCapacity', countBoeValue(oilCapacity, gasCapacity)),
    )(data);
  });
  return withoutGroups.map(entry =>
    populateEntryWithGroupInfo(entry, additionalKeys, wells),
  );
};

export default normalizeForecastDrilldownTable;
