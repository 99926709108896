import gql from 'graphql-tag';

import type { Action, GraphqlMutationAction } from 'store/models';

export const namespace = 'REQUEST_HELP';
export const SET_SHOW_SUCCESS_MESSAGE = `${namespace}/SET_SHOW_SUCCESS_MESSAGE`;
export const REQUEST_HELP = `${namespace}/REQUEST_HELP`;
export const SUBMIT_HELP_MESSAGE = `${namespace}/SUBMIT_HELP_MESSAGE`;

type setShowSuccessMessageAC = (show: boolean) => Action;

export const setShowSuccessMessage: setShowSuccessMessageAC = show => ({
  type: SET_SHOW_SUCCESS_MESSAGE,
  payload: show,
});

const REQUEST_HELP_MUTATION = gql`
  mutation ($payload: RequestHelpInput!) {
    requestHelp(data: $payload)
  }
`;

type submitHelpMessageAC = (message: string) => Action;

export const submitHelpMessage: submitHelpMessageAC = message => ({
  type: SUBMIT_HELP_MESSAGE,
  payload: message,
});

type requestHelpAC = (payload: { message: string }) => GraphqlMutationAction;

export const requestHelp: requestHelpAC = payload => ({
  type: REQUEST_HELP,
  payload: {
    key: 'requestHelp',
    graphql: {
      mutation: REQUEST_HELP_MUTATION,
      variables: { payload },
    },
  },
});
