import * as React from 'react';
import * as R from 'ramda';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import { FaPlusCircle } from 'react-icons/fa';

import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';
import { CloseIcon } from 'components/Icons';
import Button from 'components/Button';

interface CauseFormProps {
  allocIssue: AllocIssue;
  allocIssueCauses: { [id: string]: { id: string; cause: string } };
  disabled: boolean;
  onAllocIssueUpdate: (data: {
    updatedIssue: AllocIssue;
    data: Record<string, any>;
  }) => void;
}

const CauseForm = ({
  allocIssueCauses,
  allocIssue,
  disabled,
  onAllocIssueUpdate,
}: CauseFormProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuItems = R.values(allocIssueCauses).filter(
    cause => !allocIssue.causes.includes(cause.id),
  );
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = causeId => {
    if (typeof causeId === 'string') {
      onAllocIssueUpdate({
        updatedIssue: allocIssue,
        data: { causes: allocIssue.causes.concat(causeId) },
      });
    }
    setAnchorEl(null);
  };

  const handleDelete = deletedCauseId => {
    const newCauses = allocIssue.causes.filter(
      causeId => causeId !== deletedCauseId,
    );
    onAllocIssueUpdate({
      updatedIssue: allocIssue,
      data: { causes: newCauses },
    });
  };

  return (
    <CauseForm.Wrapper>
      <CauseForm.Controls>
        <CauseForm.Title>Causes</CauseForm.Title>
        <Button
          width={30}
          transparent
          onClick={handleClick}
          disabled={R.isEmpty(menuItems) || disabled}
        >
          <FaPlusCircle />
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {menuItems.map(item => (
            <MenuItem key={item.id} onClick={() => handleClose(item.id)}>
              {item.cause}
            </MenuItem>
          ))}
        </Menu>
      </CauseForm.Controls>
      <CauseForm.ChipsContainer>
        {allocIssue.causes.map(causeId => (
          <CauseForm.ChipWrapper key={causeId}>
            <CauseForm.Chip>
              <CauseForm.ChipLabel>
                {allocIssueCauses[causeId].cause}
              </CauseForm.ChipLabel>
              {!disabled && (
                <CauseForm.ChipDeleteButton
                  onClick={() => handleDelete(causeId)}
                >
                  <CloseIcon />
                </CauseForm.ChipDeleteButton>
              )}
            </CauseForm.Chip>
          </CauseForm.ChipWrapper>
        ))}
      </CauseForm.ChipsContainer>
    </CauseForm.Wrapper>
  );
};

CauseForm.Wrapper = styled.div`
  margin-top: 10px;
`;

CauseForm.Controls = styled.div`
  display: flex;
  align-items: center;
`;

CauseForm.Title = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin: 0;
`;

CauseForm.ChipWrapper = styled.div`
  margin-bottom: 5px;
  width: 100%;
`;

CauseForm.ChipsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
`;

CauseForm.Chip = styled.div`
  display: flex;
  height: 34px;
  width: 100%;
  border: 1px solid #c1c1c1;
  border-radius: 3px;
  background: linear-gradient(180deg, #f3f3f3 0%, #e1e1e1 100%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  align-items: center;
  padding: 3px 3px 3px 8px;
  font-family: 'Lato', sans-serif;
`;

CauseForm.ChipLabel = styled.div``;

CauseForm.ChipDeleteButton = styled.button`
  height: 23px;
  width: 23px;
  border-radius: 50%;
  border: 1px solid transparent;
  background: transparent;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
`;

export default React.memo<CauseFormProps>(CauseForm);
