import { format } from 'd3-format';
import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';

import InputCheckbox from 'components/InputCheckbox';
import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';
import { OIL, GAS, WATER } from 'modules/phase/models/phase';

interface PhasesFormProps {
  allocIssue: AllocIssue;
  disabled: boolean;
  onAllocIssueUpdate: (data: {
    updatedIssue: AllocIssue;
    data: Record<string, any>;
  }) => void;
}

const varianceFormat = format('+,.3r');

const PhasesForm = ({
  allocIssue,
  disabled,
  onAllocIssueUpdate,
}: PhasesFormProps) => {
  const allocIssuePhases = R.indexBy(R.prop('phaseName'), allocIssue.phases);
  const allocIssueVarianceByPhase = R.map(
    phaseData => phaseData.actualVolume - phaseData.capacityVolume,
    allocIssuePhases,
  );

  const handleChange = e => {
    const phase = e.target.name;
    const checked = e.target.checked;
    const newIssuePhases = allocIssue.phases.map(issuePhase => {
      if (issuePhase.phaseName === phase) {
        return R.assoc('checked', checked, issuePhase);
      }

      return issuePhase;
    });

    onAllocIssueUpdate({
      updatedIssue: allocIssue,
      data: {
        phases: newIssuePhases,
      },
    });
  };

  return (
    <PhasesForm.FormControl component="fieldset">
      <PhasesForm.FormGroup>
        <InputCheckbox
          checked={allocIssuePhases[OIL].checked}
          disabled={disabled}
          onChange={handleChange}
          value="Oil"
          name="Oil"
          label={`Oil ${
            allocIssueVarianceByPhase[OIL]
              ? ': ' + varianceFormat(allocIssueVarianceByPhase[OIL]) + ' BBL'
              : ''
          }`}
        />
        <InputCheckbox
          checked={allocIssuePhases[GAS].checked}
          disabled={disabled}
          onChange={handleChange}
          value="Gas"
          name="Gas"
          label={`Gas ${
            allocIssueVarianceByPhase[GAS]
              ? ': ' + varianceFormat(allocIssueVarianceByPhase[GAS]) + ' MCF'
              : ''
          }`}
        />
        <InputCheckbox
          checked={allocIssuePhases[WATER].checked}
          disabled={disabled}
          onChange={handleChange}
          value="Water"
          name="Water"
          label={`Water ${
            allocIssueVarianceByPhase[WATER]
              ? ': ' + varianceFormat(allocIssueVarianceByPhase[WATER]) + ' BBL'
              : ''
          }`}
        />
      </PhasesForm.FormGroup>
    </PhasesForm.FormControl>
  );
};

PhasesForm.FormGroup = styled(FormGroup)`
  width: 100%;
`;

PhasesForm.FormControl = styled(FormControl)`
  width: 100%;
`;

export default React.memo<PhasesFormProps>(PhasesForm);
