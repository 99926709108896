import * as React from 'react';
import styled from 'styled-components';

const USER_AGENT_REG_EXP = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i;
const PLATFORM_REG_EXP = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i;

type CheckDeviceProps = {
  children: React.ReactNode;
};

const CheckDevice = ({ children }: CheckDeviceProps) => {
  const ua = navigator.userAgent;
  const platform = navigator.platform;
  const isMobile =
    USER_AGENT_REG_EXP.test(ua) || PLATFORM_REG_EXP.test(platform);
  const isIE = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;

  return (
    <>
      {isMobile || isIE ? (
        <CheckDevice.Container>
          <CheckDevice.Message>
            {isMobile &&
              'Wise Rock is not currently available on mobile browsers. Please log in through a computer'}
            {isIE &&
              "We don't support old versions of Internet Explorer.\nPlease, use latest version of Google Chrome, Microsoft Edge or Firefox"}
          </CheckDevice.Message>
        </CheckDevice.Container>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

CheckDevice.Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e8e8e8;
  padding: 20%;
`;

CheckDevice.Message = styled.p`
  color: #35995b;
  text-align: center;
  font-size: 1.5em;
`;

export default CheckDevice;
