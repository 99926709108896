import { Action, Middleware } from 'redux';

const warn = error => {
  console.warn(error.message || error); // eslint-disable-line no-console
  throw error; // To let the caller handle the rejection
};

const promiseMiddleware: Middleware =
  () => (next: any) => (action: Promise<Action>) => {
    if (typeof action.then === 'function') {
      Promise.resolve(action).then(next, warn);
    } else {
      next(action);
    }
  };

export default promiseMiddleware;
