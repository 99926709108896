import React from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import TabsContext from './TabsContext';

interface Props {
  children?: React.ReactNode;
  disabled?: boolean;
  defaultActive?: boolean;
  id?: string;
  tabName: string;
}

export const Tab = ({
  children,
  defaultActive = false,
  disabled = false,
  id,
  tabName,
}: Props) => {
  const tabId = React.useRef(id ?? uuid().toString());
  const context = React.useContext(TabsContext);

  if (!context) throw new Error('Tab must have TabsProvider as parent!');

  React.useEffect(() => {
    if (!defaultActive || context.activeTab !== null) return;

    context.open(tabId.current);
  }, [context.activeTab, context.open, tabId]);

  React.useEffect(() => {
    context.setTabInfo(tabId.current, {
      disabled,
      isActive: context.activeTab === tabId.current,
      tabName,
    });
  }, [context.activeTab, tabId, disabled, tabName, context.setTabInfo]);

  return tabId.current === context.activeTab ? (
    <Tab.Container>{children}</Tab.Container>
  ) : null;
};

Tab.Container = styled.div`
  width: 100%;
  height: 100%;
`;
