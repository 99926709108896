import * as R from 'ramda';

import { countBoeValue } from 'modules/production/utils';

import type { VarianceDrilldownTableItem } from 'modules/drilldownTable/models/drilldownTable';
import sum from './sum';

const createGroupedTable = (
  table: VarianceDrilldownTableItem[],
  subject = 'well',
  compareOption = 'Actual',
) => {
  if (!subject) return [];
  const groupedTable = R.groupBy(R.prop(subject), table);

  return R.keys(groupedTable).reduce((acc, subjectItemName) => {
    const itemWithNotExistingCapacities = groupedTable[subjectItemName].map(
      row => {
        return {
          ...row,
          oilCapacity: row.oilCapacity ?? row.oilActual,
          gasCapacity: row.gasCapacity ?? row.gasActual,
          waterCapacity: row.waterCapacity ?? row.waterActual,
        };
      },
    );
    const oilVariance = sum(itemWithNotExistingCapacities, 'oilVariance');
    const gasVariance = sum(itemWithNotExistingCapacities, 'gasVariance');
    const waterVariance = sum(itemWithNotExistingCapacities, 'waterVariance');
    const boeVariance = countBoeValue(oilVariance, gasVariance);

    const oilCapacity = sum(itemWithNotExistingCapacities, 'oilCapacity');
    const gasCapacity = sum(itemWithNotExistingCapacities, 'gasCapacity');
    const waterCapacity = sum(itemWithNotExistingCapacities, 'waterCapacity');
    const boeCapacity = countBoeValue(oilCapacity, gasCapacity);

    const oilSource = sum(itemWithNotExistingCapacities, `oil${compareOption}`);
    const gasSource = sum(itemWithNotExistingCapacities, `gas${compareOption}`);
    const waterSource = sum(
      itemWithNotExistingCapacities,
      `water${compareOption}`,
    );
    const boeSource = countBoeValue(oilSource, gasSource);
    const displaySubjectItemName =
      subjectItemName === 'null' ? '' : subjectItemName;
    const tableRowData = {
      [subject || 'well']: subject === 'all' ? 'all' : displaySubjectItemName,
      oilVariance,
      gasVariance,
      waterVariance,
      boeVariance,
      oilCapacity,
      gasCapacity,
      waterCapacity,
      boeCapacity,
      [`oil${compareOption}`]: oilSource,
      [`gas${compareOption}`]: gasSource,
      [`water${compareOption}`]: waterSource,
      [`boe${compareOption}`]: boeSource,
    };

    acc.push(tableRowData);
    return acc;
  }, []);
};

export default createGroupedTable;
