import * as React from 'react';

const Pattern = () => (
  <svg
    width="100%"
    height="100%"
    style={{ position: 'absolute', zIndex: 0, left: '0', top: '0' }}
  >
    <defs>
      <pattern
        id="diagonalHatch_pattern"
        patternUnits="userSpaceOnUse"
        width="7"
        height="7"
      >
        <path
          d="M-1,1 l2,-2 M0,7 l7,-7 M6,8 l2,-2"
          stroke="#f7b4b4"
          strokeWidth="1"
        />
      </pattern>
    </defs>
    <rect
      width="100%"
      height="100%"
      x="0"
      y="0"
      fill="url(#diagonalHatch_pattern)"
    />
  </svg>
);

export default Pattern;
