import React from 'react';
import ModalWindow from '.';

interface DeleteModalWindowProps {
  close?: () => void;
  title: string;
  onCancel?: () => void;
  onDelete?: () => void;
}

export const DeleteModalWindow = ({
  close,
  onCancel,
  onDelete,
  title,
}: DeleteModalWindowProps) => {
  return (
    <ModalWindow
      close={close}
      title={title}
      controls={[
        {
          text: 'Cancel',
          action: () => onCancel?.call(null),
          type: 'danger',
          style: { width: '70px' },
        },
        {
          text: 'Delete',
          action: () => onDelete?.call(null),
          style: { width: '70px' },
        },
      ]}
    />
  );
};
