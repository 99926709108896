import * as R from 'ramda';

export const getGraphqlPayload = (
  action: Record<string, any>,
  defaultPayload: any = null,
): any => {
  const queryKey = R.pathOr(
    '',
    ['meta', 'previousAction', 'payload', 'key'],
    action,
  );
  return R.pathOr(defaultPayload, ['payload', 'data', queryKey], action);
};

export const getGraphqlPrevActionVariables = (
  action: Record<string, any>,
  defaultPayload: any = null,
) => {
  return R.pathOr(
    defaultPayload,
    ['meta', 'previousAction', 'payload', 'graphql', 'variables'],
    action,
  );
};

export const buildFakeGraphqlPayloadAction: (
  string,
  any,
) => Record<string, any> = (type, payload) => ({
  type,
  meta: {
    previousAction: {
      payload: { key: 'action' },
    },
  },
  payload: {
    data: { action: payload },
  },
});
