import React from 'react';

type KeycodeName = keyof typeof KEYCODES;
type KeydownCallback = (data: {
  keyName?: KeycodeName;
  keyCode: string;
  target: HTMLElement;
}) => any;

export const KEYCODES = {
  ESCAPE: ['Escape', 'Esc'],
  DELETE: ['Delete', 'Backspace'],
  LEFT: ['ArrowLeft', 'Left'],
  RIGHT: ['ArrowRight', 'Right'],
  UP: ['ArrowUp', 'Up'],
  DOWN: ['ArrowDown', 'Down'],
};

export const KEYNAMES = Object.keys(KEYCODES) as KeycodeName[];

export const useKeydown = (callback: KeydownCallback, deps: any[] = []) => {
  const handleKeyDown = React.useCallback((evt: KeyboardEvent) => {
    const target = evt.target as HTMLElement;
    const targetIsHtmlElement = target instanceof window.HTMLElement;
    if (!targetIsHtmlElement) return;

    const keyCode = evt.code;
    const keyName = KEYNAMES.find(n => KEYCODES[n].includes(keyCode));
    callback({ keyName, keyCode, target });
  }, deps);

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
};

export const useNonInputKeydown = (
  callback: KeydownCallback,
  deps: any[] = [],
) => {
  const callbackWrapper = React.useCallback(data => {
    if (data.target.tagName === 'INPUT') return;
    if (data.target.tagName === 'TEXTAREA') return;
    if (data.target.tagName === 'SELECT') return;
    callback(data);
  }, deps) as KeydownCallback;

  useKeydown(callbackWrapper, deps);
};
