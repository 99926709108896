export type Filter = {
  [filterName: string]: string[];
};

export const DEFAULT_FILTER_VALUES = {};
export const MAX_COLUMN_WIDTH = 300;
export const FILTER_LAYOUT_PANEL_WIDTH = 320;

export enum StretchType {
  all = 'all',
  none = 'none',
  last = 'last',
}

export enum DisabledSelectionType {
  current = 'current',
  area = 'area',
  header = 'header',
}

export enum MutationType {
  create = 'create',
  update = 'update',
  remove = 'remove',
}

export type CellInputData = {
  wellId: string;
  wellCustomAttributeId: string;
  prev: string | undefined;
  next: string | undefined;
};

export const ALLOWED_CELL_ACTIONS = [
  'edit',
  'Autofill.fill',
  'CopyPaste.paste',
];
