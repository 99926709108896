import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import useMouseDownOutside from 'hooks/useMouseDownOutside';

import { activateNotesPanel, resetActivePanel } from 'modules/ui/UIActions';
import { getActivePanel, getAdditionMode } from 'modules/ui/UIReducer';
import { NOTES_PANEL } from 'modules/ui/models/ui';
import useRightPanel from 'modules/ui/hooks/useRightPanel';
import EditAllocIssuePanel from 'modules/allocIssue/containers/EditAllocIssuePanel';
import EditCapacityPanel from 'modules/capacityChangeEvent/containers/EditCapacityPanel';
import ChartoPtionsPanel from 'modules/chartOptions/containers/ChartOptionsPanel';
import WellNotesPanel from 'modules/notes/containers/WellNotesPanel';
import EditRibbonEventPanel from 'modules/ribbon/containers/EditRibbonEventPanel';
import RibbonDetailsPanel from 'modules/ribbon/containers/RibbonDetailsPanel';
import EditVariancePanel from 'modules/varianceEvent/containers/EditVariancePanel';
import RequestHelpPanel from 'modules/requestHelp/containers/RequestHelpPanel';
import WellInfoPanel from 'modules/well/containers/WellInfoPanel';
import LegendPanel from './LegendPanel';
import ShortcutPanel from '../components/ShortcutPanel';

const RightPanels = () => {
  const dispatch = useDispatch();
  const rightPanel = useRightPanel();
  const activePanel = useSelector(getActivePanel);
  const additionMode = useSelector(getAdditionMode);

  const notesPanelEl = React.useRef(null);

  const handleNotesPanelClick = React.useCallback(
    e => {
      const { target } = e;
      if (
        target.tagName === 'BUTTON' ||
        target.tagName === 'svg' ||
        target.tagName === 'line'
      )
        return;
      dispatch(activateNotesPanel());
    },
    [dispatch],
  );

  const resetActiveNotesPanel = React.useCallback(
    (e: MouseEvent) => {
      const { target } = e;
      if (
        !(target instanceof Element) ||
        activePanel !== NOTES_PANEL ||
        target.id === 'note-production-line'
      )
        return;
      dispatch(resetActivePanel());
    },
    [activePanel, dispatch],
  );

  useMouseDownOutside([notesPanelEl], resetActiveNotesPanel);

  return (
    <>
      <RightPanels.Wrapper
        isShown={rightPanel.isDialogOfType('CapacityChangeEvent')}
        panelWidth={rightPanel.width}
      >
        <EditCapacityPanel />
      </RightPanels.Wrapper>

      <RightPanels.Wrapper
        isShown={rightPanel.isDialogOfType('RibbonDetails')}
        panelWidth={rightPanel.width}
      >
        <RibbonDetailsPanel />
      </RightPanels.Wrapper>

      <RightPanels.Wrapper
        isShown={rightPanel.isDialogOfType('VarianceChangeEvent')}
        panelWidth={rightPanel.width}
      >
        <EditVariancePanel />
      </RightPanels.Wrapper>

      {!additionMode.isOn && (
        <>
          <RightPanels.Wrapper
            isShown={rightPanel.isDialogOfType('RibbonEvent')}
            panelWidth={rightPanel.width}
          >
            <EditRibbonEventPanel />
          </RightPanels.Wrapper>
          <RightPanels.Wrapper
            isShown={rightPanel.isDialogOfType('AllocationIssue')}
            panelWidth={rightPanel.width}
          >
            <EditAllocIssuePanel />
          </RightPanels.Wrapper>
        </>
      )}

      <RightPanels.Wrapper
        isShown={rightPanel.isDialogOfType('ChartOptions')}
        panelWidth={rightPanel.width}
      >
        <ChartoPtionsPanel />
      </RightPanels.Wrapper>

      <RightPanels.Wrapper
        isShown={rightPanel.isDialogOfType('Shortcuts')}
        ref={notesPanelEl}
        panelWidth={rightPanel.width}
      >
        <ShortcutPanel
          onShortcutPanelClose={rightPanel.unsetDialog}
          onRequestHelpPanelOpen={() =>
            rightPanel.setDialog({ type: 'RequestHelp' })
          }
        />
      </RightPanels.Wrapper>

      <RightPanels.Wrapper
        isShown={rightPanel.isDialogOfType('RequestHelp')}
        panelWidth={rightPanel.width}
      >
        <RequestHelpPanel />
      </RightPanels.Wrapper>

      <RightPanels.Wrapper
        isShown={rightPanel.isDialogOfType('WellNotes')}
        ref={notesPanelEl}
        onClick={handleNotesPanelClick}
        panelWidth={rightPanel.width}
      >
        <WellNotesPanel />
      </RightPanels.Wrapper>

      <RightPanels.Wrapper
        isShown={rightPanel.isDialogOfType('WellInfo')}
        panelWidth={rightPanel.width}
      >
        <WellInfoPanel />
      </RightPanels.Wrapper>

      {rightPanel.isExpanded && (
        <RightPanels.Wrapper
          isShown={rightPanel.isDialogOfType('Legend')}
          panelWidth={rightPanel.width}
        >
          <LegendPanel show />
        </RightPanels.Wrapper>
      )}
    </>
  );
};

RightPanels.Wrapper = styled.div`
  position: absolute;
  transform: ${(props: Record<string, any>) =>
    props.isShown ? `translateX(0)` : `translateX(${props.panelWidth + 30}px)`};
  top: 5px;
  right: 5px;
  height: calc(100% - 10px);
  width: ${(props: Record<string, any>) => props.panelWidth}px;
  z-index: 500;
  border: 1px solid #ccc;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
`;

export default RightPanels;
