import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getAdditionMode } from 'modules/ui/UIReducer';
import { enableAdditionMode, disableAdditionMode } from 'modules/ui/UIActions';

const useAdditionMode = () => {
  const dispatch = useDispatch();

  const additionMode = useSelector(getAdditionMode);

  const additionModeOn = React.useCallback(
    (subject: string) => dispatch(enableAdditionMode(subject)),
    [dispatch],
  );

  const additionModeOff = React.useCallback(
    () => dispatch(disableAdditionMode()),
    [dispatch],
  );

  return {
    additionMode,
    additionModeOn,
    additionModeOff,
  };
};

export default useAdditionMode;
