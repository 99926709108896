import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from 'components/Button';
import { DialogContent } from '@material-ui/core';
import styled from 'styled-components';

type ModalControl = {
  action: () => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  text: string;
  type?: 'default' | 'danger' | 'safe';
};

interface ModalWindowProps {
  title?: string;
  titleGap?: number;
  children?: React.ReactNode;
  controls?: ModalControl[];
  close?: () => void;
  onClose?: () => void;
  style?: React.CSSProperties;
  backdropStyle?: React.CSSProperties;
}

const ModalWindow = ({
  controls,
  close,
  children,
  onClose,
  title,
  titleGap,
  style,
  backdropStyle,
}: ModalWindowProps) => {
  const closeModal = React.useCallback(() => {
    close?.call(null);
    onClose?.call(null);
  }, [close, onClose]);

  const getJoinedStyleFor = React.useCallback((control: ModalControl) => {
    const defaultStyles: { color?: string } = {};
    switch (control.type) {
      case 'danger':
        defaultStyles.color = '#C10000';
        break;
      case 'safe':
        defaultStyles.color = '#41AB5D';
        break;
      case 'default':
      default:
        break;
    }
    return { ...defaultStyles, ...control.style };
  }, []);

  return (
    <Dialog
      open
      style={style}
      BackdropProps={{ style: backdropStyle }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={(e, reason) => {
        if (reason === 'escapeKeyDown') closeModal();
      }}
    >
      {title ? (
        <ModalWindow.DialogTitle id="alert-dialog-title" props={{ titleGap }}>
          {title}
        </ModalWindow.DialogTitle>
      ) : null}

      {children && (
        <ModalWindow.DialogContent>{children}</ModalWindow.DialogContent>
      )}

      <DialogActions>
        {controls
          ? controls.map((control, idx) => (
              <Button
                disabled={control.disabled}
                key={idx}
                onClick={control.action}
                style={getJoinedStyleFor(control)}
              >
                {control.text}
              </Button>
            ))
          : null}
      </DialogActions>
    </Dialog>
  );
};

ModalWindow.DialogTitle = styled(DialogTitle)`
  &&&&&& {
    ${({ props }) =>
      typeof props.titleGap !== 'undefined'
        ? `padding-bottom: ${props.titleGap}px`
        : ''};
  }
`;

ModalWindow.DialogContent = styled(DialogContent)`
  &&&&&& {
    padding-top: 0;
  }
`;

export default ModalWindow;
