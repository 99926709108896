import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  archive,
  markAsRead,
  markAsUnread,
  moveToInbox,
  openConversation,
} from '../InboxConversationActions';
import { getActiveFilter } from '../InboxConversationReducer';
import { LatestEventNote, NoteFilter } from '../models';

export const useConversation = () => {
  const dispatch = useDispatch();

  const activeFilter: NoteFilter = useSelector(getActiveFilter);

  const open = React.useCallback(
    (note: LatestEventNote) => {
      dispatch(openConversation(note));
    },
    [dispatch],
  );

  const archiveConversation = React.useCallback(
    (...notes: LatestEventNote[]) => {
      if (notes.length === 0) return;
      const mapped = notes.map(note => ({
        eventId: note.eventId,
        eventType: note.eventType,
      }));

      dispatch(archive(mapped));
    },
    [dispatch],
  );

  const readConversation = React.useCallback(
    (...notes: LatestEventNote[]) => {
      const mapped = notes
        .filter(n => !n.read)
        .map(note => ({
          eventId: note.eventId,
          eventType: note.eventType,
        }));

      if (mapped.length === 0) return;

      dispatch(markAsRead(mapped));
    },
    [dispatch],
  );

  const unreadConversation = React.useCallback(
    (...notes: LatestEventNote[]) => {
      if (notes.length === 0) return;
      const mapped = notes.map(note => ({
        eventId: note.eventId,
        eventType: note.eventType,
        isWatching: note.isWatching,
      }));

      dispatch(markAsUnread(mapped));
    },
    [dispatch],
  );

  const moveConversationToInbox = React.useCallback(
    (...notes: LatestEventNote[]) => {
      if (notes.length === 0) return;
      const mapped = notes.map(note => ({
        eventId: note.eventId,
        eventType: note.eventType,
        isWatching: note.isWatching,
      }));

      dispatch(moveToInbox(mapped));
    },
    [dispatch],
  );

  const actions = React.useMemo(
    () => ({
      activeFilter,
      archiveConversation,
      readConversation,
      unreadConversation,
      moveConversationToInbox,
      openConversation: open,
    }),
    [
      activeFilter,
      archiveConversation,
      readConversation,
      unreadConversation,
      moveConversationToInbox,
      open,
    ],
  );

  return actions;
};
