import { utcDay } from 'd3-time';
import * as R from 'ramda';

import { Note } from 'modules/notes/models/notes';

export const getProductionDayAsString = (
  point: Record<string, any>,
): string => {
  return utcDay.floor(point.productionDate).toISOString();
};

export const getNotesByProductionDay = (notes: Array<Note>) => {
  const grouped = R.groupBy<Record<string, any>>(
    getProductionDayAsString,
    notes,
  );
  return Object.keys(grouped).reduce((acc, key) => {
    const sorted = grouped[key].sort(
      (a, b) => b.timeStamp.getTime() - a.timeStamp.getTime(),
    );
    acc[key] = sorted;
    return acc;
  }, {});
};

export const normalizeNote = (rawNote: Record<string, any>): Note => {
  const rawNoteTime = rawNote.timeStamp;
  const rawProductionDate = rawNote.productionDate;

  return R.compose(
    R.assoc('productionDate', utcDay.round(new Date(rawProductionDate))),
    R.assoc('timeStamp', new Date(rawNoteTime)),
    R.assoc('attachments', rawNote.attachments ? rawNote.attachments : []),
  )(rawNote);
};

export const normalizeNotes = (notes: Array<Record<string, any>>): Note[] => {
  if (notes) return notes.map(note => normalizeNote(note));

  return [];
};
