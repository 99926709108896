import * as React from 'react';

import useClickOutside from 'hooks/useClickOutside';

type CloseOnMouseClickOutsideProps = {
  children: React.ReactNode;
  exceptForClassName: string;
  closeHandler: () => void;
  event: string;
};

const CloseOnMouseClickOutside = ({
  children,
  exceptForClassName,
  closeHandler,
  event,
}: CloseOnMouseClickOutsideProps) => {
  const elements = document.querySelectorAll(`.${exceptForClassName}`);

  useClickOutside([...elements], closeHandler, event, exceptForClassName);

  return <>{children}</>;
};

export default CloseOnMouseClickOutside;
