import CircularProgress from '@material-ui/core/CircularProgress';
import * as React from 'react';
import styled from 'styled-components';

const LoaderIndicatorWithDelay = () => {
  const [showLoader, setShowLoader] = React.useState(false);
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(true);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <LoaderIndicatorWithDelay.Wrapper>
      {showLoader ? <CircularProgress color="inherit" size={21} /> : null}
    </LoaderIndicatorWithDelay.Wrapper>
  );
};

LoaderIndicatorWithDelay.Wrapper = styled.div`
  margin-left: 20px;
  padding-top: 3px;
`;

export default React.memo(LoaderIndicatorWithDelay);
