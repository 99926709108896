import * as React from 'react';
import styled from 'styled-components';

import Button from 'components/Button';
import { RedCloseIcon } from 'components/Icons';

const MAX_FILE_SIZE_IN_BYTES = 52428800;
const MAX_COEF = 0.6;
const MIN_COEF = 0.00000001;

interface AttachmentFormComponentProps {
  attachment: {
    file: any;
    id?: string;
    timestamp?: string;
  };
  handleAttachmentDelete: (id: string) => void;
}

const AttachmentFormComponent = ({
  attachment,
  handleAttachmentDelete,
}: AttachmentFormComponentProps) => {
  const { file } = attachment;
  const coef = file.size
    ? MAX_COEF - ((MAX_COEF - MIN_COEF) / MAX_FILE_SIZE_IN_BYTES) * file.size
    : 0;

  const [completed, setCompleted] = React.useState(
    attachment.file.id ? 100 : 0,
  );
  const [step, setStep] = React.useState(0);

  React.useEffect(() => {
    const progress = () => {
      setStep(oldStep => oldStep + coef / 4);
      setCompleted(oldCompleted => {
        if (attachment.id) {
          return 100;
        }
        return (
          Math.round((Math.atan(step) / (Math.PI / 2)) * 100 * 1000) / 1000
        );
      });
    };

    const timer = setInterval(progress, 200);
    if (completed === 100) clearInterval(timer);
    return () => {
      clearInterval(timer);
    };
  }, [attachment.id, step, completed, coef]);

  return (
    <AttachmentFormComponent.Container>
      <AttachmentFormComponent.Progress
        percent={completed}
        isTransparent={completed >= 100}
      />

      <AttachmentFormComponent.FileName>
        {file.name}
      </AttachmentFormComponent.FileName>
      {completed === 100 && (
        <AttachmentFormComponent.Button
          transparent
          height={30}
          onClick={() => handleAttachmentDelete(file.id)}
        >
          <RedCloseIcon />
        </AttachmentFormComponent.Button>
      )}
    </AttachmentFormComponent.Container>
  );
};

AttachmentFormComponent.Container = styled.div`
  border: 1px solid #d5d5d5;
  border-radius: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 32px;
  overflow: hidden;
`;

AttachmentFormComponent.FileName = styled.div`
  width: calc(100% - 40px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 15px;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  position: relative;
  z-index: 5;
`;

AttachmentFormComponent.Progress = styled.div.attrs(props => ({
  style: {
    width: `${props.percent}%`,
  },
}))`
  border: none;
  background-color: ${(props: Record<string, any>) =>
    props.theme.colors.primary};
  border-radius: 15px;
  height: 100%;
  position: absolute;
  z-index: 1;
  transition: all 0.5s;
  opacity: ${(props: Record<string, any>) =>
    props.isTransparent ? '0' : '0.2'};
`;

AttachmentFormComponent.Button = styled(Button)`
  position: relative;
  z-index: 5;
`;

export default AttachmentFormComponent;
