import * as R from 'ramda';

import type { ProductionPoint } from 'modules/production/models/production';

import type { AllocIssue, AllocIssuePhase } from '../models/allocIssue';

const updateIssuePhaseVolumes = (
  issue: AllocIssue,
  capacity: {
    [phase: string]: Array<{ date: Date; capacity: number }>;
  },
  production: ProductionPoint[],
) => {
  const flatCapacityDataByPhase = R.map(
    phaseCapacityData => R.flatten(phaseCapacityData),
    capacity,
  );

  const productionForIssue = production.filter(
    productionPoint =>
      productionPoint.day <= issue.dateEnd &&
      productionPoint.day >= issue.dateStart,
  );

  const newIssuePhases = issue.phases.map<AllocIssuePhase>(issuePhase => {
    const productionKey = issuePhase.phaseName.toLowerCase();
    const actualVolume = productionForIssue.reduce(
      (acc, productionPoint) => acc + productionPoint[productionKey],
      0,
    );
    const capacityVolume = flatCapacityDataByPhase[issuePhase.phaseName].reduce(
      (acc, phaseCapacityPoint) => {
        if (
          phaseCapacityPoint.date <= issue.dateEnd &&
          phaseCapacityPoint.date >= issue.dateStart &&
          phaseCapacityPoint.date.getUTCHours() === 0
        ) {
          return acc + phaseCapacityPoint.capacity;
        }
        return acc;
      },
      0,
    );
    const newIssuePhase = R.compose(
      R.assoc('actualVolume', actualVolume),
      R.assoc('capacityVolume', capacityVolume),
    )(issuePhase);

    return newIssuePhase;
  });

  return R.assoc('phases', newIssuePhases, issue);
};

export default updateIssuePhaseVolumes;
