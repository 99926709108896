import * as React from 'react';

interface SVGSeriesTrellisInteractionProps {
  height: number;
  width: number;
  isDragging: boolean;
  maxDataPoint: number;
  onXAxisScaling: (
    e: MouseEvent,
    svgEl: { current: Element | null } | null,
  ) => void;
}

const SVGSeriesTrellisInteraction = ({
  height,
  width,
  isDragging,
  maxDataPoint,
  onXAxisScaling,
}: SVGSeriesTrellisInteractionProps) => {
  const svgEl = React.useRef<SVGSVGElement>(null);
  const [draggedMaxDataPoint, setDraggedMaxDataPoint] =
    React.useState(maxDataPoint);

  const onWheelHandler = React.useCallback(
    e => {
      onXAxisScaling(e, svgEl);
    },
    [svgEl, onXAxisScaling],
  );

  React.useEffect(() => {
    if (isDragging || maxDataPoint === draggedMaxDataPoint) return;
    setDraggedMaxDataPoint(maxDataPoint);
  }, [isDragging, maxDataPoint, draggedMaxDataPoint, setDraggedMaxDataPoint]);

  return (
    <svg
      ref={svgEl}
      height="100%"
      width={width}
      preserveAspectRatio="none"
      viewBox={`0 0 ${width} ${height}`}
      className="panInteraction series"
      onWheel={onWheelHandler}
    ></svg>
  );
};

export default SVGSeriesTrellisInteraction;
