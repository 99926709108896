import * as React from 'react';
import styled from 'styled-components';

import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';

interface StatusFormProps {
  allocIssue: AllocIssue;
  allocIssueStatuses: { [id: string]: { id: string; status: string } };
  disabled: boolean;
  onAllocIssueUpdate: ({ updatedIssue: AllocIssue, data: Object }) => void;
}

const StatusForm = ({
  allocIssueStatuses,
  allocIssue,
  disabled,
  onAllocIssueUpdate,
}: StatusFormProps) => (
  <StatusForm.Wrapper>
    <select
      id="allocIssueStatus"
      disabled={disabled}
      name="allocIssueStatus"
      value={allocIssue.statusId}
      onChange={e => {
        onAllocIssueUpdate({
          updatedIssue: allocIssue,
          data: {
            statusId: e.target.value,
          },
        });
        e.target.blur();
      }}
    >
      {Object.keys(allocIssueStatuses).map(statusId => (
        <option key={statusId} value={statusId}>
          {allocIssueStatuses[statusId].status}
        </option>
      ))}
    </select>
  </StatusForm.Wrapper>
);

StatusForm.Wrapper = styled.div`
  & select {
    width: 100%;
  }
`;

export default React.memo<StatusFormProps>(StatusForm);
