import gql from 'graphql-tag';

export const namespace = 'ALLOC_ISSUE_STATUS';
export const FETCH_ALLOC_ISSUE_STATUS = `${namespace}/FETCH_ALLOC_ISSUE_STATUS`;

const FETCH_ALLOC_ISSUE_STATUS_QUERY = gql`
  query {
    listAllocStatuses {
      id
      status
    }
  }
`;

export const fetchAllocIssueStatus = () => ({
  type: FETCH_ALLOC_ISSUE_STATUS,
  payload: {
    key: 'listAllocStatuses',
    graphql: {
      query: FETCH_ALLOC_ISSUE_STATUS_QUERY,
    },
  },
});
