import React from 'react';
import spotfireOutputStorage from '../utils/spotfireOutputStorage';

const useSpotfireOutputStorage = () => {
  const [currentData, setCurrentData] = React.useState(
    spotfireOutputStorage.get(),
  );

  React.useEffect(() => {
    return spotfireOutputStorage.subscribe(data => {
      setCurrentData(data);
    });
  }, []);

  return {
    data: currentData,
    setData: spotfireOutputStorage.set,
  };
};

export default useSpotfireOutputStorage;
