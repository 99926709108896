import * as R from 'ramda';

import { countBoeValue } from 'modules/production/utils';

import {
  COMPARE_OPTION,
  VarianceDrilldownTableItem,
} from 'modules/drilldownTable/models/drilldownTable';
import sum from './sum';

const createForecastGroupedTable = (
  table: VarianceDrilldownTableItem[],
  compareOption: string,
  subject = 'well',
) => {
  if (!subject) return [];
  const groupedTable = R.groupBy(R.prop(subject), table);

  return R.keys(groupedTable).reduce((acc, subjectItemName) => {
    const modifiedItem =
      compareOption === COMPARE_OPTION.actVsExt
        ? groupedTable[subjectItemName]
        : groupedTable[subjectItemName].map(row => {
            const copy = { ...row };
            if (copy.oilThirdCol === null) {
              copy.oilThirdCol = copy.oilActual;
              copy.oilVariance = copy.oilActual - copy.oilSecondCol;
            }
            if (row.gasThirdCol === null) {
              copy.gasThirdCol = copy.gasActual;
              copy.gasVariance = copy.gasActual - copy.gasSecondCol;
            }
            if (row.waterThirdCol === null) {
              copy.waterThirdCol = copy.waterActual;
              copy.waterVariance = copy.waterActual - copy.waterSecondCol;
            }
            return copy;
          });

    const oilVariance = sum(modifiedItem, 'oilVariance');
    const gasVariance = sum(modifiedItem, 'gasVariance');
    const waterVariance = sum(modifiedItem, 'waterVariance');
    const boeVariance = countBoeValue(oilVariance, gasVariance);

    const oilSecondCol = sum(modifiedItem, 'oilSecondCol');
    const gasSecondCol = sum(modifiedItem, 'gasSecondCol');
    const waterSecondCol = sum(modifiedItem, 'waterSecondCol');
    const boeSecondCol = countBoeValue(oilSecondCol, gasSecondCol);

    const oilThirdCol = sum(modifiedItem, `oilThirdCol`);
    const gasThirdCol = sum(modifiedItem, `gasThirdCol`);
    const waterThirdCol = sum(modifiedItem, `waterThirdCol`);
    const boeThirdCol = countBoeValue(oilThirdCol, gasThirdCol);
    const displaySubjectItemName =
      subjectItemName === 'null' ? '' : subjectItemName;

    const tableRowData = {
      [subject || 'well']: subject === 'all' ? 'all' : displaySubjectItemName,
      oilVariance,
      gasVariance,
      waterVariance,
      boeVariance,
      oilSecondCol,
      gasSecondCol,
      waterSecondCol,
      boeSecondCol,
      oilThirdCol,
      gasThirdCol,
      waterThirdCol,
      boeThirdCol,
    };

    acc.push(tableRowData);
    return acc;
  }, []);
};

export default createForecastGroupedTable;
