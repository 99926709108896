import gql from 'graphql-tag';

import { GraphqlQueryAction, Action } from 'store/models';

import {
  VarianceDrilldownTableItem,
  WellDrilldownTableItem,
} from './models/drilldownTable';

export const namespace = 'DRILLDOWN_TABLE';

export const FETCH_VARIANCE_DRILLDOWN_TABLE = `${namespace}/FETCH_VARIANCE_DRILLDOWN_TABLE`;
export const FETCH_FILTERED_VARIANCE_DRILLDOWN_TABLE = `${namespace}/FETCH_FILTERED_VARIANCE_DRILLDOWN_TABLE`;
export const FETCH_WELL_DRILLDOWN_TABLE = `${namespace}/FETCH_WELL_DRILLDOWN_TABLE`;
export const FETCH_FILTERED_WELL_DRILLDOWN_TABLE = `${namespace}/FETCH_FILTERED_WELL_DRILLDOWN_TABLE`;
export const FETCH_FORECAST_DRILLDOWN_TABLE = `${namespace}/FETCH_FORECAST_DRILLDOWN_TABLE`;
export const FETCH_FILTERED_FORECAST_DRILLDOWN_TABLE = `${namespace}/FETCH_FILTERED_FORECAST_DRILLDOWN_TABLE`;

export const SET_NUMBER_OF_ROI_DAYS = `${namespace}/SET_NUMBER_OF_ROI_DAYS`;

export const INIT_DRILLDOWN_TABLE_FETCH = `${namespace}/INIT_DRILLDOWN_TABLE_FETCH`;

export const INIT_CLEAR_DRILLDOWN_TABLE = `${namespace}/INIT_CLEAR_DRILLDOWN_TABLE`;
export const CLEAR_DRILLDOWN_TABLE = `${namespace}/CLEAR_DRILLDOWN_TABLE`;
export const POPULATE_VARIANCE_DRILLDOWN_TABLE = `${namespace}/POPULATE_VARIANCE_DRILLDOWN_TABLE`;
export const POPULATE_FILTERED_VARIANCE_DRILLDOWN_TABLE = `${namespace}/POPULATE_FILTERED_VARIANCE_DRILLDOWN_TABLE`;
export const POPULATE_WELL_DRILLDOWN_TABLE = `${namespace}/POPULATE_WELL_DRILLDOWN_TABLE`;
export const POPULATE_FILTERED_WELL_DRILLDOWN_TABLE = `${namespace}/POPULATE_FILTERED_WELL_DRILLDOWN_TABLE`;
export const POPULATE_FORECAST_DRILLDOWN_TABLE = `${namespace}/POPULATE_FORECAST_DRILLDOWN_TABLE`;

export const SET_MAX_DRILLDOWN_TABLE_DATE = `${namespace}/SET_MAX_DRILLDOWN_TABLE_DATE`;
export const SET_MIN_DRILLDOWN_TABLE_DATE = `${namespace}/SET_MIN_DRILLDOWN_TABLE_DATE`;
export const SET_DRILLDOWN_TABLE_PHASE = `${namespace}/SET_DRILLDOWN_TABLE_PHASE`;
export const SET_DRILLDOWN_TABLE_PARAMS = `${namespace}/SET_DRILLDOWN_TABLE_PARAMS`;
export const SET_DRILLDOWN_TABLE_OPTION_COLUMNS = `${namespace}/SET_DRILLDOWN_TABLE_OPTION_COLUMNS`;

export const SET_SORT_DIRECTION = `${namespace}/SET_SORT_DIRECTION`;
export const SET_SORT_CRITERIA = `${namespace}/SET_SORT_CRITERIA`;
export const SET_SORT_VAR_INDEX = `${namespace}/SET_SORT_VAR_INDEX`;
export const CONFIGURE_SORTING = `${namespace}/CONFIGURE_SORTING`;
export const RESET_SORTING = `${namespace}/RESET_SORTING`;

export const ADD_CURRENT_VARIANCE_OPTION = `${namespace}/ADD_CURRENT_VARIANCE_OPTION`;
export const ADD_SEVERAL_VARIANCE_OPTIONS = `${namespace}/ADD_SEVERAL_VARIANCE_OPTIONS`;
export const SET_CURRENT_VARIANCE_OPTION = `${namespace}/SET_CURRENT_VARIANCE_OPTION`;
export const RESET_CURRENT_VARIANCE_OPTION = `${namespace}/RESET_CURRENT_VARIANCE_OPTION`;
export const REMOVE_ONE_VARIANCE_OPTION = `${namespace}/REMOVE_ONE_VARIANCE_OPTION`;
export const SET_COMPARE_OPTION = `${namespace}/SET_COMPARE_OPTION`;

export const ADD_MARKED_ROW = `${namespace}/ADD_MARKED_ROW`;
export const ADD_SEVERAL_MARKED_ROWS = `${namespace}/ADD_SEVERAL_MARKED_ROWS`;
export const REMOVE_MARKED_ROW = `${namespace}/REMOVE_MARKED_ROW`;
export const RESET_MARKED_ROWS = `${namespace}/RESET_MARKED_ROWS`;
export const SET_MARKED_ROWS = `${namespace}/SET_MARKED_ROWS`;
export const MARK_ALL_ROWS = `${namespace}/MARK_ALL_ROWS`;

const VARIANCE_DRILLDOWN_QUERY = gql`
  query ($payload: VarianceDrilldownTableInput!) {
    listVarianceDrilldownTable(data: $payload) {
      varianceTable
    }
  }
`;

type fetchDrilldownTableAC = (payload: {
  minDate: Date;
  maxDate: Date;
}) => GraphqlQueryAction;

export const fetchVarianceDrilldownTable: fetchDrilldownTableAC = payload => ({
  type: FETCH_VARIANCE_DRILLDOWN_TABLE,
  payload: {
    key: 'listVarianceDrilldownTable',
    graphql: {
      query: VARIANCE_DRILLDOWN_QUERY,
      variables: { payload },
    },
  },
});

const DRILLDOWN_QUERY = gql`
  query ($payload: WellDrilldownTableInput!) {
    listWellDrilldownTable(data: $payload) {
      wellTable
    }
  }
`;

export const fetchWellDrilldownTable: fetchDrilldownTableAC = payload => ({
  type: FETCH_WELL_DRILLDOWN_TABLE,
  payload: {
    key: 'listWellDrilldownTable',
    graphql: {
      query: DRILLDOWN_QUERY,
      variables: { payload },
    },
  },
});

type fetchFilteredDrilldownTableAC = (payload: {
  minDate: Date;
  maxDate: Date;
  filters: { columnIndex: number; values: string[] }[];
}) => GraphqlQueryAction;

const FILTERED_VARIANCE_DRILLDOWN_QUERY = gql`
  query ($payload: FilteredDrilldownTableInput!) {
    listFilteredVarianceDrilldownTable(data: $payload) {
      varianceTable
    }
  }
`;

export const fetchFilteredVarianceDrilldownTable: fetchFilteredDrilldownTableAC =
  payload => ({
    type: FETCH_FILTERED_VARIANCE_DRILLDOWN_TABLE,
    payload: {
      key: 'listFilteredVarianceDrilldownTable',
      graphql: {
        query: FILTERED_VARIANCE_DRILLDOWN_QUERY,
        variables: { payload },
      },
    },
  });

const FILTERED_DRILLDOWN_QUERY = gql`
  query ($payload: FilteredDrilldownTableInput!) {
    listFilteredWellDrilldownTable(data: $payload) {
      wellTable
    }
  }
`;

export const fetchFilteredDrilldownTable: fetchFilteredDrilldownTableAC =
  payload => ({
    type: FETCH_FILTERED_WELL_DRILLDOWN_TABLE,
    payload: {
      key: 'listFilteredWellDrilldownTable',
      graphql: {
        query: FILTERED_DRILLDOWN_QUERY,
        variables: { payload },
      },
    },
  });

const FILTERED_FORECAST_DRILLDOWN_QUERY = gql`
  query ($payload: FilteredDrilldownTableInput!) {
    listFilteredForecastDrilldownTable(data: $payload) {
      forecastTable
    }
  }
`;

export const fetchFilteredForecastDrilldownTable: fetchFilteredDrilldownTableAC =
  payload => ({
    type: FETCH_FILTERED_FORECAST_DRILLDOWN_TABLE,
    payload: {
      key: 'listFilteredForecastDrilldownTable',
      graphql: {
        query: FILTERED_FORECAST_DRILLDOWN_QUERY,
        variables: { payload },
      },
    },
  });

const FORECAST_DRILLDOWN_QUERY = gql`
  query ($payload: VarianceDrilldownTableInput!) {
    listForecastDrilldownTable(data: $payload) {
      forecastTable
    }
  }
`;

type fetchForecastDrilldownTableAC = (payload: {
  minDate: Date;
  maxDate: Date;
}) => GraphqlQueryAction;

export const fetchForecastDrilldownTable: fetchForecastDrilldownTableAC =
  payload => ({
    type: FETCH_FORECAST_DRILLDOWN_TABLE,
    payload: {
      key: 'listForecastDrilldownTable',
      graphql: {
        query: FORECAST_DRILLDOWN_QUERY,
        variables: { payload },
      },
    },
  });

type initDrilldownTableFetchAC = (payload: {
  minDate: Date;
  maxDate: Date;
}) => Action;

export const initDrilldownTableFetch: initDrilldownTableFetchAC = payload => ({
  type: INIT_DRILLDOWN_TABLE_FETCH,
  payload,
});

type populateWellDrilldownTableAC = (
  drilldownTable: WellDrilldownTableItem[],
) => Action;

export const populateWellDrilldownTable: populateWellDrilldownTableAC =
  payload => ({
    type: POPULATE_WELL_DRILLDOWN_TABLE,
    payload,
  });
export const populateFilteredWellDrilldownTable: populateWellDrilldownTableAC =
  payload => ({
    type: POPULATE_FILTERED_WELL_DRILLDOWN_TABLE,
    payload,
  });

type populateVarianceDrilldownTableAC = (
  drilldownTable: VarianceDrilldownTableItem[],
) => Action;

export const populateVarianceDrilldownTable: populateVarianceDrilldownTableAC =
  payload => ({
    type: POPULATE_VARIANCE_DRILLDOWN_TABLE,
    payload,
  });

export const populateFilteredVarianceDrilldownTable: populateVarianceDrilldownTableAC =
  payload => ({
    type: POPULATE_FILTERED_VARIANCE_DRILLDOWN_TABLE,
    payload,
  });

export const populateForecastDrilldownTable: populateVarianceDrilldownTableAC =
  payload => ({
    type: POPULATE_FORECAST_DRILLDOWN_TABLE,
    payload,
  });

export const initClearDrilldownTable = () => ({
  type: INIT_CLEAR_DRILLDOWN_TABLE,
});

export const clearDrilldownTable = () => ({
  type: CLEAR_DRILLDOWN_TABLE,
});

type setMaxDrilldownTableDateAC = (date: Date) => Action;

export const setMaxDrilldownTableDate: setMaxDrilldownTableDateAC = date => ({
  type: SET_MAX_DRILLDOWN_TABLE_DATE,
  payload: date,
});

type setMinDrilldownTableDateAC = (date: Date) => Action;

export const setMinDrilldownTableDate: setMinDrilldownTableDateAC = date => ({
  type: SET_MIN_DRILLDOWN_TABLE_DATE,
  payload: date,
});

type setDrilldownTablePhaseAC = (phase: string) => Action;

export const setDrilldownTablePhase: setDrilldownTablePhaseAC = phase => ({
  type: SET_DRILLDOWN_TABLE_PHASE,
  payload: phase,
});

type setDrilldownTableParamsAC = (payload: {
  phase?: string;
  minDate?: Date;
  maxDate?: Date;
  grossNet?: string;
  rateVolume?: string;
  compareOption?: string;
}) => Action;

export const setDrilldownTableParams: setDrilldownTableParamsAC = payload => ({
  type: SET_DRILLDOWN_TABLE_PARAMS,
  payload,
});

type setDrilldownTableOptionColumnsAC = (payload: string) => Action;

export const setDrilldownTableOptionColumns: setDrilldownTableOptionColumnsAC =
  payload => ({
    type: SET_DRILLDOWN_TABLE_OPTION_COLUMNS,
    payload,
  });

type setSortDirectionAC = (payload: string) => Action;

export const setSortDirection: setSortDirectionAC = payload => ({
  type: SET_SORT_DIRECTION,
  payload,
});

type setSortCriteriaAC = (payload: string) => Action;

export const setSortCriteria: setSortCriteriaAC = payload => ({
  type: SET_SORT_CRITERIA,
  payload,
});

type setSortVarDirectionIndexAC = (payload: number) => Action;

export const setSortVarDirectionIndex: setSortVarDirectionIndexAC =
  payload => ({
    type: SET_SORT_VAR_INDEX,
    payload,
  });

type configureSortingAC = (payload: {
  sortDirection: string;
  sortBy: string;
}) => Action;

export const configureSorting: configureSortingAC = payload => ({
  type: CONFIGURE_SORTING,
  payload,
});

export const resetSorting = () => ({
  type: RESET_SORTING,
});

type setCurrentVarianceOptionAC = (optionId: string) => Action;

export const setCurrentVarianceOption: setCurrentVarianceOptionAC =
  optionId => ({
    type: SET_CURRENT_VARIANCE_OPTION,
    payload: { optionId },
  });

type addCurrentVarianceOptionAC = (optionId: string) => Action;

export const addCurrentVarianceOption: addCurrentVarianceOptionAC =
  optionId => ({
    type: ADD_CURRENT_VARIANCE_OPTION,
    payload: { optionId },
  });

type addSeveralVarianceOptionsAC = (optionIds: string[]) => Action;

export const addSeveralVarianceOptions: addSeveralVarianceOptionsAC =
  optionIds => ({
    type: ADD_SEVERAL_VARIANCE_OPTIONS,
    payload: { optionIds },
  });

type removeOneVarianceOptionAC = (optionId: string) => Action;

export const removeOneVarianceOption: removeOneVarianceOptionAC = optionId => ({
  type: REMOVE_ONE_VARIANCE_OPTION,
  payload: { optionId },
});

export const resetCurrentVarianceOption = () => ({
  type: RESET_CURRENT_VARIANCE_OPTION,
});

type setCompareOptionAC = (optionId: string) => Action;

export const setCompareOption: setCompareOptionAC = optionId => ({
  type: SET_COMPARE_OPTION,
  payload: { optionId },
});

type setNumberOfRoiDaysAC = (days: number) => Action;

export const setNumberOfRoiDays: setNumberOfRoiDaysAC = days => ({
  type: SET_NUMBER_OF_ROI_DAYS,
  payload: { days },
});

type addMarkedRowAC = (item: string) => Action;

export const addMarkedRow: addMarkedRowAC = payload => ({
  type: ADD_MARKED_ROW,
  payload,
});

type markAllRowsAC = () => Action;

export const markAllRows: markAllRowsAC = () => ({
  type: MARK_ALL_ROWS,
});

type addSeveralMarkedRowsAC = (items: string[]) => Action;

export const addSeveralMarkedRows: addSeveralMarkedRowsAC = payload => ({
  type: ADD_SEVERAL_MARKED_ROWS,
  payload,
});

type removeMarkedRowAC = (item: string) => Action;

export const removeMarkedRow: removeMarkedRowAC = payload => ({
  type: REMOVE_MARKED_ROW,
  payload,
});

type resetMarkedRowsAC = () => Action;

export const resetMarkedRows: resetMarkedRowsAC = () => ({
  type: RESET_MARKED_ROWS,
});

type setMarkedRowsAC = (payload: string[]) => Action;

export const setMarkedRows: setMarkedRowsAC = payload => ({
  type: SET_MARKED_ROWS,
  payload,
});
