import gql from 'graphql-tag';

import type { GraphqlQueryAction } from 'store/models';

export const namespace = 'PHASE';
export const FETCH_PHASES = `${namespace}/FETCH_PHASES`;

const FETCH_PHASES_QUERY = gql`
  query {
    listPhases {
      id
      phaseName
    }
  }
`;

type fetchPhasesAC = () => GraphqlQueryAction;

export const fetchPhases: fetchPhasesAC = () => ({
  type: FETCH_PHASES,
  payload: {
    key: 'listPhases',
    graphql: {
      query: FETCH_PHASES_QUERY,
    },
  },
});
