import * as R from 'ramda';

import { CapacityChangeEvent } from '../models/capacityChangeEvent';
import countCapacity from './countCapacity';

const countBFactor = (
  capacityEvent: CapacityChangeEvent,
  newRate: number,
  date: Date,
  phase: string,
) => {
  const phasePrefix = phase.toLowerCase();
  const declineInitDailyNom =
    capacityEvent[`${phasePrefix}DeclineInitDailyNom`];
  if (declineInitDailyNom <= 0) return capacityEvent;
  const bFactorRange = Array.from({ length: 501 }, (item, i) => i / 100);
  const capacityValues = bFactorRange.reduce((acc, bFactor) => {
    const capacity = countCapacity(
      date,
      R.assoc(`${phasePrefix}BFactor`, bFactor, capacityEvent),
      phasePrefix,
    );
    acc.push({ bFactor, capacity });

    return acc;
  }, [] as { bFactor: number; capacity: number }[]);

  const newBFactorObj = capacityValues.reduce((acc, capacityObj) => {
    const difference = Math.abs(newRate - capacityObj.capacity);
    if (R.isEmpty(acc)) return { difference, bFactor: capacityObj.bFactor };
    if (acc.difference > difference)
      return { difference, bFactor: capacityObj.bFactor };

    return acc;
  }, {} as { difference: number; bFactor: number });

  return R.assoc<number, CapacityChangeEvent>(
    `${phasePrefix}BFactor`,
    newBFactorObj.bFactor,
    capacityEvent,
  );
};

export default countBFactor;
