import Button from 'components/Button';
import * as React from 'react';
import styled from 'styled-components';

interface Props {
  title: string;
  reset: () => void;
  children?: React.ReactChild;
}

export const AppSettingWrapper = ({ children, reset, title }: Props) => {
  return (
    <div>
      <AppSettingWrapper.Title>{title}</AppSettingWrapper.Title>

      <AppSettingWrapper.Input>
        {children}

        <Button width={128} height={30} onClick={reset}>
          Reset to Default
        </Button>
      </AppSettingWrapper.Input>
    </div>
  );
};

AppSettingWrapper.Title = styled.p`
  font-size: 14px;
  margin-bottom: 8px;
`;

AppSettingWrapper.Input = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  height: 30px;

  > :first-child {
    width: 128px;
  }
`;
