import { TimeInterval, utcDay, utcMinute } from 'd3-time';
import * as R from 'ramda';
import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Y_AXIS_WIDTH } from 'modules/chart/models/chart';
import { createVarianceYScale, isInside } from 'modules/chart/utils';
import SeriesPill from 'modules/chart/components/SeriesPill';
import YAxis from 'modules/chart/components/YAxis';
import {
  ListChartOptions,
  BAR_CHART,
  NOT_EXISTING_SENSOR_SERIES_MESSAGE,
} from 'modules/chartOptions/models';
import { DataSeriesTooltipData, TooltipData } from 'modules/ui/models/ui';

import usePrevious from 'hooks/usePrevious';

import SVGSeriesTrellis from '../components/SVGSeriesTrellis';
import SVGSeriesTrellisInteraction from '../components/SVGSeriesTrellisInteraction';
import { Series, NormalizedSeriesMapping } from '../models';
import SecondaryInformationTooltip from 'modules/chart/components/SecondaryInformationTooltip';
import {
  getSensorSeriesMapping,
  getSensorSeriesAvailableDates,
} from '../SeriesReducer';
import checkSensorSeriesExists from '../../chartOptions/utils/checkSensorSeriesExists';

const getDataForSeriesTooltip = ({
  dataMap,
  chartOptions,
  groupOptions,
  seriesMapping,
  tooltipData,
  availableSensorSeriesDates,
}) => {
  const tooltipDate =
    R.pathOr(false, ['trellisTooltipData', 'day'], tooltipData) ||
    R.pathOr(false, ['dataSeriesTooltipData', 'day'], tooltipData) ||
    null;

  if (!tooltipData || !tooltipDate || R.isEmpty(seriesMapping)) return [];

  const nearestSensorSeriesDates = tooltipData.trellisTooltipData
    ? (utcMinute.every(1) as TimeInterval).range(
        utcDay.offset(tooltipDate, -1),
        utcDay.offset(tooltipDate, 1),
      )
    : (utcMinute.every(1) as TimeInterval)
        .range(
          utcDay.offset(tooltipDate, -1),
          utcMinute.offset(utcMinute.floor(tooltipDate), 1),
        )
        .reverse();

  const dataForTooltip = groupOptions.reduce((acc, seriesId) => {
    const name = seriesMapping[seriesId]?.displayName;
    const color = (chartOptions[seriesId].customColor ||
      chartOptions[seriesId].color) as string;

    let value: number | null = null;
    if (seriesId.startsWith('s')) {
      const availableDates = availableSensorSeriesDates[seriesId];

      if (
        !availableDates ||
        tooltipDate.getTime() > availableDates.max.getTime()
      )
        return acc;
      const existingDate = nearestSensorSeriesDates.find(
        d => R.path([d.toISOString(), seriesId], dataMap) !== undefined,
      );
      if (!existingDate) return acc;

      value = R.pathOr(null, [existingDate.toISOString(), seriesId], dataMap);
    } else {
      value = R.pathOr(
        null,
        [
          utcDay.floor(tooltipDate).toISOString(),
          `series${seriesMapping[seriesId].seriesIndex}`,
        ],
        dataMap,
      );
    }

    acc.push({
      name,
      color,
      value,
    });
    return acc;
  }, [] as any);

  return dataForTooltip;
};

const getFormat = (sourceName: string) =>
  sourceName.toLowerCase() === 'Watercut' ? '.0%' : ',d';

const getMaxDataPoint = (
  seriesData: Series[],
  seriesMapping: NormalizedSeriesMapping,
  options: string[],
  extremeDates: { min: Date; max: Date },
) => {
  if (!seriesData || seriesData.length === 0 || R.isEmpty(seriesMapping)) {
    return 0;
  }

  const maxGlobalValue = options.reduce((acc: number, optionId: string) => {
    const maxSeriesValue = seriesData.reduce((acc: number, series: Series) => {
      if (!seriesMapping[optionId]) return acc;
      const key = optionId.startsWith('s')
        ? optionId
        : `series${seriesMapping[optionId].seriesIndex}`;

      const value = series[key];
      const day = utcMinute.round(new Date(series.day));
      if (
        day.getTime() <= extremeDates.max.getTime() &&
        day.getTime() >= extremeDates.min.getTime() &&
        value > acc
      ) {
        acc = value;
      }

      return acc;
    }, 0);

    if (acc < maxSeriesValue) {
      acc = maxSeriesValue;
    }
    return acc;
  }, 0);

  return maxGlobalValue * 1.05 || 10;
};

interface SeriesChartProps {
  groupOptions: string[];
  chartWasDragging: boolean;
  seriesMapping: NormalizedSeriesMapping;

  currentWellId: string;
  extremeDates: { min: Date; max: Date };
  height: number;
  isAxisDragging: boolean;
  isDragging: boolean;
  isLast: boolean;
  leftOffset: number;
  onPillClick: () => void;
  onSetTooltipData: (tooltipData: DataSeriesTooltipData | null) => void;
  onXAxisScaling: (
    e: MouseEvent,
    svgEl: { current: Element | null } | null,
  ) => void;
  series: { data: Series[]; sensor: Series[]; joined: Series[] };
  tooltipData: TooltipData;
  xScale: any;
  width: number;
  chartOptions: ListChartOptions;
  today: Date;
}

const SeriesChart = ({
  groupOptions,
  chartOptions,
  chartWasDragging,
  seriesMapping,
  currentWellId,
  extremeDates,
  height,
  isAxisDragging,
  isDragging,
  isLast,
  leftOffset,
  onPillClick,
  onSetTooltipData,
  onXAxisScaling,
  tooltipData,
  series,
  xScale,
  width,
  today,
}: SeriesChartProps) => {
  const sensorSeriesMapping = useSelector(getSensorSeriesMapping);
  const groupOptionsWithActiveFilter = React.useMemo(() => {
    if (!sensorSeriesMapping) {
      return [];
    }
    return groupOptions.map(optionId => {
      if (/s/.test(optionId)) {
        return {
          ...chartOptions[optionId],
          exists: checkSensorSeriesExists(
            chartOptions[optionId],
            sensorSeriesMapping,
            currentWellId,
          ),
        };
      }
      return chartOptions[optionId];
    });
  }, [chartOptions, groupOptions, sensorSeriesMapping]);

  const containerElem = React.useRef(null);
  const rect =
    containerElem && containerElem.current
      ? //@ts-expect-error
        containerElem.current.getBoundingClientRect()
      : {};

  const getContainerAreaCoords = React.useCallback(
    elementBoundingRect => ({
      x1: elementBoundingRect.x,
      x2: elementBoundingRect.x + elementBoundingRect.width,
      y1: elementBoundingRect.y,
      y2: elementBoundingRect.y + elementBoundingRect.height,
    }),
    [],
  );
  const noteContainerCoords = getContainerAreaCoords(rect);

  const currentPointerIsInsideChart =
    tooltipData && tooltipData.dataSeriesTooltipData
      ? isInside(
          R.pick(['clientY', 'clientX'], tooltipData.dataSeriesTooltipData),
          noteContainerCoords,
        )
      : false;

  const multipliedSeries = React.useMemo(() => {
    if (R.isEmpty(seriesMapping)) return series;
    const keys = ['watercut', 'watercut_well_test'];

    const waterCutSeries = Object.values(seriesMapping).filter(
      m => m.units === '%' && keys.includes(m.sourceName),
    );

    if (waterCutSeries.length !== keys.length) return series;

    return {
      ...series,
      data: series.data.map(s => {
        waterCutSeries.forEach(
          watercut => (s[`series${watercut.seriesIndex}`] *= 100),
        );
        return s;
      }),
    };
  }, [series, seriesMapping]);

  const initialMaxDataPoint = React.useMemo(
    () =>
      getMaxDataPoint(
        multipliedSeries.joined,
        seriesMapping,
        groupOptions,
        extremeDates,
      ),
    [multipliedSeries, seriesMapping, extremeDates, groupOptions],
  );

  const getSeriesPillText = React.useCallback(
    (optionId: string) =>
      `${seriesMapping[optionId].displayName}, ${seriesMapping[optionId].units}`,
    [seriesMapping],
  );

  const [displayMinDataPoint, setDisplayMinDataPoint] = React.useState(0);
  const [displayMaxDataPoint, setDisplayMaxDataPoint] =
    React.useState(initialMaxDataPoint);
  const [yAxisLinePos, setYAxisLinePos] = React.useState<null | number>(null);

  const [isAdjusted, setIsAdjusted] = React.useState(false);
  const resetMax = React.useCallback(() => {
    setIsAdjusted(false);
    setDisplayMinDataPoint(0);
    setDisplayMaxDataPoint(initialMaxDataPoint);
  }, [setIsAdjusted, setDisplayMaxDataPoint, initialMaxDataPoint]);

  const prevMaxDataPoint = usePrevious(initialMaxDataPoint);
  const prevWellId = usePrevious(currentWellId);

  const [isYAxisDragging, setIsStartYAxisDragging] = React.useState(false);

  const yScale = React.useMemo(
    () =>
      createVarianceYScale(height, displayMinDataPoint, displayMaxDataPoint),
    [height, displayMinDataPoint, displayMaxDataPoint],
  );

  const showLine = React.useCallback(
    (rate: number) => {
      const linePosition = yScale(rate);
      setYAxisLinePos(linePosition);
    },
    [setYAxisLinePos, yScale],
  );
  const hideLine = React.useCallback(
    () => setYAxisLinePos(null),
    [setYAxisLinePos],
  );

  React.useEffect(() => {
    if (
      !R.isNil(prevMaxDataPoint) &&
      prevMaxDataPoint !== initialMaxDataPoint &&
      prevMaxDataPoint === displayMaxDataPoint
    ) {
      setDisplayMaxDataPoint(initialMaxDataPoint);
    }
  }, [
    prevMaxDataPoint,
    initialMaxDataPoint,
    displayMaxDataPoint,
    setDisplayMaxDataPoint,
  ]);

  const orderdGroupOptions = React.useMemo(() => {
    if (groupOptions.length >= 1) {
      return [...groupOptions].reverse().sort((a, b) => {
        const typeA = chartOptions[a].chartType;
        const typeB = chartOptions[b].chartType;
        if (typeA !== typeB && typeA === BAR_CHART) {
          return -1;
        }
        if (typeA !== typeB && typeB === BAR_CHART) {
          return 1;
        }
        return 0;
      });
    }
    return groupOptions;
  }, [groupOptions, chartOptions]);

  const color = React.useMemo(() => {
    if (groupOptions.length > 1 || R.isEmpty(seriesMapping)) {
      return '#000';
    }
    return (
      chartOptions[groupOptions[0]].customColor ||
      seriesMapping[groupOptions[0]].color
    );
  }, [groupOptions, chartOptions, seriesMapping]);

  React.useEffect(() => {
    if (prevWellId !== currentWellId) resetMax();
  }, [currentWellId, prevWellId, resetMax]);

  const dataMap = React.useMemo(() => {
    return multipliedSeries.joined.reduce((acc, series) => {
      acc[utcMinute(series.day).toISOString()] = series;
      return acc;
    }, {});
  }, [multipliedSeries]);

  const availableSensorSeriesDates = useSelector(getSensorSeriesAvailableDates);

  const dataForSeriesTooltip = getDataForSeriesTooltip({
    dataMap,
    chartOptions,
    groupOptions,
    seriesMapping,
    tooltipData,
    availableSensorSeriesDates,
  });

  return (
    <>
      {!R.isEmpty(seriesMapping) && (
        <SeriesChart.Container
          height={height}
          isLast={isLast}
          ref={containerElem}
        >
          <SeriesChart.MessageContainer
            isNotExist={
              groupOptionsWithActiveFilter.length !== 0 &&
              groupOptionsWithActiveFilter.some(
                elem => elem.exists || elem.exists === undefined,
              ) === false
            }
          >
            <span>{NOT_EXISTING_SENSOR_SERIES_MESSAGE}</span>
          </SeriesChart.MessageContainer>
          <SeriesChart.SVGWrapper className="trellis-chart-wrapper">
            <SVGSeriesTrellis
              chartOptions={chartOptions}
              groupOptions={orderdGroupOptions}
              height={height}
              isAxisDragging={isAxisDragging}
              onSetTooltipData={onSetTooltipData}
              series={multipliedSeries}
              seriesMapping={seriesMapping}
              showLine={displayMinDataPoint < 0}
              dataMap={dataMap}
              tooltipData={tooltipData}
              xScale={xScale}
              yAxisLinePos={yAxisLinePos}
              yScale={yScale}
            />
            {!(isAxisDragging && chartWasDragging) && (
              <SVGSeriesTrellisInteraction
                height={height}
                isDragging={isDragging}
                maxDataPoint={displayMaxDataPoint}
                onXAxisScaling={onXAxisScaling}
                width={width}
              />
            )}
          </SeriesChart.SVGWrapper>

          <SeriesChart.YAxisContainer>
            <YAxis
              format={getFormat(seriesMapping[groupOptions[0]].sourceName)}
              height={height}
              color={color}
              disableZero={displayMinDataPoint >= 0}
              maxDataPoint={displayMaxDataPoint}
              isAdjusted={isAdjusted}
              hideLine={hideLine}
              minDataPoint={displayMinDataPoint}
              onStartYAxisDragging={() => setIsStartYAxisDragging(true)}
              onStopDragging={() => setIsStartYAxisDragging(false)}
              setDisplayMaxDataPoint={setDisplayMaxDataPoint}
              setDisplayMinDataPoint={setDisplayMinDataPoint}
              isDragging={isYAxisDragging}
              resetMax={resetMax}
              showLine={showLine}
              setIsAdjusted={setIsAdjusted}
              yScale={yScale}
              varianceTrellis
            />
          </SeriesChart.YAxisContainer>
          <SeriesChart.PillsContainer>
            {groupOptionsWithActiveFilter &&
              groupOptionsWithActiveFilter.map(elem => (
                <SeriesPill
                  key={elem.id}
                  color={
                    chartOptions[elem.id].customColor ||
                    seriesMapping[elem.id].color
                  }
                  onPillClick={onPillClick}
                  text={getSeriesPillText(elem.id)}
                  isNotExist={elem.exists === false}
                />
              ))}
          </SeriesChart.PillsContainer>
          {tooltipData &&
            (tooltipData.trellisTooltipData ||
              tooltipData.dataSeriesTooltipData) &&
            !currentPointerIsInsideChart && (
              <SecondaryInformationTooltip
                containerHeight={height}
                leftOffset={leftOffset}
                tooltipData={tooltipData}
                secondaryCavTooltipData={{}}
                seriesTooltipData={dataForSeriesTooltip}
                trellisTitle={''}
                yScale={yScale}
                today={today}
              />
            )}
        </SeriesChart.Container>
      )}
    </>
  );
};

SeriesChart.Container = styled.div`
  width: 100%;
  height: ${(props: Record<string, any>) => props.height}px;
  display: flex;
  flex-direction: row;
  position: relative;
  border-bottom: ${(props: Record<string, any>) =>
    props.isLast ? 'none' : '1px solid grey'};
`;

SeriesChart.SVGWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  & > svg,
  & > div {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

SeriesChart.PillsContainer = styled.div`
  position: absolute;
  right: 6px;
  top: 5px;
  display: flex;

  > div {
    position: initial;
    margin-left: 10px;
  }
`;

SeriesChart.YAxisContainer = styled.div`
  position: absolute;
  height: 100%;
  width: ${Y_AXIS_WIDTH}px;
  margin-left: -${Y_AXIS_WIDTH}px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: 'Lato', sans-serif;
  box-shadow: 0 1px 0 0 black;
`;

SeriesChart.MessageContainer = styled.span`
  display: ${({ isNotExist }) => (isNotExist ? 'grid' : 'none')};
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  font-size: 14px;
  font-weight: 400;
`;

export default React.memo<SeriesChartProps>(SeriesChart);
