import React from 'react';
import { TextField } from '@material-ui/core';
import ModalWindow from '.';
import useShortcut, { Code } from 'hooks/useShortcut';
import { textIsEmpty, textLengthMatches } from './validators';

interface CreateModalWindowProps {
  close?: () => void;
  title: string;
  onCancel?: () => void;
  onCreate?: (value: string) => void;
}

export const CreateModalWindow = ({
  close,
  onCancel,
  onCreate,
  title,
}: CreateModalWindowProps) => {
  const [inputValue, setInputValue] = React.useState('');
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] =
    React.useState(true);

  const createAction = React.useCallback(() => {
    if (!textLengthMatches(inputValue) || textIsEmpty(inputValue)) return;
    onCreate?.call(null, inputValue);
  }, [onCreate, inputValue]);

  const onChange = React.useCallback(
    e => {
      if (!textLengthMatches(e.target.value)) return;
      setInputValue(e.target.value);
    },
    [setInputValue],
  );

  useShortcut(
    cancel => {
      cancel();
      createAction();
    },
    Code.Enter,
    [inputValue, createAction],
  );

  React.useEffect(() => {
    setIsCreateButtonDisabled(textIsEmpty(inputValue));
  }, [inputValue, setIsCreateButtonDisabled]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current?.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <ModalWindow
      close={close}
      title={title}
      controls={[
        {
          text: 'Cancel',
          action: () => onCancel?.call(null),
          type: 'danger',
          style: { width: '70px' },
        },
        {
          text: 'Save',
          action: createAction,
          style: { width: '70px' },
          disabled: isCreateButtonDisabled,
        },
      ]}
    >
      <TextField
        autoFocus
        margin="dense"
        id="name"
        type="text"
        inputRef={inputRef}
        fullWidth
        value={inputValue}
        onChange={onChange}
      />
    </ModalWindow>
  );
};
