import * as React from 'react';
import styled from 'styled-components';

import Input from 'components/Input';
import { InputConverter } from 'modules/appSettings/model';

interface Props {
  settingValue: number;
  onChange: (value: number) => void;
  format?: InputConverter<number, string>;
}

export const NumericAppSetting = ({
  format,
  onChange,
  settingValue,
}: Props) => {
  const convertToString = React.useCallback(
    (value: number) => {
      return format ? format.serialize(value) : value.toString();
    },
    [format],
  );

  const convertToNumber = React.useCallback(
    (value: string) => {
      return format ? format.deserialize(value) : parseFloat(value);
    },
    [format],
  );

  const handleInput = React.useCallback(
    event => {
      event.target.blur();
      const number = convertToNumber(event.target.value);
      setValue(number);
    },
    [convertToNumber],
  );

  const input = React.useRef<HTMLInputElement | null>(null);
  const [value, setValue] = React.useState(settingValue);

  React.useEffect(() => {
    if (!input.current) return;
    onChange(value);
    input.current.value = convertToString(value);
  }, [value]);

  React.useEffect(() => {
    setValue(settingValue);
  }, [settingValue]);

  React.useEffect(() => {
    if (!input.current) return;
    input.current.value = convertToString(value);
  }, []);

  return (
    <NumericAppSetting.Container>
      <Input
        isValid={true}
        ref={input}
        onBlur={handleInput}
        onKeyUp={e => e.key === 'Enter' && handleInput(e)}
      />
    </NumericAppSetting.Container>
  );
};

NumericAppSetting.Container = styled.div`
  & input {
    width: 100% !important;
  }

  > * {
    height: 100% !important;
  }
`;
