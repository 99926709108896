import createTransform from 'redux-persist/lib/createTransform';

export const dateTimeTransform = createTransform(null, outboundState => {
  if (
    typeof outboundState === 'string' &&
    /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(outboundState)
  ) {
    return new Date(outboundState);
  }

  return outboundState;
});
