import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getBaseLayouts,
  getCurrentFiltersLayoutData,
  getCurrentLayout,
  getUserLayouts,
} from '../FilterLayoutsReducer';
import { setCurrentFiltersLayout } from '../FilterLayoutsActions';

const useSeriesLayouts = () => {
  const dispatch = useDispatch();

  const currentFilterLayout = useSelector(getCurrentLayout);
  const baseFilterLayouts = useSelector(getBaseLayouts);
  const userFilterLayouts = useSelector(getUserLayouts);
  const filtersLayoutData = useSelector(getCurrentFiltersLayoutData);

  const setCurrentFilterLayout = React.useCallback(
    data => dispatch(setCurrentFiltersLayout(data)),
    [dispatch],
  );

  return {
    currentFilterLayout,
    baseFilterLayouts,
    userFilterLayouts,
    filtersLayoutData,
    setCurrentFilterLayout,
  };
};

export default useSeriesLayouts;
