import streamSaver from 'streamsaver';
import * as React from 'react';
import styled from 'styled-components';
import { FaTrashAlt } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons';

import Button from 'components/Button';

import { isIdNew } from 'helpers';
const MAX_FILE_SIZE_IN_BYTES = 52428800;
const MAX_COEF = 0.6;
const MIN_COEF = 0.00000001;

interface InactiveNoteAttachmentProps {
  attachment: {
    file?: any;
    name?: any;
    url?: string;
    timestamp?: string;
    id: string;
  };
  deleteExistedNoteAttachment: (fileId: string) => void;
  isEditing?: boolean;
  isUploaded?: boolean;
  onDeleteTempAttachments: (
    timestamp: any,
    noteId: string,
    fileId: string,
  ) => void;
  noteId: string;
}

const InactiveNoteAttachment = ({
  attachment,
  isUploaded,
  onDeleteTempAttachments,
  noteId,
  isEditing,
  deleteExistedNoteAttachment,
}: InactiveNoteAttachmentProps) => {
  const { name, url, file } = attachment;

  const attachedFileName = file ? file.name : name;
  const onAttachmentClick = React.useCallback(
    url => {
      const fileStream = streamSaver.createWriteStream(attachment.name);
      fetch(url).then(res => {
        const readableStream = res.body;
        if (!readableStream) return;
        const writer = fileStream.getWriter();
        const reader = readableStream.getReader();
        const pump = () =>
          reader
            .read()
            .then(res =>
              res.done ? writer.close() : writer.write(res.value).then(pump),
            );
        pump();
      });
    },
    [attachment],
  );

  const coef = file?.size
    ? MAX_COEF - ((MAX_COEF - MIN_COEF) / MAX_FILE_SIZE_IN_BYTES) * file?.size
    : 0;

  const [completed, setCompleted] = React.useState(
    !isIdNew(attachment.id) ? 100 : 0,
  );
  const [step, setStep] = React.useState(0);
  React.useEffect(() => {
    const progress = () => {
      setStep(oldStep => oldStep + coef / 4);
      setCompleted(oldCompleted => {
        if (!isIdNew(attachment.id)) {
          return 100;
        }
        return (
          Math.round((Math.atan(step) / (Math.PI / 2)) * 100 * 1000) / 1000
        );
      });
    };

    const timer = setInterval(progress, 200);
    if (completed === 100) clearInterval(timer);
    return () => {
      clearInterval(timer);
    };
  }, [attachment.id, step, completed, coef]);
  return (
    <>
      {completed === 100 && file?.type?.includes('image') ? null : (
        <InactiveNoteAttachment.Container
          onClick={() => onAttachmentClick(url)}
        >
          <InactiveNoteAttachment.Progress
            percent={completed}
            isTransparent={completed >= 100}
          />

          <InactiveNoteAttachment.Left>
            <FontAwesomeIcon
              color={'#909090'}
              flip="vertical"
              icon={faPaperclip}
            />

            <InactiveNoteAttachment.FileName>
              {attachedFileName}
            </InactiveNoteAttachment.FileName>
          </InactiveNoteAttachment.Left>

          {isEditing && (
            <InactiveNoteAttachment.Button
              transparent
              width={20}
              height={20}
              onClick={e => {
                e.stopPropagation();
                !isUploaded
                  ? onDeleteTempAttachments(
                      attachment.timestamp,
                      noteId,
                      attachment.id,
                    )
                  : deleteExistedNoteAttachment(attachment.id);
              }}
            >
              <FaTrashAlt size={16} />
            </InactiveNoteAttachment.Button>
          )}
        </InactiveNoteAttachment.Container>
      )}
    </>
  );
};

InactiveNoteAttachment.FileName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  position: relative;
  z-index: 5;
  color: #484848;
`;

InactiveNoteAttachment.Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 24px;
  font-size: 14px;
  padding: 0 12px;

  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;

    ${InactiveNoteAttachment.FileName} {
      color: #222222;
    }
  }
`;

InactiveNoteAttachment.Left = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  &&& svg {
    font-size: 16px;
  }
`;

InactiveNoteAttachment.Button = styled(Button)`
  position: relative;
  z-index: 5;
`;

InactiveNoteAttachment.Progress = styled.div.attrs(props => ({
  style: {
    width: `${props.percent}%`,
  },
}))`
  border: none;
  background-color: ${(props: Record<string, any>) =>
    props.theme.colors.primary};
  border-radius: 15px;
  height: 100%;
  position: absolute;
  z-index: 1;
  transition: all 0.5s;
  opacity: ${(props: Record<string, any>) =>
    props.isTransparent ? '0' : '0.2'};
`;

InactiveNoteAttachment.Button = styled(Button)`
  position: relative;
  padding: 0;
  z-index: 5;
`;

export default InactiveNoteAttachment;
