import { utcDay, utcYear } from 'd3-time';

const getDefaulDrilldownDates = (): { minDate: Date; maxDate: Date } => {
  return {
    minDate: utcYear.floor(new Date()),
    maxDate: utcDay.floor(new Date()),
  };
};

export default getDefaulDrilldownDates;
