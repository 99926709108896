const naturalSorting = (a: string, b: string) => {
  const chunkify = (t: string) => {
    const tz: Array<string | number> = [];
    let x = 0;
    let y = -1;
    let n: number | boolean = 0;
    let i, j;

    while ((i = (j = t.charAt(x++)).charCodeAt(0))) {
      const m = i === 46 || (i >= 48 && i <= 57);
      if (m !== n) {
        tz[++y] = '';
        n = m;
      }
      tz[y] += j;
    }
    return tz;
  };

  const aa = chunkify(a);
  const bb = chunkify(b);

  for (let x = 0; aa[x] && bb[x]; x++) {
    if (aa[x] !== bb[x]) {
      const c = Number(aa[x]),
        d = Number(bb[x]);
      if (c === aa[x] && d === bb[x]) {
        return c - d;
      }
      return aa[x] > bb[x] ? 1 : -1;
    }
  }
  return aa.length - bb.length;
};
export default naturalSorting;
