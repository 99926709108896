import * as R from 'ramda';
import { OIL, GAS, WATER } from 'modules/phase/models/phase';
import type { ProductionPoint } from 'modules/production/models/production';

const PHASE_LIST = [OIL, GAS, WATER];

const emptyPhaseAcc = {
  [OIL]: 0,
  [GAS]: 0,
  [WATER]: 0,
};

const getAverageProductionRateByPhase = (
  startDate: Date,
  endDate: Date,
  productionData: ProductionPoint[],
): { [phase: string]: number } =>
  PHASE_LIST.reduce((acc, phaseName) => {
    const productionKey = phaseName.toLowerCase();
    const filteredData = productionData.filter(
      productionPoint =>
        productionPoint.day < endDate &&
        productionPoint.day > startDate &&
        productionPoint[productionKey] > 0,
    );
    const dataLength = filteredData.length;
    // $FlowFixMe
    const sum = R.sum(R.pluck(productionKey, filteredData));

    acc[phaseName] = dataLength > 0 ? sum / filteredData.length : 0;

    return acc;
  }, R.clone(emptyPhaseAcc));

export default getAverageProductionRateByPhase;
