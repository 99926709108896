import { utcDay } from 'd3-time';

import type { CapacityChangeEvent } from 'modules/capacityChangeEvent/models/capacityChangeEvent';

export const getMaxCapacityPointDate = (
  capacityEvents: CapacityChangeEvent[],
  pointIndex: number,
): Date => {
  const isPointFirst = pointIndex === 0;
  if (isPointFirst) return utcDay.round(new Date(2099, 0, 1));

  return utcDay.offset(capacityEvents[pointIndex - 1].dayInit, -1);
};

export const getMinCapacityPointDate = (
  capacityEvents: CapacityChangeEvent[],
  pointIndex: number,
): Date => {
  const isPointLast = pointIndex === capacityEvents.length - 1;
  if (isPointLast) return new Date(0);

  return utcDay.offset(capacityEvents[pointIndex + 1].dayInit, 1);
};
