import * as React from 'react';
import styled from 'styled-components';

interface CardProps {
  children: React.ReactNode;
}

const Card = ({ children, ...props }: CardProps, ref) => (
  <Card.Container {...props} ref={ref}>
    {children}
  </Card.Container>
);

Card.Container = styled.div`
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
`;

export default React.forwardRef<Element, CardProps>(Card);
