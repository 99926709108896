import * as React from 'react';

import logoImgWithText from './assets/WR-black.svg';
import logoImgWithoutText from './assets/WR-no-text.svg';

type LogoProps = {
  width: number;
  noText?: boolean;
};

const Logo = ({ width, noText }: LogoProps) =>
  noText ? (
    <img width={width} src={logoImgWithoutText} alt="Logo" />
  ) : (
    <img width={width} src={logoImgWithText} alt="Logo" />
  );

export default Logo;
