import styled from 'styled-components';
import { Link } from 'react-router-dom';

import ButtonDropdown from 'components/ButtonDropdown';
import useFiltersLayouts from '../hooks/useFiltersLayouts';

const FiltersLayoutsDropdown = () => {
  const {
    baseFilterLayouts,
    userFilterLayouts,
    filtersLayoutData,
    currentFilterLayout,
    setCurrentFilterLayout,
  } = useFiltersLayouts();

  const hasBaseLayouts = Boolean(baseFilterLayouts.length);
  const hasUserLayouts = Boolean(userFilterLayouts.length);
  const title = !currentFilterLayout.id
    ? 'Unsaved Layout'
    : filtersLayoutData.name;

  return (
    <ButtonDropdown title={title}>
      <>
        {hasBaseLayouts && (
          <ButtonDropdown.MenuTitle>Shared Layouts:</ButtonDropdown.MenuTitle>
        )}
        {baseFilterLayouts.map(layout => (
          <ButtonDropdown.MenuItem
            isActive={layout.id === currentFilterLayout.id}
            key={layout.name}
            onClick={() => setCurrentFilterLayout(layout)}
          >
            {layout.name}
          </ButtonDropdown.MenuItem>
        ))}

        {hasUserLayouts && (
          <ButtonDropdown.MenuTitle>Your Layouts:</ButtonDropdown.MenuTitle>
        )}
        {userFilterLayouts.map(layout => (
          <ButtonDropdown.MenuItem
            isActive={layout.id === currentFilterLayout.id}
            key={layout.name}
            onClick={() => setCurrentFilterLayout(layout)}
          >
            {layout.name}
          </ButtonDropdown.MenuItem>
        ))}

        <FiltersLayoutsDropdown.Footer>
          Edit these Saved Layouts in the <br />
          <FiltersLayoutsDropdown.Link to="/filter">
            Well Attribute Layout Configuration Panel
          </FiltersLayoutsDropdown.Link>
        </FiltersLayoutsDropdown.Footer>
      </>
    </ButtonDropdown>
  );
};

FiltersLayoutsDropdown.Footer = styled.div`
  border-top: 1px solid #e7e7e7;
  padding: 10px 12px 6px;
  margin-top: 10px;
  font-size: 12px;
  line-height: 16px;
`;

FiltersLayoutsDropdown.Link = styled(Link)`
  color: #41ab5d !important;
`;

export default FiltersLayoutsDropdown;
