import * as React from 'react';
import styled from 'styled-components';

const NoSelectedItemsMessage = ({ message, ...props }: { message: string }) => (
  <NoSelectedItemsMessage.MessageWrapper {...props}>
    <NoSelectedItemsMessage.Message>{message}</NoSelectedItemsMessage.Message>
  </NoSelectedItemsMessage.MessageWrapper>
);

NoSelectedItemsMessage.MessageWrapper = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
`;

NoSelectedItemsMessage.Message = styled.span`
  font-size: 20px;
`;

export default NoSelectedItemsMessage;
