import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { changeExtremeDatesByDragging } from 'modules/production/ProductionActions';
import { getExtremeDates } from 'modules/production/ProductionReducer';
import {
  startXAxisDragging,
  startYAxisDragging,
  stopYAxisDragging,
  stopXAxisDragging,
  setWasDragging,
} from 'modules/ui/UIActions';
import {
  getIsYAxisDragging,
  getIsXAxisDragging,
  getWasDragging,
} from 'modules/ui/UIReducer';

import { Y_AXIS_WIDTH } from '../models/chart';
import useChartUIData from './useChartUIData';
import useChartScaling from './useChartScaling';

const useChartDragging = (params?: { skipDragging?: boolean }) => {
  const { skipDragging = false } = params || {};
  const dispatch = useDispatch();
  const extremeDates = useSelector(getExtremeDates);
  const isXAxisDragging = useSelector(getIsXAxisDragging);
  const isYAxisDragging = useSelector(getIsYAxisDragging);
  const chartWasDragging = useSelector(getWasDragging);

  const { xScale, panInterval } = useChartScaling();
  const { chartRect } = useChartUIData();
  const { leftOffset } = chartRect;

  const [initialDate, setInitialDate] = React.useState<Date | null>(null);

  const handleDragStop = React.useCallback(() => {
    if (isXAxisDragging) dispatch(stopXAxisDragging());
    if (isYAxisDragging) dispatch(stopYAxisDragging());
  }, [dispatch, isXAxisDragging, isYAxisDragging]);

  const handleDragStartY = React.useCallback(() => {
    dispatch(startYAxisDragging());
  }, [dispatch]);

  const getDateFromMouseEvent = React.useCallback(
    (e: React.MouseEvent) => xScale.invert(e.pageX - leftOffset - Y_AXIS_WIDTH),
    [xScale, leftOffset],
  );

  const handleDragStartX = React.useCallback(
    (e: React.MouseEvent) => {
      setInitialDate(getDateFromMouseEvent(e));
      dispatch(startXAxisDragging());
    },
    [dispatch, getDateFromMouseEvent],
  );

  const handleDragX = React.useCallback(
    (e: React.MouseEvent) => {
      if (skipDragging || !isXAxisDragging || !initialDate) return;
      const newDate = getDateFromMouseEvent(e);
      const timeOffset = panInterval.count(newDate, initialDate || new Date());

      dispatch(
        changeExtremeDatesByDragging({
          min: panInterval.offset(extremeDates.min, timeOffset),
          max: panInterval.offset(extremeDates.max, timeOffset),
        }),
      );

      dispatch(setWasDragging());
    },
    [
      isXAxisDragging,
      getDateFromMouseEvent,
      initialDate,
      extremeDates,
      skipDragging,
    ],
  );

  return {
    isXAxisDragging,
    isYAxisDragging,
    chartWasDragging,
    handleDragStartX,
    handleDragStartY,
    handleDragStop,
    handleDragX,
  };
};

export default useChartDragging;
