import { area, curveStepAfter } from 'd3-shape';
import { utcDay } from 'd3-time';
import * as React from 'react';

import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';

interface AllocIssueProps {
  allocIssue: AllocIssue;
  height: number;
  varianceData: { date: Date; variance: number }[];
  xScale: any;
  yScale: any;
}

const VarianceAllocArea = ({
  allocIssue,
  varianceData,
  xScale,
  yScale,
}: AllocIssueProps) => {
  const lastEventDayLimit = utcDay.offset(allocIssue.dateEnd, 1).getTime();
  const firstEventDayLimit = allocIssue.dateStart.getTime();

  const filteredData = varianceData
    ? varianceData.filter(
        dataPoint =>
          dataPoint.date.getTime() <= lastEventDayLimit &&
          dataPoint.date.getTime() >= firstEventDayLimit,
      )
    : [];
  const createArea: (data: any) => any = area()
    .x((d: any) => xScale(d.date || 0))
    .y1((d: any) => yScale(d.variance))
    .y0(yScale(0))
    .curve(curveStepAfter);

  return (
    <>
      <path
        fill="rgb(247, 180, 180)"
        d={createArea(filteredData)}
        stroke="rgb(247, 180, 180)"
      />
    </>
  );
};

export default VarianceAllocArea;
