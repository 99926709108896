import React from 'react';
import styled from 'styled-components';
import { ExtendedTabInfo } from 'components/Tabs/Model';
import { Menu } from './tabsHeader/Menu';
import { Drilldown } from './tabsHeader/Drilldown';
import { Inbox } from './tabsHeader/Inbox';
import { Wells } from './tabsHeader/Wells';

interface Props {
  appliedFilters: number;
  tabs: ExtendedTabInfo[];
}

export const TabsHeader = ({ appliedFilters, tabs }: Props) => {
  const tabNameToTab = React.useMemo(
    () =>
      tabs.reduce<{ [name: string]: ExtendedTabInfo }>((acc, t) => {
        acc[t.tabName] = t;
        return acc;
      }, {}),
    [tabs],
  );

  return (
    <TabsHeader.BorderContainer>
      <TabsHeader.PaddedContainer>
        <Menu tab={tabNameToTab['Menu']} />

        <Drilldown tab={tabNameToTab['Drilldown']} />

        <Inbox tab={tabNameToTab['Notes']} />

        <Wells tab={tabNameToTab['Wells']} appliedFilters={appliedFilters} />
      </TabsHeader.PaddedContainer>
    </TabsHeader.BorderContainer>
  );
};

TabsHeader.BorderContainer = styled.div`
  border-bottom: 1px solid #c1c1c1;
`;

TabsHeader.PaddedContainer = styled.div`
  display: grid;
  grid-template-columns: 72px 1fr 1fr 1fr;
  grid-template-rows: auto;

  > div:nth-child(n + 1) {
    border-left: 0;
  }

  > div:last-child {
    border-right: 0;
  }
`;
