import { Portal } from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';

interface LongNameMouseTooltipProps {
  text: string;
  maxWidth: number;
  isAlwaysShow?: boolean;
  position: { x: number; y: number };
}
const LongNameMouseTooltip = ({
  text,
  maxWidth,
  position,
  isAlwaysShow,
}: LongNameMouseTooltipProps) => {
  const canvas = React.useMemo(() => document.createElement('canvas'), []);

  const getTextWidth = React.useCallback(
    (text, font = '14px lato') => {
      const context = canvas.getContext('2d');
      //@ts-expect-error
      context.font = font;
      //@ts-expect-error
      const metrics = context.measureText(text);
      return metrics.width;
    },
    [canvas],
  );
  const baseTextWidt = getTextWidth(text);
  const tooltipWidth = getTextWidth(text, '12px lato');
  const isEnoughSpace = baseTextWidt < maxWidth;
  const windowWidth = window.innerWidth;

  const width =
    position.x + 15 + tooltipWidth > windowWidth
      ? windowWidth - tooltipWidth - 20
      : position.x;

  return !isEnoughSpace || isAlwaysShow ? (
    <Portal>
      <LongNameMouseTooltip.Container
        position={position}
        style={{
          transform: `translate(${width}px, ${position.y - 33}px)`,
        }}
      >
        {text}
      </LongNameMouseTooltip.Container>
    </Portal>
  ) : null;
};
LongNameMouseTooltip.Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: auto;
  width: auto;
  background: white;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding: 5px;
  border-radius: 3px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto auto;
  justify-content: start | end;
  gap: 0 7px;
  z-index: 700;
`;

export default LongNameMouseTooltip;
