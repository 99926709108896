import { Well } from 'modules/well/models/well';

import { VarianceDrilldownTableItem } from '../models/drilldownTable';

const populateEntryWithGroupInfo = (
  entry: VarianceDrilldownTableItem,
  keys: string[],
  wells: { [wellId: string]: Well },
): VarianceDrilldownTableItem => {
  return keys.reduce((acc, groupKey) => {
    acc[groupKey] =
      entry.wellId && wells[entry.wellId]
        ? wells[entry.wellId][groupKey]
        : null;

    return acc;
  }, entry);
};

export default populateEntryWithGroupInfo;
