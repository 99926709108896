import * as React from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  children: any;
  root?: string;
}
const Portal = ({ children, root }: PortalProps) => {
  const el = React.useRef(document.createElement('div'));
  const portalRoot = React.useRef(
    document.getElementById(root ? root : 'portal'),
  );

  React.useEffect(() => {
    const portalEl = portalRoot.current;
    const portedEl = el.current;
    if (portalEl) portalEl.appendChild(portedEl);

    return () => {
      if (portalEl) portalEl.removeChild(portedEl);
    };
  }, []);

  return ReactDOM.createPortal(children, el.current);
};

export default Portal;
