import * as R from 'ramda';
import * as React from 'react';

type useMouseDownOutsideHook = (
  elements: Array<React.RefObject<HTMLElement | null>>,
  handler: (MouseEvent) => void,
) => void;

const useMouseDownOutside: useMouseDownOutsideHook = (elements, handler) => {
  const handlerRef = React.useRef(handler);

  React.useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  const listener = React.useCallback((event: MouseEvent) => {
    const target = event.target;
    if (target instanceof HTMLElement || target instanceof Element) {
      if (
        R.isEmpty(elements) ||
        !handlerRef.current ||
        elements.some(element => {
          if (!element.current) return false;
          return element.current.contains(target);
        })
      ) {
        return;
      }

      handlerRef.current(event);
    }
  }, elements);

  React.useEffect(() => {
    window.addEventListener('mousedown', listener);
    return () => window.removeEventListener('mousedown', listener);
  }, [listener]);
};

export default useMouseDownOutside;
