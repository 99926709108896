import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as React from 'react';

import Button from 'components/Button';

const ENTER_KEYCODE = 13;

type AlertWindowProps = {
  handleClose: () => void;
  onDelete: () => void | Promise<void>;
  subject?: string;
  action?: string;
  actionButton?: string;
  text?: string;
  layoutName?: string;
};

const AlertWindow = ({
  handleClose,
  onDelete,
  subject = 'thing',
  action = 'delete',
  actionButton = 'Delete',
  layoutName,
  text,
}: AlertWindowProps) => {
  const handleKeyDown = React.useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      const { keyCode } = e;
      if (keyCode === ENTER_KEYCODE) {
        handleClose();
        onDelete();
      }
    },
    [handleClose, onDelete],
  );

  React.useEffect(() => {
    document.addEventListener('keyup', handleKeyDown);
    return () => document.removeEventListener('keyup', handleKeyDown);
  }, [handleKeyDown]);

  const dialogText = layoutName
    ? `Are you sure you want to delete the "${layoutName}" layout?`
    : text
    ? text
    : `Are you sure you want to ${action} this ${subject}?`;
  return (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onEscapeKeyDown={handleClose}
    >
      <DialogTitle id="alert-dialog-title">{dialogText}</DialogTitle>
      <DialogActions>
        <Button
          onClick={() => {
            onDelete();
            handleClose();
          }}
          variant="critical"
          width={70}
        >
          {actionButton}
        </Button>
        <Button onClick={() => handleClose()} width={70}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertWindow;
