import { utcDay } from 'd3-time';
import * as R from 'ramda';

import type { CapacityChangeEvent } from '../models/capacityChangeEvent';

const updateCapacityEventDeclineIndirectly = ({
  oldCapacityEvent,
  rate,
  date,
  phase,
}: {
  oldCapacityEvent: CapacityChangeEvent;
  rate: number;
  date: Date;
  phase: string;
}) => {
  const phasePrefix = phase.toLowerCase();
  const rateInit = oldCapacityEvent[`${phasePrefix}RateInit`];
  const declineInitDailyNom =
    oldCapacityEvent[`${phasePrefix}DeclineInitDailyNom`];
  const bFactor = oldCapacityEvent[`${phasePrefix}BFactor`];
  const { dayInit } = oldCapacityEvent;
  const daysElapsed = utcDay.count(dayInit, utcDay.ceil(date));

  if (rate > rateInit) {
    const newDecline = (rateInit - rate) / daysElapsed || declineInitDailyNom;

    return R.assoc<number, CapacityChangeEvent>(
      `${phasePrefix}DeclineInitDailyNom`,
      newDecline,
      oldCapacityEvent,
    );
  } else if (bFactor > 0) {
    const newDecline =
      (Math.pow(rateInit / rate, bFactor) - 1) / (bFactor * daysElapsed) ||
      declineInitDailyNom;

    return R.assoc<number, CapacityChangeEvent>(
      `${phasePrefix}DeclineInitDailyNom`,
      newDecline,
      oldCapacityEvent,
    );
  }
  const newDecline =
    -Math.log(rate / rateInit) / daysElapsed || declineInitDailyNom;

  return R.assoc<number, CapacityChangeEvent>(
    `${phasePrefix}DeclineInitDailyNom`,
    newDecline,
    oldCapacityEvent,
  );
};

export default updateCapacityEventDeclineIndirectly;
