const spotfireIdsArray = {
  stringify: (arr: string[]) => {
    return arr.map(id => `"${id}"`).join(',');
  },
  parse: (arrString: string): string[] => {
    try {
      return JSON.parse(arrString);
    } catch (err) {
      return [];
    }
  },
};

export default spotfireIdsArray;
