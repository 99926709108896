import * as React from 'react';
import styled from 'styled-components';

export type InputProps = {
  activeColor?: string;
  autoComplete?: string;
  width?: string;
  height?: string;
  disabled?: boolean;
  transparent?: boolean;
  style?: any;
  id: string;
  name: string;
} & { [key: string]: any };

const Input = (props: InputProps, ref) => <StyledInput {...props} ref={ref} />;

const StyledInput = styled.input`
  height: ${({ height }) => height ?? '30px'};
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  background: ${(props: any) => (props.transparent ? 'transparent' : '#fff')};
  border: ${(props: any) =>
    props.transparent
      ? 'none'
      : props.isActive
      ? '2px solid ' + props.theme.colors.activeInput
      : '1px solid #c1c1c1'};
  box-shadow: ${(props: any) =>
    props.transparent ? 'none' : '0 2px 3px rgba(0, 0, 0, 0.15)'};
  padding-left: 10px;
  color: ${(props: any) =>
    props.isValid
      ? props.isActive
        ? props.theme.colors[props.activeColor] ||
          props.theme.colors.activeInput
        : props.theme.colors.primaryText
      : props.theme.colors.criticalText};
  outline: ${(props: any) => (props.transparent ? 'none' : 'inherit')};
`;

export default React.forwardRef<Element, InputProps>(Input);
