import { currencyConverter } from '../model';

export const getLostProductionExplanation = ({
  gasPrice,
  oilPrice,
}: {
  gasPrice: number;
  oilPrice: number;
}) => {
  const gasFormatted = currencyConverter.serialize(gasPrice);
  const oilFormatted = currencyConverter.serialize(oilPrice);

  return `Assumes ${oilFormatted}/BBL and ${gasFormatted}/MCF. Change price assumptions in App Settings on the Wise Rock logo menu.`;
};
