import React from 'react';
import { EmptyInboxMessage } from './EmptyInboxMessage';

export const EmptySentMessage = () => {
  return (
    <EmptyInboxMessage.EmptyMessageContainer>
      <EmptyInboxMessage.EmptyIcon
        url="/emptySentIcon.svg"
        width="177px"
        height="120px"
      />
      <EmptyInboxMessage.EmptyTitle>
        You have not recorded any notes.
      </EmptyInboxMessage.EmptyTitle>
      <EmptyInboxMessage.EmptyMessage>
        Notes will appear in your Sent view when you write a note on a Variance
        Event, Capacity Change, or Ribbon Event.
      </EmptyInboxMessage.EmptyMessage>
    </EmptyInboxMessage.EmptyMessageContainer>
  );
};
