import { area, curveStepBefore } from 'd3-shape';
import * as R from 'ramda';
import * as React from 'react';

import { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';

import { getEventClipPathId } from '../../utils';

interface EventAreaProps {
  color: string;
  varianceData: { date: Date; capacity: number }[];
  xScale: any;
  yScale: any;
  varianceEvent: VarianceEvent;
  title: string;
}

const EventArea = ({
  xScale,
  yScale,
  varianceData,
  color,
  varianceEvent,
  title,
}: EventAreaProps) => {
  const createArea: (data: any) => any = React.useMemo(
    () =>
      area()
        .x((d: any) => xScale(d.date || 0))
        .y1((d: any) => yScale(R.pathOr(0, ['capacity'], d)))
        .y0(yScale(0))
        .curve(curveStepBefore),
    [xScale, yScale],
  );

  const createClipArea: (data: any) => any = React.useMemo(
    () =>
      area()
        .x((d: any) => xScale(d.date || 0))
        .y1((d: any) => yScale(R.pathOr(0, ['capacity'], d)))
        .y0(0)
        .curve(curveStepBefore),
    [xScale, yScale],
  );

  return (
    <>
      <path fill={color} d={createArea(varianceData)} />
      <defs>
        <clipPath id={getEventClipPathId(title, varianceEvent.id)}>
          <path d={createClipArea(varianceData)} />
        </clipPath>
      </defs>
    </>
  );
};

export default React.memo<EventAreaProps>(EventArea);
