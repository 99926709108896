import * as R from 'ramda';

const isNotEmpty = (val, key) => !R.isEmpty(val);

export const normalizeFilters = (
  filters: any,
  columnMapping: any,
  customOptions: any[],
) => {
  const filtersWithoutEmpty = R.pickBy(isNotEmpty, filters);
  const normalizedFilters = Object.entries<string[]>(
    filtersWithoutEmpty,
  ).reduce<{
    default: { columnIndex: number; values: string[] }[];
    custom: { attributeId: string; values: string[] }[];
  }>(
    (acc, [filterName, values]) => {
      if (filterName === 'id') {
        acc.custom.push({
          attributeId: '-1',
          values,
        });
      } else if (filterName.startsWith('custom')) {
        const attributeId = customOptions.find(
          o => o.filterName === filterName,
        )?.id;
        if (attributeId)
          acc.custom.push({
            attributeId: attributeId.split('custom')[1],
            values,
          });
      } else {
        const columnIndex = columnMapping[filterName].columnIndex;
        acc.default.push({
          columnIndex,
          values: filtersWithoutEmpty[filterName],
        });
      }

      return acc;
    },
    { default: [], custom: [] },
  );

  return normalizedFilters;
};
