import * as React from 'react';
import styled from 'styled-components';

import DateInput from 'components/DateInput';

import { DEFAULT_MAX, DEFAULT_MIN } from 'modules/appSettings/model';

interface Props {
  min?: Date;
  max?: Date;
  name?: string;
  id?: string;
  settingValue: Date;
  onChange: (value: Date) => void;
}

export const DateAppSetting = ({
  id,
  min,
  max,
  name,
  onChange,
  settingValue,
}: Props) => {
  return (
    <DateAppSetting.Container>
      <DateInput
        date={settingValue}
        max={max ?? DEFAULT_MAX}
        min={min ?? DEFAULT_MIN}
        name={name ?? 'settingDateInput'}
        onProcess={date => onChange(date)}
        id={id ?? 'settingDateInput'}
      />
    </DateAppSetting.Container>
  );
};

DateAppSetting.Container = styled.div`
  & input {
    width: 100% !important;
  }

  > * {
    height: 100% !important;
  }
`;
