import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import NoSelectedItemsMessage from 'components/NoSelectedItemsMessage/NoSelectedItemsMessage';
import { LayoutOption, NO_FILTERS_MESSAGE } from 'modules/filterLayouts/models';
import { Well } from 'modules/well/models/well';
import DataTable from './DataTable';

import LongNameMouseTooltip from 'components/LongNameMouseTooltip/LongNameMouseTooltip';
import FilterTableControlPanel from './FilterTableControlPanel';
import FilterTable from './FilterTable';
import {
  countFilterValues,
  filterWells,
  getInitialCountColumnWidths,
} from 'modules/filter/utils/filter';
import { measureText } from 'modules/filter/utils/text';
import useComponentSize from 'hooks/useComponentSize';
import usePrevious from 'hooks/usePrevious';
import { getDropIndicatorIndex } from 'modules/filterLayouts/FilterLayoutsReducer';
import { useSelector, useDispatch } from 'react-redux';
import { MAX_COLUMN_WIDTH } from 'modules/filter/models/filter';
import DataTableHeader from './DataTableHeader';
import { ShadowDragArea } from 'hooks/useShadowDrag';
import {
  changeOptionsColumnsOrder,
  setDropIndicatorIndex,
} from 'modules/filterLayouts/FilterLayoutsActions';
import ScrollBar from 'components/ScrollBar';
import { redirectToChartFromFilters } from 'modules/router/RouterActions';

interface TableViewProps {
  activeTable: string | null;
  addFilter: (data: { filterName: string; filterValue: string }) => void;
  addSeveralFilters: (data: {
    filterName: string;
    filterValues: string[];
  }) => void;
  clearAllFilters: () => void;
  clearFilter: (filterName: string) => void;
  draggableColumn: string;
  filteredWells: Well[];
  filters: { [key: string]: string[] };
  setFilter: ({ filterName, filterValue }) => void;
  removeFilter: (data: { filterName: string; filterValue: string }) => void;
  selectedFilterLayoutsOptionsToDisplay: LayoutOption[];
  wells: Well[];
}

const TableView = ({
  activeTable,
  addFilter,
  addSeveralFilters,
  clearAllFilters,
  clearFilter,
  draggableColumn,
  filteredWells,
  filters,
  setFilter,
  removeFilter,
  selectedFilterLayoutsOptionsToDisplay,
  wells,
}: TableViewProps) => {
  const dataTableRef = React.useRef(null);
  const filterTableScroller = React.useRef<HTMLElement | null>(null);
  const vScrollBar = React.useRef<HTMLDivElement | null>(null);
  const tablesContainerRef = React.useRef(null);
  const shouldIgnoreScroll = React.useRef(false);
  const tablesContainerSize = useComponentSize(tablesContainerRef);
  const [tooltipData, setTooltipData] = React.useState<{
    x?: number;
    y: number;
  }>({
    x: 0,
    y: 0,
  });
  const [tooltipValue, setTooltipValue] = React.useState('');
  const [isResizingTable, setIsResizingTable] = React.useState(false);
  const [isVScrollbarVisible, setIsVScrollbarVisible] = React.useState(false);
  const [isHScrollbarVisible, setIsHScrollbarVisible] = React.useState(false);
  const [filterTableHeight, setFilterTableHeight] = React.useState(0);
  const [isTableOpened, setIsTableOpened] = React.useState(true);
  const [dragData, setDragData] = React.useState({
    pivotY: 0,
    curHeight: 0,
  });
  const [scrollX, setScrollX] = React.useState(0);
  const [sortDataTableBy, setSortDataTableBy] = React.useState<{
    column: string;
    order: 'asc' | 'desc';
  } | null>(null);
  const [searchWord, setSearchWord] = React.useState('');
  const [columnWidths, setColumnWidths] = React.useState<{
    [key: string]: number;
  }>({});
  const prevSearchWord = usePrevious(searchWord);
  const dropIndicatorIndex = useSelector(getDropIndicatorIndex);
  const dispatch = useDispatch();

  const dataTableScroller = React.useMemo(
    () =>
      R.pathOr(
        null,
        ['current', 'hotInstance', 'rootElement'],
        dataTableRef,
      )?.querySelector('.wtHolder'),
    [dataTableRef.current],
  );

  const [rowsOrder, setRowsOrder] = React.useState<string[] | null>(null);

  const orderedColumnWidths = React.useMemo(
    () =>
      selectedFilterLayoutsOptionsToDisplay.map(o =>
        R.propOr(0, o.filterName, columnWidths),
      ),
    [selectedFilterLayoutsOptionsToDisplay, columnWidths],
  );

  const dataAsObject: { [id: string]: Well } = React.useMemo(
    () =>
      filteredWells.reduce((acc, n) => {
        acc[n.id] = n;
        return acc;
      }, {}),
    [filteredWells],
  );

  const resetSorting = React.useCallback(
    () => setSortDataTableBy(null),
    [setSortDataTableBy],
  );

  const dataToDisplay = React.useMemo(() => {
    if (!rowsOrder) {
      return filteredWells;
    }

    return rowsOrder.reduce<Well[]>((acc, id) => {
      if (dataAsObject[id]) acc.push(dataAsObject[id] ?? {});
      return acc;
    }, []);
  }, [rowsOrder, dataAsObject, filteredWells]);

  const countedValues: {
    [key: string]: { value: string; quantity: number }[];
  } = React.useMemo(() => {
    return selectedFilterLayoutsOptionsToDisplay.reduce((acc, opt) => {
      acc[opt.filterName] = R.isEmpty(filters[opt.filterName])
        ? countFilterValues(filteredWells, opt.filterName)
        : countFilterValues(
            filterWells(wells, R.omit([opt.filterName], filters)),
            opt.filterName,
          );
      return acc;
    }, {});
  }, [selectedFilterLayoutsOptionsToDisplay, filters, filteredWells, wells]);

  const counterColWidths = React.useMemo(
    () =>
      getInitialCountColumnWidths(wells, selectedFilterLayoutsOptionsToDisplay),
    [wells, selectedFilterLayoutsOptionsToDisplay],
  );

  const calculateColumnWidthForHiddenPercentage = React.useCallback(
    (
      columnWidth: number,
      countedValues: { value: string; quantity: number }[],
      percentage = 10,
      aproximatedValue: null | number = null,
    ) => {
      const matched = countedValues.filter(
        ({ value }) => value && measureText(value, 12) >= columnWidth,
      );

      const hiddenPercentage = Math.floor(
        (matched.length / countedValues.length) * 100,
      );

      if (columnWidth < 25) return null;
      else if (hiddenPercentage - percentage > 1) return aproximatedValue;
      else if (hiddenPercentage - percentage >= 0) return columnWidth;

      return calculateColumnWidthForHiddenPercentage(
        columnWidth - 1,
        countedValues,
        percentage,
        columnWidth,
      );
    },
    [],
  );

  React.useEffect(() => {
    if (R.isEmpty(wells)) setColumnWidths({});

    const oldOrder = Object.keys(columnWidths).sort();

    const optionsToCalculate = R.differenceWith(
      (a, b) => a.filterName === b,
      selectedFilterLayoutsOptionsToDisplay,
      oldOrder,
    );

    if (optionsToCalculate.length === 0) return;

    const headerWidths = optionsToCalculate.reduce((acc, data) => {
      const key = R.propOr('', 'filterName', data);

      acc[key] =
        measureText(R.propOr('', 'displayName', data)) +
        R.pathOr(0, [key], counterColWidths);
      return acc;
    }, {});
    const columnOrder = optionsToCalculate.map(layout =>
      R.propOr('', 'filterName', layout),
    );

    const widthMap = wells.reduce((acc, data) => {
      columnOrder.forEach(key => {
        const value = R.propOr('', key, data) ?? '';
        const width = measureText(value, 12);
        if (acc[key]) {
          const previousCount = R.isNil(acc[key].count) ? 0 : acc[key].count;
          acc[key] = {
            value: acc[key].value + (value ? width : 0),
            count: value ? 1 + previousCount : previousCount,
            maxWidth: acc[key].maxWidth < width ? width : acc[key].maxWidth,
          };
        } else {
          acc[key] = {
            value: value ? width : 0,
            count: value ? 1 : 0,
            maxWidth: value ? width : 0,
          };
        }
      });
      return acc;
    }, {});

    const finalWidth = Object.keys(widthMap).reduce<typeof columnWidths>(
      (acc, key) => {
        const headerWidth = Math.min(
          R.propOr(0, [key], headerWidths) + 28 + 18,
          MAX_COLUMN_WIDTH,
        );
        const counterColWidth = R.pathOr(0, [key], counterColWidths) + 10;
        const valueWidth = R.pathOr(0, [key, 'maxWidth'], widthMap);
        const rowWidth = valueWidth + counterColWidth + 24;

        const columnWidth = Math.max(rowWidth, headerWidth);

        if (columnWidth >= MAX_COLUMN_WIDTH) {
          acc[key] = MAX_COLUMN_WIDTH;
          return acc;
        } else if (columnWidth < 150) {
          acc[key] = columnWidth;
          return acc;
        }

        const countedValues = countFilterValues(wells, key);

        const adjustedWidth = calculateColumnWidthForHiddenPercentage(
          valueWidth,
          countedValues,
          10,
        );

        acc[key] = adjustedWidth
          ? adjustedWidth + counterColWidth + 15
          : columnWidth;
        return acc;
      },
      {},
    );

    setColumnWidths(prev => ({
      ...prev,
      ...finalWidth,
    }));
  }, [wells, selectedFilterLayoutsOptionsToDisplay]);

  const onMouseOut = React.useCallback(() => {
    setTooltipData({
      x: 0,
      y: 0,
    });
    setTooltipValue('');
  }, [setTooltipData, setTooltipValue]);

  const onMouseOver = React.useCallback(
    (event, coords, td) => {
      const rect = event.target.getBoundingClientRect();
      if (rect.width < event.target.scrollWidth) {
        setTooltipData({ y: event.y });
        setTooltipValue(td.textContent);
      }
    },
    [setTooltipData, setTooltipValue],
  );

  const onMouseMovehandler = React.useCallback(
    e => {
      setTooltipData({ x: e.pageX, y: e.pageY });
    },
    [setTooltipData],
  );

  const tableWidth = React.useMemo(
    () => orderedColumnWidths.reduce((acc, n) => acc + n, 0),
    [orderedColumnWidths],
  );

  const dropIndicatorOffsetX = React.useMemo(() => {
    if (
      dropIndicatorIndex === null ||
      selectedFilterLayoutsOptionsToDisplay.find(
        o => o.id === draggableColumn,
      ) === undefined
    )
      return -1;
    return orderedColumnWidths.reduce((acc, n, idx) => {
      if (idx >= dropIndicatorIndex) return acc;
      return acc + n;
    }, -scrollX);
  }, [
    orderedColumnWidths,
    dropIndicatorIndex,
    scrollX,
    selectedFilterLayoutsOptionsToDisplay,
  ]);

  const resizeTable = React.useCallback(
    (e: MouseEvent) => {
      const { pivotY, curHeight } = dragData;
      const toAdd = e.clientY - pivotY;
      const height = curHeight + toAdd;

      if (!tablesContainerSize.height) return;

      const finalHeight =
        height < 0
          ? 0
          : tablesContainerSize.height - height <= 12
          ? tablesContainerSize.height - 12
          : height;

      setFilterTableHeight(finalHeight);
      setIsTableOpened(finalHeight > 0);
    },
    [dragData, tablesContainerSize, setFilterTableHeight, dataTableRef],
  );

  const removeDragListeners = React.useCallback(() => {
    document.removeEventListener('mousemove', resizeTable);
    document.removeEventListener('mouseup', removeDragListeners);
    setIsResizingTable(false);
  }, [resizeTable, setIsResizingTable]);

  React.useEffect(() => {
    if (!isResizingTable) return;

    document.addEventListener('mousemove', resizeTable);
    document.addEventListener('mouseup', removeDragListeners);
  }, [isResizingTable, removeDragListeners]);

  const startDraggingHandle = React.useCallback(
    (e: MouseEvent) => {
      setIsResizingTable(true);
      setDragData({ pivotY: e.clientY, curHeight: filterTableHeight });
    },
    [filterTableHeight, setDragData, setIsResizingTable],
  );

  const toggleFilterTable = React.useCallback(() => {
    if (!tablesContainerSize.height) return;
    setFilterTableHeight(prev => {
      setIsTableOpened(prev <= 0);
      return prev > 0 ? 0 : (tablesContainerSize.height - 12) / 2;
    });
  }, [setFilterTableHeight, setIsTableOpened, tablesContainerSize]);

  const scrollTableTo = React.useCallback(
    ({ scroller, x, y }: { scroller: HTMLElement; x?: number; y?: number }) => {
      const tempShouldIgnoreScroll = shouldIgnoreScroll.current;
      shouldIgnoreScroll.current = false;
      if (tempShouldIgnoreScroll) return;
      shouldIgnoreScroll.current = true;

      const newScrollX = x ?? scroller.scrollLeft;
      const newScrollY = y ?? scroller.scrollTop;
      scroller.scrollTo(newScrollX, newScrollY);
    },
    [],
  );

  const reorderColumns = React.useCallback(
    (dragIndex, dropIndex) => {
      if (dragIndex === dropIndex) return;
      dispatch(changeOptionsColumnsOrder({ dragIndex, dropIndex }));
    },
    [dispatch],
  );

  const onDropIndexChange = React.useCallback(
    ({ indicatorIndex, shouldDisplayIndicator }) => {
      if (dropIndicatorIndex === indicatorIndex) return;
      dispatch(
        setDropIndicatorIndex({
          index: shouldDisplayIndicator ? indicatorIndex : null,
        }),
      );
    },
    [dispatch, dropIndicatorIndex],
  );

  const onSortDataTable = React.useCallback(
    data => {
      setRowsOrder(null);
      setSortDataTableBy(data);
    },
    [setSortDataTableBy, setRowsOrder],
  );

  const redirectToChart = React.useCallback(
    () => dispatch(redirectToChartFromFilters()),
    [dispatch],
  );

  const clearTableSelection = React.useCallback(
    () =>
      R.pathOr(null, ['current', 'hotInstance'], dataTableRef)?.deselectCell(),
    [dataTableRef],
  );

  React.useEffect(() => {
    setRowsOrder(null);
  }, [dataAsObject, setRowsOrder]);

  React.useEffect(() => {
    if (
      !tablesContainerSize.height ||
      tablesContainerSize.height - filterTableHeight <= 50
    )
      return;
    R.pathOr(null, ['current', 'hotInstance'], dataTableRef)?.render();
  }, [isTableOpened, tablesContainerSize, filterTableHeight, dataTableRef]);

  React.useEffect(() => {
    if (!tablesContainerSize.height) return;
    setFilterTableHeight(
      isTableOpened ? (tablesContainerSize.height - 12) / 2 : 0,
    );
  }, [tablesContainerSize, setFilterTableHeight]);

  React.useEffect(() => {
    if (
      isTableOpened ||
      searchWord.length === 0 ||
      prevSearchWord === searchWord
    )
      return;
    toggleFilterTable();
  }, [isTableOpened, searchWord, toggleFilterTable]);

  React.useEffect(() => {
    const createHandlerX = scroller => e => {
      if (!scroller) return;
      scrollTableTo({
        scroller,
        x: e.target.scrollLeft,
      });
      setScrollX(e.target.scrollLeft);
    };

    const dataTableHandlerX = createHandlerX(filterTableScroller.current);
    const composedDataTableHandler = e => {
      dataTableHandlerX(e);

      if (!vScrollBar.current) return;
      scrollTableTo({ scroller: vScrollBar.current, y: e.target.scrollTop });
    };

    const filterTableHandlerX = createHandlerX(dataTableScroller);

    dataTableScroller?.addEventListener('scroll', composedDataTableHandler);
    filterTableScroller.current?.addEventListener(
      'scroll',
      filterTableHandlerX,
    );

    return () => {
      dataTableScroller?.removeEventListener(
        'scroll',
        composedDataTableHandler,
      );
      filterTableScroller.current?.removeEventListener(
        'scroll',
        filterTableHandlerX,
      );
    };
  }, [dataTableScroller, filterTableScroller.current, scrollTableTo]);

  React.useEffect(() => {
    if (!isTableOpened || !dataTableScroller || !filterTableScroller.current)
      return;

    filterTableScroller.current.scrollLeft = dataTableScroller.scrollLeft;
  }, [isTableOpened, dataTableScroller, filterTableScroller.current]);

  return (
    <>
      {!R.isEmpty(selectedFilterLayoutsOptionsToDisplay) ? (
        <TableView.Container
          isDragging={isResizingTable}
          isTableVisible={isTableOpened}
          onMouseMove={onMouseMovehandler}
        >
          <TableView.FilterTableControlPanelContainer tableWidth={tableWidth}>
            <FilterTableControlPanel
              clearAllFilters={clearAllFilters}
              isTableOpened={isTableOpened}
              onSearchWordChange={setSearchWord}
              redirectToChart={redirectToChart}
              searchWord={searchWord}
              toggleFilterTable={toggleFilterTable}
              wellsFound={filteredWells.length}
              wellsTotal={wells.length}
              isFiltering={!R.isEmpty(R.flatten(R.values(filters)))}
            />
          </TableView.FilterTableControlPanelContainer>

          <TableView.TablesContainer
            isTableOpened={isTableOpened}
            ref={tablesContainerRef}
          >
            <TableView.FilterTableScrollContainer
              ref={filterTableScroller}
              filterTableWidth={tableWidth}
              isVisible={isTableOpened}
              tableHeight={filterTableHeight}
              onClick={clearTableSelection}
            >
              <TableView.FilterTableContainer
                isVScrollBarVisible={isVScrollbarVisible}
                tableHeight={filterTableHeight - 8}
                colWidths={orderedColumnWidths.map(w => `${w}px`).join(' ')}
                tableWidth={tableWidth}
              >
                <ShadowDragArea
                  backlightStyle={{ display: 'none' }}
                  indicatorStyle={{ display: 'none' }}
                  scrollX={scrollX}
                  onDropIndexChange={onDropIndexChange}
                  onScrollContainer={movementX => {
                    if (!dataTableScroller) return;
                    dataTableScroller.scrollLeft = scrollX + movementX;
                  }}
                >
                  {selectedFilterLayoutsOptionsToDisplay.map((o, idx) => (
                    <FilterTable
                      addFilter={addFilter}
                      searchWord={searchWord}
                      key={o.id}
                      draggableColumn={draggableColumn}
                      addSeveralFilters={addSeveralFilters}
                      clearFilter={clearFilter}
                      countedValues={countedValues[o.filterName]}
                      filter={o}
                      filters={filters[o.filterName]}
                      firstColDataKey={'value'}
                      isActive={activeTable === o.filterName}
                      order={idx}
                      onMouseEnterRow={setTooltipValue}
                      onMouseLeaveRow={onMouseOut}
                      reorderColumns={reorderColumns}
                      removeFilter={removeFilter}
                      secondColDataKey={'quantity'}
                      setFilter={setFilter}
                      counterColWidth={counterColWidths[o.filterName]}
                    />
                  ))}
                </ShadowDragArea>
              </TableView.FilterTableContainer>
            </TableView.FilterTableScrollContainer>

            <TableView.DragHandle
              isDragging={isResizingTable}
              onMouseDown={e => startDraggingHandle(e)}
              tableWidth={tableWidth}
            />

            <TableView.DataTableContainer
              isDragging={isResizingTable}
              maxWidth={tableWidth}
              headerHeight={30}
              scrollbarHeight={isHScrollbarVisible ? 12 : 0}
              isVScrollbarVisible={isVScrollbarVisible}
            >
              <ScrollBar
                dock="top-right"
                scrollSize={dataToDisplay.length * 23}
                scrollable={dataTableScroller}
                onAppear={() => setIsVScrollbarVisible(true)}
                onDisappear={() => setIsVScrollbarVisible(false)}
              />

              <ScrollBar
                dock="top-left"
                horizontal
                scrollSize={tableWidth + 1}
                clientSize={`calc(100% - ${
                  isVScrollbarVisible ? '12px' : '0px'
                })`}
                scrollable={dataTableScroller}
                onAppear={() => setIsHScrollbarVisible(true)}
                onDisappear={() => setIsHScrollbarVisible(false)}
              />

              <DataTableHeader
                colWidths={orderedColumnWidths}
                options={selectedFilterLayoutsOptionsToDisplay}
                draggableColumn={draggableColumn}
                height={30}
                dataTableRef={dataTableRef}
                scrollX={scrollX}
                isFilterTableOpened={isTableOpened}
                reorderColumns={reorderColumns}
                scrollTable={x => {
                  if (!dataTableScroller) return;
                  dataTableScroller.scrollLeft = x;
                }}
                sortDataTableBy={sortDataTableBy}
                onSortDataTable={onSortDataTable}
                onDropIndexChange={onDropIndexChange}
              />

              <DataTable
                colWidths={orderedColumnWidths}
                countedValues={countedValues}
                dataToDisplay={dataToDisplay}
                filters={filters}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                ref={dataTableRef}
                resetSorting={resetSorting}
                setRowsOrder={setRowsOrder}
                sortBy={sortDataTableBy}
                selectedFilterLayoutsOptionsToDisplay={
                  selectedFilterLayoutsOptionsToDisplay
                }
              />
            </TableView.DataTableContainer>

            <TableView.Indicator
              isIndicatorVisible={dropIndicatorOffsetX >= 0}
              dropIndicatorOffsetX={dropIndicatorOffsetX}
            />
          </TableView.TablesContainer>

          {tooltipValue && tooltipData.x ? (
            <LongNameMouseTooltip
              text={tooltipValue}
              position={{ x: tooltipData.x, y: tooltipData.y }}
              maxWidth={0}
              isAlwaysShow
            />
          ) : null}
        </TableView.Container>
      ) : (
        <NoSelectedItemsMessage message={NO_FILTERS_MESSAGE} />
      )}
    </>
  );
};

TableView.Container = styled.div`
  cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : '')};
  display: grid;
  grid-template-rows: min-content minmax(0, 1fr);
`;

TableView.Indicator = styled.div`
  display: ${({ isIndicatorVisible }) =>
    isIndicatorVisible ? 'block' : 'none'};
  position: absolute;
  width: 4px;
  background: #000;
  height: 100%;
  top: 0;
  left: ${({ dropIndicatorOffsetX }) => dropIndicatorOffsetX}px;
`;

TableView.DragHandle = styled.div`
  position: relative;
  background: linear-gradient(180deg, #f3f3f3 0%, #e1e1e1 100%);
  border: 1px solid #c1c1c1;
  cursor: ${({ isDragging }) => (isDragging ? 'inherit' : 'grab')};

  ::before {
    content: '';
    display: block;
    width: 40px;
    height: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    border: 1px solid;
    border-left: 0;
    border-right: 0;
    transform: translate(-50%, -50%);
  }
`;

TableView.FilterTableScrollContainer = styled.div`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  background: #fdfdfd;
  border: 1px solid #c1c1c1;
  border-bottom: 0;
  overflow-y: hidden;
  overflow-x: auto;
  height: ${({ tableHeight }) => tableHeight}px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .ReactVirtualized__Table__Grid {
    background: #fff;
  }
`;

TableView.FilterTableContainer = styled.div`
  height: 100%;
  padding: 4px 0 4px 2px;
  overflow: hidden;
  width: ${({ tableWidth, isVScrollBarVisible }) =>
    tableWidth + (isVScrollBarVisible ? 12 : 0)}px;

  > :first-child {
    display: grid;
    grid-template-columns: ${({ colWidths }) => colWidths};
    grid-template-rows: 1fr;
  }
`;

TableView.TablesContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: ${({ isTableOpened }) => (isTableOpened ? 'auto' : '')} 12px minmax(
      0,
      1fr
    );
`;

TableView.FilterTableControlPanelContainer = styled.div`
  border: 1px solid #c1c1c1;
  border-bottom: 0;
`;

TableView.DataTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  padding-top: ${({ headerHeight, scrollbarHeight }) =>
    headerHeight + scrollbarHeight}px;
  padding-right: ${({ isVScrollbarVisible }) =>
    isVScrollbarVisible ? '12px' : '0'};

  > :last-child {
    flex-frow: 1;
  }

  * {
    cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : '')};
  }
`;

export default React.memo<TableViewProps>(TableView);
