import { utcDay } from 'd3-time';

import type { CapacityChangeEvent } from 'modules/capacityChangeEvent/models/capacityChangeEvent';

const getDividerExtraPositionsFromPoints = (
  capacityEvents: CapacityChangeEvent[],
  index: number,
  xScale: any,
): number[] => {
  const capacityEvent = capacityEvents[index];
  const quantity = capacityEvents.length;

  if (!capacityEvent) return [];

  if (quantity <= 1) return xScale.range();

  if (index === 0) {
    const minDividerPosition = Math.max(
      xScale(utcDay.offset(capacityEvents[index + 1].dayInit, 1)),
      xScale.range()[0],
    );
    return [minDividerPosition, xScale.range()[1]];
  } else if (index === quantity - 1) {
    const maxDividerPosition = Math.min(
      xScale(utcDay.offset(capacityEvents[index - 1].dayInit, -1)),
      xScale.range()[1],
    );
    return [xScale.range()[0], maxDividerPosition];
  }

  const maxDividerPosition = Math.min(
    xScale(utcDay.offset(capacityEvents[index - 1].dayInit, -1)),
    xScale.range()[1],
  );
  const minDividerPosition = Math.max(
    xScale(utcDay.offset(capacityEvents[index + 1].dayInit, 1)),
    xScale.range()[0],
  );

  return [minDividerPosition, maxDividerPosition];
};

export default getDividerExtraPositionsFromPoints;
