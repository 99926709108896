const getSearchHighlightsHtml = (
  content: string,
  search?: string,
  matchedClass = 'match',
) => {
  if (!content || !search) return content;

  const matchedIdx = content.toLowerCase().indexOf(search.toLowerCase());

  if (matchedIdx === -1) return content;

  const matched = [matchedIdx, matchedIdx + search.length];

  const before = content.substring(0, matched[0]);
  const match = content.substring(matched[0], matched[1]);
  const after = getSearchHighlightsHtml(
    content.substring(matched[1]),
    search,
    matchedClass,
  );

  const result =
    before + `<span class='${matchedClass}'>` + match + '</span>' + after;

  return result;
};

export default getSearchHighlightsHtml;
