import { RibbonEventData } from 'modules/ribbon/models';

const getRibbonEventsStackHeight = (
  ribbonEvents: RibbonEventData[],
  ribbonHeight: number,
) => {
  let maxYLevel = 0;
  const yLevelsMap: Record<number, boolean> = {};

  ribbonEvents.forEach(e => {
    const yLevel = e.currentBounds.yLevel;
    const isVisible = e.currentBounds.width > 0;
    if (isVisible) yLevelsMap[yLevel] = true;
    if (yLevel > maxYLevel) maxYLevel = yLevel;
  });

  while (maxYLevel > 0 && !yLevelsMap[maxYLevel]) {
    maxYLevel -= 1;
  }

  return Math.max(ribbonHeight, ribbonHeight * (maxYLevel + 1));
};

export default getRibbonEventsStackHeight;
