import * as R from 'ramda';
import type { Category } from 'modules/category/models/category';
import { COLORS } from 'modules/chart/models/chart';
import type { Well } from 'modules/well/models/well';
import { countBoeValue } from 'modules/production/utils';

import { VarianceDrilldownTableItem } from '../models/drilldownTable';
import populateEntryWithGroupInfo from './populateEntryWithGroupInfo';

const normalizeVarianceDrilldownTable = (
  rawDrilldownTable: Record<string, any>[],
  wells: { [wellId: string]: Well },
  varianceCategories: Category[],
  additionalKeys: string[],
  days: number,
): VarianceDrilldownTableItem[] => {
  const withoutGroups = rawDrilldownTable.reduce((acc, entry) => {
    const {
      gasActual,
      oilActual,
      waterActual,
      gasVariance,
      oilVariance,
      waterVariance,
    } = entry;
    const data = {} as VarianceDrilldownTableItem;
    data.wellId = entry.wellId ? entry.wellId.toString() : null;
    data.well =
      entry.wellId && wells[entry.wellId] ? wells[entry.wellId].LEASE : '';
    data.gasActual = gasActual / days;
    data.oilActual = oilActual / days;
    data.varianceOptionId = entry.varianceOptionId;
    data.waterActual = waterActual / days;
    data.gasVariance = R.isNil(gasVariance) ? 0 : gasVariance / days;
    data.oilVariance = R.isNil(oilVariance) ? 0 : oilVariance / days;
    data.waterVariance = R.isNil(waterVariance) ? 0 : waterVariance / days;
    data.gasCapacity = R.isNil(gasVariance)
      ? 0
      : data.gasActual - data.gasVariance;
    data.oilCapacity = R.isNil(oilVariance)
      ? 0
      : data.oilActual - data.oilVariance;
    data.waterCapacity = R.isNil(waterVariance)
      ? 0
      : data.waterActual - data.waterVariance;
    data.boeActual = countBoeValue(data.oilActual, data.gasActual);
    data.boeVariance = countBoeValue(data.oilVariance, data.gasVariance);
    data.boeCapacity = countBoeValue(data.oilCapacity, data.gasCapacity);
    data.planType = varianceCategories[entry.varianceOptionId]
      ? varianceCategories[entry.varianceOptionId].planType
      : null;
    data.subCause = varianceCategories[entry.varianceOptionId]
      ? varianceCategories[entry.varianceOptionId].subCause
      : 'Uncategorized';
    data.color = varianceCategories[entry.varianceOptionId]
      ? varianceCategories[entry.varianceOptionId].color
      : COLORS.uncategorizedVariance;
    acc.push(data);
    return acc;
  }, []);

  return withoutGroups.map(entry =>
    populateEntryWithGroupInfo(entry, additionalKeys, wells),
  );
};

export default normalizeVarianceDrilldownTable;
