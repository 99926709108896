import { line } from 'd3-shape';
import * as R from 'ramda';
import * as React from 'react';

import type { CapacityChangeEvent } from 'modules/capacityChangeEvent/models/capacityChangeEvent';
import {
  Phase,
  useCapacityData,
} from 'modules/wellChart/context/capacityVariance';

interface CapacityLineProps {
  capacityEvent: CapacityChangeEvent;
  capacitySegment: { date: Date; capacity: number }[];
  isEditable?: boolean;
  xScale: any;
  yScale: any;
  trellisTitle: string;
}

const CapacityLine = ({
  capacityEvent,
  capacitySegment: capSeg,
  isEditable,
  xScale,
  yScale,
  trellisTitle,
}: CapacityLineProps) => {
  const capacityData = useCapacityData();
  const capacitySegment = capacityData.getSingleByPhase(
    trellisTitle as Phase,
    capacityEvent.id,
  );

  const createLine: (data: any) => any = React.useMemo(
    () =>
      line()
        .x((d: any) => xScale(d.date || 0))
        .y((d: any) => yScale(d.capacity)),
    [xScale, yScale],
  );
  const pathD = React.useMemo(
    () => createLine(capacitySegment),
    [capacitySegment, createLine],
  );

  return (
    <path
      stroke="black"
      strokeWidth={
        isEditable && capacityEvent && !R.isNil(capacityEvent.id) ? '3' : '1'
      }
      fill="none"
      d={pathD}
      vectorEffect="non-scaling-stroke"
      strokeLinejoin="bevel"
    />
  );
};

export default React.memo<CapacityLineProps>(CapacityLine);
