import gql from 'graphql-tag';

import type {
  Action,
  GraphqlQueryAction,
  GraphqlMutationAction,
} from 'store/models';

import { SeriesLayoutOption } from './models/seriesLayouts';

export const namespace = 'SERIES_LAYOUTS';

export const CREATE_SERIES_LAYOUT_REMOTELY = `${namespace}/CREATE_SERIES_LAYOUT_REMOTELY`;
export const FETCH_BASE_SERIES_LAYOUTS = `${namespace}/FETCH_BASE_SERIES_LAYOUTS`;
export const FETCH_USER_SERIES_LAYOUTS = `${namespace}/FETCH_USER_SERIES_LAYOUTS`;
export const REMOVE_SERIES_LAYOUT_REMOTELY = `${namespace}/REMOVE_SERIES_LAYOUT_REMOTELY`;
export const REORDER_SERIES_LAYOUT_REMOTELY = `${namespace}/REORDER_SERIES_LAYOUT_REMOTELY`;
export const REORDER_SERIES_LAYOUT_LOCALY = `${namespace}/REORDER_SERIES_LAYOUT_LOCALY`;
export const SET_USER_LAYOUTS = `${namespace}/SET_USER_LAYOUTS`;
export const RENAME_SERIES_LAYOUT_REMOTELY = `${namespace}/RENAME_SERIES_LAYOUT_REMOTELY`;
export const UPDATE_SERIES_LAYOUT_REMOTELY = `${namespace}/UPDATE_SERIES_LAYOUT_REMOTELY`;
export const REORDER_SERIES_LAYOUTS_REMOTYLY = `${namespace}/REORDER_SERIES_LAYOUTS_REMOTYLY`;
export const SET_CURRENT_SERIES_LAYOUT = `${namespace}/SET_CURRENT_SERIES_LAYOUT`;
export const SET_CURRENT_CONFIGURATION = `${namespace}/SET_CURRENT_CONFIGURATION`;

const FETCH_BASE_SERIES_LAYOUTS_QUERY = gql`
  query {
    listBaseSeriesLayouts {
      id
      userId
      name
      configuration
      order
    }
  }
`;

const FETCH_USER_SERIES_LAYOUTS_QUERY = gql`
  query ($userId: ID!) {
    listUserSeriesLayouts(userId: $userId) {
      id
      userId
      name
      configuration
      order
    }
  }
`;

type fetchBaseSeriesLayoutsAC = () => GraphqlQueryAction;

export const fetchBaseSeriesLayouts: fetchBaseSeriesLayoutsAC = () => ({
  type: FETCH_BASE_SERIES_LAYOUTS,
  payload: {
    key: 'listBaseSeriesLayouts',
    graphql: {
      query: FETCH_BASE_SERIES_LAYOUTS_QUERY,
    },
  },
});

type fetchUserSeriesLayoutsAC = (userId: string) => GraphqlQueryAction;

export const fetchUserSeriesLayouts: fetchUserSeriesLayoutsAC = userId => ({
  type: FETCH_USER_SERIES_LAYOUTS,
  payload: {
    key: 'listUserSeriesLayouts',
    graphql: {
      query: FETCH_USER_SERIES_LAYOUTS_QUERY,
      variables: { userId },
    },
  },
});

const CREATE_SERIES_LAYOUT_MUTATION = gql`
  mutation ($payload: CreateSeriesLayoutInput!) {
    createSeriesLayout(data: $payload) {
      id
      userId
      name
      configuration
      order
    }
  }
`;

type createSeriesLayoutRemotelyAC = (payload: {
  id: string;
  userId: string;
  name: string;
  configuration: string;
  order: number;
}) => GraphqlMutationAction;

export const createSeriesLayoutRemotely: createSeriesLayoutRemotelyAC =
  payload => ({
    type: CREATE_SERIES_LAYOUT_REMOTELY,
    payload: {
      key: 'createSeriesLayout',
      graphql: {
        mutation: CREATE_SERIES_LAYOUT_MUTATION,
        variables: { payload },
      },
    },
  });

const REMOVE_SERIES_LAYOUT_MUTATION = gql`
  mutation ($id: ID!) {
    deleteSeriesLayout(id: $id) {
      id
      userId
      name
      configuration
      order
    }
  }
`;

type removeSeriesLayoutRemotelyAC = (payload: {
  id: string;
}) => GraphqlMutationAction;

export const removeSeriesLayoutRemote: removeSeriesLayoutRemotelyAC =
  payload => ({
    type: REMOVE_SERIES_LAYOUT_REMOTELY,
    payload: {
      key: 'deleteSeriesLayout',
      graphql: {
        mutation: REMOVE_SERIES_LAYOUT_MUTATION,
        variables: payload,
      },
    },
  });

const RENAME_SERIES_LAYOUT_MUTATION = gql`
  mutation ($payload: SeriesLayoutInput!) {
    updateSeriesLayout(data: $payload) {
      id
      userId
      name
      configuration
      order
    }
  }
`;

type updateSeriesLayoutRemotelyAC = (
  payload: SeriesLayoutOption,
) => GraphqlMutationAction;

export const updateSeriesLayoutRemotely: updateSeriesLayoutRemotelyAC =
  payload => {
    return {
      type: UPDATE_SERIES_LAYOUT_REMOTELY,
      payload: {
        key: 'updateSeriesLayout',
        graphql: {
          mutation: RENAME_SERIES_LAYOUT_MUTATION,
          variables: { payload },
        },
      },
    };
  };

type renameSeriesLayoutRemotelyAC = (payload: {
  id: string;
  name: string;
}) => GraphqlMutationAction;

export const renameSeriesLayoutRemotely: renameSeriesLayoutRemotelyAC =
  payload => {
    return {
      type: RENAME_SERIES_LAYOUT_REMOTELY,
      payload: {
        key: 'updateSeriesLayout',
        graphql: {
          mutation: RENAME_SERIES_LAYOUT_MUTATION,
          variables: { payload },
        },
      },
    };
  };

const REORDER_SERIES_LAYOUTS_MUTATION = gql`
  mutation ($data: [SeriesLayoutInput!]!) {
    reorderSeriesLayouts(data: $data) {
      id
      userId
      name
      configuration
      order
    }
  }
`;

type reorderSeriesLayoutsRemotelyAC = (payload: {
  data: SeriesLayoutOption[];
}) => GraphqlMutationAction;

export const reorderSeriesLayoutsRemotely: reorderSeriesLayoutsRemotelyAC =
  payload => {
    return {
      type: REORDER_SERIES_LAYOUTS_REMOTYLY,
      payload: {
        key: 'reorderSeriesLayouts',
        graphql: {
          mutation: REORDER_SERIES_LAYOUTS_MUTATION,
          variables: payload,
        },
      },
    };
  };

type setCurrentSeriesLayoutFromRouteAC = (payload: {
  layout: SeriesLayoutOption;
}) => Action;

export const setCurrentSeriesLayoutFromRoute: setCurrentSeriesLayoutFromRouteAC =
  payload => ({
    type: SET_CURRENT_SERIES_LAYOUT,
    payload,
  });

type setCurrentSeriesConfigurationAC = (payload: {
  configuration: string;
}) => Action;

export const setCurrentSeriesConfiguration: setCurrentSeriesConfigurationAC =
  payload => ({
    type: SET_CURRENT_CONFIGURATION,
    payload,
  });

type reorderUserLayoutsLocallyAC = (payload: {
  dragId: string;
  hoverId: string;
}) => Action;

export const reorderUserLayoutsLocally: reorderUserLayoutsLocallyAC =
  payload => ({
    type: REORDER_SERIES_LAYOUT_LOCALY,
    payload,
  });

type setUserLayoutsLocallyAC = (payload: {
  layouts: SeriesLayoutOption[];
}) => Action;

export const setUserLayoutsLocally: setUserLayoutsLocallyAC = payload => ({
  type: SET_USER_LAYOUTS,
  payload,
});
