import * as React from 'react';

interface Props<T> {
  onAddSelection?: (item: T) => void;
  onSelectMany?: (range: { from: number; to: number }) => void;
  onSelect?: (item: T) => void;
  onDeselect?: (item: T) => void;
  shouldIgnoreSelection?: (item: T) => boolean;
  isItemSelected: (item: T) => boolean;
  selectedItemCount: number;
  selected?: number;
}

export const useMultipleSelection = <
  T extends { event: MouseEvent; index: number },
>({
  onAddSelection,
  onDeselect,
  onSelect,
  onSelectMany,
  shouldIgnoreSelection,
  isItemSelected,
  selectedItemCount,
  selected,
}: Props<T>) => {
  const [lastSelected, setLastSelected] = React.useState(selected ?? -1);

  React.useEffect(() => setLastSelected(prev => selected ?? prev), [selected]);

  const selectHandler = React.useCallback(
    (data: T) => {
      if (shouldIgnoreSelection?.call(null, data)) return;
      const { shiftKey, ctrlKey, metaKey } = data.event;

      const isSelected = isItemSelected(data);

      setLastSelected(data.index);

      if (ctrlKey || metaKey) {
        if (isSelected) return onDeselect?.call(null, data);

        if (selectedItemCount > 0) return onAddSelection?.call(null, data);

        onSelect?.call(null, data);
      } else if (shiftKey) {
        if (selectedItemCount > 0) {
          const [from, to] =
            lastSelected < data.index
              ? [lastSelected, data.index]
              : [data.index, lastSelected];

          onSelectMany?.call(null, { from, to: to + 1 });
        } else if (selectedItemCount === 0) {
          onAddSelection?.call(null, data);
        }
      } else {
        onSelect?.call(null, data);
      }
    },
    [
      isItemSelected,
      onDeselect,
      onSelect,
      onSelectMany,
      onAddSelection,
      lastSelected,
      selectedItemCount,
    ],
  );

  const data = React.useMemo(
    () => ({
      lastSelected,
      select: (index: number) => setLastSelected(index),
      selectHandler,
    }),
    [lastSelected, selectHandler],
  );

  return data;
};
