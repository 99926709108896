import React from 'react';
import styled from 'styled-components';

type Dock = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

interface ScrollBarProps {
  clientSize?: string;
  scrollSize?: number;
  horizontal?: boolean;
  dock?: Dock;
  onScroll?: (value: number, horizontal: boolean) => void;
  style?: React.CSSProperties;
  scrollable?: HTMLElement | null;
  onAppear?: () => void;
  onDisappear?: () => void;
}

const MIN_THUMB_SIZE = 20;

const ScrollBar = ({
  clientSize,
  scrollSize,
  horizontal,
  dock,
  onScroll,
  onAppear,
  onDisappear,
  style,
  scrollable,
}: ScrollBarProps) => {
  const [trackSize, setTrackSize] = React.useState({ value: 0 });
  const [thumbSize, setThumbSize] = React.useState({ value: 0 });

  const [thumbPos, setThumbPos] = React.useState(0);
  const pivot = React.useRef(0);
  const previousThumbPos = React.useRef(0);
  const previousThumbPagePos = React.useRef(0);

  const availableScrollPX = React.useMemo(
    () => ({ value: trackSize.value - thumbSize.value }),
    [trackSize, thumbSize],
  );
  const stepPerPX = React.useMemo(() => {
    const axis = horizontal ? 'Width' : 'Height';

    if (!scrollable) return 0;

    const subtractFrom =
      scrollSize === undefined ? scrollable[`scroll${axis}`] : scrollSize;

    return (
      (subtractFrom - scrollable[`client${axis}`]) / availableScrollPX.value
    );
  }, [availableScrollPX, clientSize, scrollable, scrollSize, horizontal]);

  const isVisible = React.useMemo(
    () => trackSize.value > thumbSize.value,
    [trackSize, thumbSize],
  );
  const [isDragging, setIsDragging] = React.useState(false);

  const scroller = React.useRef<HTMLDivElement | null>(null);

  const scrollbarDockStyle = React.useMemo(() => {
    if (!dock) return null;
    return dock.split('-').map(dockTo => `${dockTo}: 0;`);
  }, [dock]);

  const onResizeHandler = React.useCallback(
    entries => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) return;
        setTrackSize(prev => {
          const element = clientSize
            ? scroller.current
            : scroller.current?.parentElement;

          if (!element) return prev;
          return {
            value: horizontal ? element.offsetWidth : element.offsetHeight,
          };
        });
      });
    },
    [setTrackSize, scroller, horizontal, clientSize],
  );

  const scrollBarResizeObserver = React.useMemo(
    () => new ResizeObserver(onResizeHandler),
    [onResizeHandler],
  );
  const scrollContainerResizeObserver = React.useMemo(
    () => new ResizeObserver(onResizeHandler),
    [onResizeHandler],
  );

  const onScrollHandler = React.useCallback(
    (value: number) => {
      const scrollSide = horizontal ? 'scrollLeft' : 'scrollTop';
      if (scrollable) {
        scrollable[scrollSide] = value;
        onScroll?.call(null, scrollable[scrollSide], true);
        return;
      }

      onScroll?.call(null, value, true);
    },
    [scrollable, horizontal],
  );

  const onMouseMoveHandler = React.useCallback(
    e => {
      const calculatedPos =
        previousThumbPos.current +
        (horizontal ? e.pageX : e.pageY) -
        previousThumbPagePos.current -
        pivot.current;

      const normalizedPos =
        calculatedPos <= 0
          ? 0
          : calculatedPos >= availableScrollPX.value
          ? availableScrollPX.value
          : calculatedPos;

      if (normalizedPos === thumbPos) return;

      onScrollHandler(normalizedPos * stepPerPX);
      setThumbPos(normalizedPos);
    },
    [setThumbPos, onScrollHandler, thumbPos, availableScrollPX, horizontal],
  );

  const onMouseUpHandler = React.useCallback(
    () => setIsDragging(false),
    [setIsDragging],
  );

  const onMouseDownHandler = React.useCallback(
    e => {
      const axis = horizontal ? 'X' : 'Y';
      setIsDragging(true);
      pivot.current = e.nativeEvent[`offset${axis}`];
      previousThumbPos.current = thumbPos;
      previousThumbPagePos.current =
        e.nativeEvent[`page${axis}`] - e.nativeEvent[`offset${axis}`];
    },
    [setIsDragging, thumbPos, horizontal],
  );

  // Register mouse events
  React.useEffect(() => {
    if (!isDragging) return;
    document.addEventListener('mousemove', onMouseMoveHandler);
    document.addEventListener('mouseup', onMouseUpHandler);

    return () => {
      document.removeEventListener('mousemove', onMouseMoveHandler);
      document.removeEventListener('mouseup', onMouseUpHandler);
    };
  }, [isDragging, onMouseMoveHandler, onMouseUpHandler]);

  // Observe resize event
  React.useEffect(() => {
    if (scroller.current) scrollBarResizeObserver.observe(scroller.current);
    if (scrollable && scrollable.firstElementChild) {
      scrollContainerResizeObserver.observe(scrollable.firstElementChild);
    }

    return () => {
      if (scroller.current) scrollBarResizeObserver.unobserve(scroller.current);
      if (scrollable && scrollable.firstElementChild) {
        scrollContainerResizeObserver.unobserve(scrollable.firstElementChild);
      }
    };
  }, [
    scrollable,
    scroller.current,
    scrollBarResizeObserver,
    scrollContainerResizeObserver,
  ]);

  React.useEffect(() => {
    if (!scroller.current || !clientSize) return;
    const axis = horizontal ? 'Width' : 'Height';
    setTrackSize({ value: scroller.current[`client${axis}`] });
  }, [clientSize, scroller, setTrackSize, horizontal]);

  // Recalculate thumb size
  React.useEffect(() => {
    if (scrollSize) {
      const newThumbSize = (trackSize.value * trackSize.value) / scrollSize;
      setThumbSize({
        value: newThumbSize < MIN_THUMB_SIZE ? MIN_THUMB_SIZE : newThumbSize,
      });
    } else if (scrollable) {
      const scrollAxis = horizontal ? 'scrollWidth' : 'scrollHeight';
      const scrollableSize = scrollable[scrollAxis];
      const newThumbSize = (trackSize.value * trackSize.value) / scrollableSize;
      setThumbSize({
        value: newThumbSize < MIN_THUMB_SIZE ? MIN_THUMB_SIZE : newThumbSize,
      });
    }
  }, [trackSize, scrollable, scrollSize, setThumbSize, horizontal]);

  // Limit scroll bounds
  React.useEffect(() => {
    if (thumbPos >= availableScrollPX.value)
      setThumbPos(availableScrollPX.value);
    if (thumbPos < 0) setThumbPos(0);
  }, [setThumbPos, availableScrollPX.value]);

  // Sync scroll
  React.useEffect(() => {
    if (!scrollable) return;
    const handler = e => {
      const newPos = horizontal ? e.target.scrollLeft : e.target.scrollTop;
      setThumbPos(newPos / stepPerPX);
    };

    scrollable.addEventListener('scroll', handler);
    return () => scrollable.removeEventListener('scroll', handler);
  }, [stepPerPX, scrollable, setThumbPos, horizontal]);

  // Appear / Disappear
  React.useEffect(
    () => (isVisible ? onAppear?.call(null) : onDisappear?.call(null)),
    [isVisible, onAppear, onDisappear],
  );

  return (
    <ScrollBar.ScrollContainer
      ref={scroller}
      style={style}
      isVisible={isVisible}
      clientSize={clientSize ?? '100%'}
      dockStyle={scrollbarDockStyle}
      horizontal={horizontal}
      className="VScrollBar__Scroller"
    >
      <ScrollBar.Thumb
        style={{
          [`${horizontal ? 'left' : 'top'}`]: `${thumbPos}px`,
          [`${horizontal ? 'width' : 'height'}`]: `${thumbSize.value}px`,
        }}
        onMouseDown={onMouseDownHandler}
      ></ScrollBar.Thumb>
    </ScrollBar.ScrollContainer>
  );
};

ScrollBar.ScrollContainer = styled.div`
  position: absolute;

  ${({ dockStyle }) => dockStyle ?? ''};

  ${({ horizontal, clientSize }) =>
    horizontal
      ? `width: ${clientSize}; height: 12px;`
      : `width: 12px; height: ${clientSize};`}

  ${({ isVisible }) => (!isVisible ? 'left: -9999px;' : '')}

  z-index: 999;
  overflow: hidden;

  background: #e1e1e1;
`;

ScrollBar.Thumb = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  background: #484848;
`;

export default ScrollBar;
