import { line } from 'd3-shape';
import * as React from 'react';

import { ForecastData } from '../models';

interface ExternalForecastLineProps {
  phase: string;
  forecastData: ForecastData;
  xScale: any;
  yScale: any;
}

const ExternalForecastLine = ({
  forecastData,
  phase,
  xScale,
  yScale,
}: ExternalForecastLineProps) => {
  const createLine: (data: any) => any = React.useMemo(
    () =>
      line()
        .x((d: any) => xScale(new Date(d.day) || 0))
        .y((d: any) => yScale(d[phase])),
    [xScale, yScale, phase],
  );
  const pathD = React.useMemo(
    () => createLine(forecastData),
    [forecastData, createLine],
  );

  return (
    <path
      stroke="#C0C0C0"
      strokeWidth="2"
      fill="none"
      d={pathD}
      vectorEffect="non-scaling-stroke"
      strokeLinejoin="bevel"
    />
  );
};

export default React.memo<ExternalForecastLineProps>(ExternalForecastLine);
