import { max } from 'd3-array';

import { OIL, GAS, WATER } from 'modules/phase/models/phase';
import type { ProductionPoint } from 'modules/production/models/production';

const PHASE_LIST = [OIL, GAS, WATER];

const getMaxProductionRateByPhase = (
  productionData: ProductionPoint[],
): { [phase: string]: number } =>
  PHASE_LIST.reduce((acc, phaseName) => {
    const productionKey = phaseName.toLowerCase();

    acc[phaseName] =
      max(
        productionData,
        productionPoint => productionPoint[productionKey] * 1.05,
      ) || 0;

    return acc;
  }, {} as any);

export default getMaxProductionRateByPhase;
