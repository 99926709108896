import * as React from 'react';

const useStorage = (
  key: string,
  defaultValue: any,
  storageName: 'local' | 'session',
) => {
  const [value, setValue] = React.useState(defaultValue);
  const storage = storageName === 'local' ? localStorage : sessionStorage;

  const readFromStorage = React.useCallback(() => {
    const storedValue = storage.getItem(key);
    const parsedValue = storedValue ? JSON.parse(storedValue) : defaultValue;
    setValue(parsedValue);
    return;
  }, [defaultValue, key, setValue, storage]);

  const writeToStorage = (newValue: any) => {
    if (newValue !== undefined) {
      storage.setItem(key, JSON.stringify(newValue));
    } else {
      storage.removeItem(key);
    }
    setValue(newValue);
  };

  React.useEffect(() => {
    if (window.sessionStorage) {
      readFromStorage();
    }
  }, [readFromStorage]);

  return [value, writeToStorage];
};

export default useStorage;
