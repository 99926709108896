import { utcYear } from 'd3-time';

export type AppSettingType<T> = {
  default: T;
  current: T;
  key: string;
  name: string;
};

export type InputConverter<I, O> = {
  serialize: (value: I) => O;
  deserialize: (value: O) => I;
};

export const currencyConverter: InputConverter<number, string> = {
  serialize: value => {
    if (!Number.isFinite(value)) return '$0';
    return '$' + value.toFixed(2);
  },
  deserialize: value => {
    const match = /[-]?[0-9]+\.?[0-9]*/.exec(value);
    if (!match) return NaN;
    return parseFloat(match[0]);
  },
};

export const DEFAULT_MAX = utcYear.offset(new Date(), 10);
export const DEFAULT_MIN = utcYear.offset(new Date(), -10);
