import styled from 'styled-components';

export const Tab = styled.div`
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ isActive }) =>
    isActive ? '#ffffff' : 'linear-gradient(180deg, #f3f3f3, #e1e1e1)'};
  border: 1px solid #c1c1c1;
  border-bottom: 0;
  border-top: 0;

  color: #484848;
  font-weight: bold;
  margin-bottom: -1px;
  cursor: ${({ isActive }) => (isActive ? 'auto' : 'pointer')};

  ::after {
    content: '';
    display: ${({ isActive }) => (isActive ? 'none' : 'block')};
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #c1c1c1;
  }
`;
