import styled from 'styled-components';
import React from 'react';

export const EmptyInboxMessage = () => {
  return (
    <EmptyInboxMessage.EmptyMessageContainer>
      <EmptyInboxMessage.EmptyIcon
        url="/emptyInboxIcon.svg"
        width="134px"
        height="120px"
      />
      <EmptyInboxMessage.EmptyTitle>
        There are no notes in your inbox.
      </EmptyInboxMessage.EmptyTitle>
      <EmptyInboxMessage.EmptyMessage>
        Notes will appear in your inbox when someone{' '}
        <EmptyInboxMessage.Tag>@tags</EmptyInboxMessage.Tag> you in a note or
        writes a note in a conversation you have already written in.
      </EmptyInboxMessage.EmptyMessage>
    </EmptyInboxMessage.EmptyMessageContainer>
  );
};

EmptyInboxMessage.EmptyMessageContainer = styled.div`
  padding-bottom: 24px;
  display: grid;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  justify-items: center;
`;

EmptyInboxMessage.EmptyIcon = styled.div`
  background: url(${props => props.url}) no-repeat;
  width: ${props => props.width};
  height: ${props => props.height};
`;

EmptyInboxMessage.EmptyTitle = styled.div`
  margin-top: 48px;
  font-size: 16px;
  color: #484848;
  font-weight: 700;
  line-height: 24px;
`;

EmptyInboxMessage.EmptyMessage = styled.div`
  width: 340px;
  text-align: center;
  margin-top: 11px;
  font-size: 14px;
  font-weight: 400;
  color: #484848;
  line-height: 20px;
`;

EmptyInboxMessage.Tag = styled.span`
  color: #41ab5d;
`;
