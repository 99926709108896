import React from 'react';
import styled from 'styled-components';

import { CheckIcon } from 'components/Icons';

interface ShowTrellisButtonProps {
  onShowClick: () => void;
  show: boolean;
}
const ShowTrellisButton = ({ show, onShowClick }: ShowTrellisButtonProps) => {
  return (
    <ShowTrellisButton.Button onClick={onShowClick}>
      {show ? <CheckIcon /> : null}
    </ShowTrellisButton.Button>
  );
};

ShowTrellisButton.Button = styled.button`
  height: 20px;
  border-radius: 0;
  border: none;
  border-left: ${p => p.theme.borders.thingray};
  padding: 0;
  min-width: 23px;
  outline: none;
`;

export default ShowTrellisButton;
