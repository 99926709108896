import React, { useState } from 'react';
import styled from 'styled-components';
import { ChromePicker } from 'react-color';

import Button from 'components/Button';
import { BAR_CHART, STEP_LINE_CHART, ChartOption } from '../models';

interface ColorPickerProps {
  color: string;
  customColor: string;
  onColorChange: (color: string) => void;
  onMenuHover: (e: MouseEvent) => void;
  onMenuLeave: (e: MouseEvent) => void;
  onSetChartType: (id: string, type: string) => void;
  option: ChartOption;
  top: number;
}

const ColorPicker = ({
  color: initialColor,
  customColor,
  top,
  onColorChange,
  onMenuLeave,
  onMenuHover,
  onSetChartType,
  option,
}: ColorPickerProps) => {
  const [tempColor, setTempColor] = useState(customColor || initialColor);

  const onSetTempColor = React.useCallback(
    newColor => {
      setTempColor(newColor.hex);
    },
    [setTempColor],
  );
  const onSetColor = React.useCallback(
    newColor => {
      onColorChange(newColor.hex);
    },
    [onColorChange],
  );

  const onRestColor = React.useCallback(() => {
    onColorChange('');
    setTempColor(initialColor);
  }, [onColorChange, initialColor, setTempColor]);

  return (
    <ColorPicker.Container
      top={top}
      onMouseLeave={onMenuLeave}
      onMouseEnter={onMenuHover}
    >
      <ColorPicker.ButtonsContainer>
        <ColorPicker.ResetButton onClick={onRestColor}>
          Reset
        </ColorPicker.ResetButton>
        <ColorPicker.TypeButtonContainer>
          <ColorPicker.BarsButton
            type={option.chartType}
            onClick={() => onSetChartType(option.id, BAR_CHART)}
          >
            Bar
          </ColorPicker.BarsButton>
          <ColorPicker.LineButton
            type={option.chartType}
            onClick={() => onSetChartType(option.id, STEP_LINE_CHART)}
          >
            Line
          </ColorPicker.LineButton>
        </ColorPicker.TypeButtonContainer>
      </ColorPicker.ButtonsContainer>
      <ColorPicker.PickerContainer>
        <ChromePicker
          color={tempColor}
          onChangeComplete={onSetColor}
          onChange={onSetTempColor}
          disableAlpha
        />
      </ColorPicker.PickerContainer>
    </ColorPicker.Container>
  );
};

ColorPicker.Container = styled.div`
  border: 1px solid black;
  border-radius: 7px;
  background-color: white;
  z-index: 100;
`;

ColorPicker.ButtonsContainer = styled.div`
  padding: 10px;
  display: grid;
  grid-template-columns: 45% 55%;
`;

ColorPicker.PickerContainer = styled.div`
  margin: 0 10px 10px 10px;
  .chrome-picker {
    box-shadow: none !important;
    > div:last-child > div:last-child {
      display: none !important;
    }
  }
`;

ColorPicker.TypeButtonContainer = styled.div`
  width: 100px;
  border: 1px solid #c1c1c1;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  overflow: hidden;
`;

ColorPicker.BarsButton = styled.div`
  width: 50px;
  border-right: 1px solid #c1c1c1;
  background-color: ${props =>
    props.type === BAR_CHART ? 'white' : '#EFEFEF'};
  text-align: center;
  padding-top: 2px;
`;

ColorPicker.LineButton = styled.div`
  width: 50px;
  background-color: ${props =>
    props.type === STEP_LINE_CHART ? 'white' : '#EFEFEF'};
  text-align: center;
  padding-top: 2px;
`;

ColorPicker.ResetButton = styled(Button)`
  max-height: 20px;
  width: 60px;
  justify-self: center;
`;

export default ColorPicker;
