import styled from 'styled-components';

const Badge = styled.div`
  height: 14px;
  width: 14px;
  border-radius: 50%;
  position: absolute;
  top: ${({ top }) => top}px;
  right: ${({ right }) => right}px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  font-weight: bold;
`;

export const FilterBadge = styled(Badge)`
  background-color: #c60000;
  color: #e8e8e8;
`;

export const InboxBadge = styled(Badge)`
  background-color: #484848;
  color: #ffffff;
  border: 1px solid #ffffff;
`;
