import * as R from 'ramda';
import { v4 as uuid } from 'uuid';

import { Phase, OIL, GAS, WATER } from 'modules/phase/models/phase';
import type { ProductionPoint } from 'modules/production/models/production';

const createNewAllocIssue = (
  date: Date,
  wellProduction: ProductionPoint[],
  wellCapacityByPhase: {
    [phase: string]: Array<{ date: Date; capacity: number }>;
  },
  phasesByName: { [phaseName: string]: Phase },
  wellId: string,
  userId: string,
) => {
  const flatCapacityDataByPhase = R.map(
    // $FlowFixMe
    phaseCapacityData => R.flatten(phaseCapacityData),
    wellCapacityByPhase,
  );

  const actualVolumePoint = wellProduction.find(
    productionPoint =>
      new Date(productionPoint.day).getTime() === date.getTime(),
  );
  const oilActualVolume = actualVolumePoint ? actualVolumePoint.oil : 0;
  const gasActualVolume = actualVolumePoint ? actualVolumePoint.gas : 0;
  const waterActualVolume = actualVolumePoint ? actualVolumePoint.water : 0;

  const oilCapacityVolumePoint = flatCapacityDataByPhase[OIL].find(
    capacityDatapoint => capacityDatapoint.date.getTime() === date.getTime(),
  );
  const oilCapacityVolume = oilCapacityVolumePoint
    ? oilCapacityVolumePoint.capacity
    : 0;
  const gasCapacityVolumePoint = flatCapacityDataByPhase[GAS].find(
    capacityDatapoint => capacityDatapoint.date.getTime() === date.getTime(),
  );
  const gasCapacityVolume = gasCapacityVolumePoint
    ? gasCapacityVolumePoint.capacity
    : 0;
  const waterCapacityVolumePoint = flatCapacityDataByPhase[WATER].find(
    capacityDatapoint => capacityDatapoint.date.getTime() === date.getTime(),
  );
  const waterCapacityVolume = waterCapacityVolumePoint
    ? waterCapacityVolumePoint.capacity
    : 0;

  const newIssue = {
    id: uuid(),
    wellId,
    dateStart: date,
    dateEnd: date,
    assigneeId: userId,
    editorId: userId,
    statusId: '1',
    phases: [
      {
        phaseName: OIL,
        phaseId: phasesByName[OIL].id,
        actualVolume: oilActualVolume,
        capacityVolume: oilCapacityVolume,
        checked: true,
      },
      {
        phaseName: GAS,
        phaseId: phasesByName[GAS].id,
        actualVolume: gasActualVolume,
        capacityVolume: gasCapacityVolume,
        checked: true,
      },
      {
        phaseName: WATER,
        phaseId: phasesByName[WATER].id,
        actualVolume: waterActualVolume,
        capacityVolume: waterCapacityVolume,
        checked: true,
      },
    ],
    causes: [],
  };

  return newIssue;
};

export default createNewAllocIssue;
