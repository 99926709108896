import * as React from 'react';
import styled from 'styled-components';

import Button from 'components/Button';
import { CrossIcon } from 'components/Icons';
import { isIdNew } from 'helpers';
import { FaTrashAlt } from 'react-icons/fa';

const MAX_FILE_SIZE_IN_BYTES = 52428800;
const MAX_COEF = 0.6;
const MIN_COEF = 0.00000001;

interface AttachmentFormItemsProps {
  attachment: {
    file: any;
    id: string;
    timestamp?: string;
  };
  handleAttachmentDelete: (id: string) => void;
}

const AttachmentFormItems = ({
  attachment,
  handleAttachmentDelete,
}: AttachmentFormItemsProps) => {
  const { file } = attachment;
  const coef = file.size
    ? MAX_COEF - ((MAX_COEF - MIN_COEF) / MAX_FILE_SIZE_IN_BYTES) * file.size
    : 0;

  const [completed, setCompleted] = React.useState(
    !isIdNew(attachment.id) ? 100 : 0,
  );
  const [step, setStep] = React.useState(0);

  React.useEffect(() => {
    const progress = () => {
      setStep(oldStep => oldStep + coef / 4);
      setCompleted(oldCompleted => {
        if (!isIdNew(attachment.id)) {
          return 100;
        }
        return (
          Math.round((Math.atan(step) / (Math.PI / 2)) * 100 * 1000) / 1000
        );
      });
    };

    const timer = setInterval(progress, 200);
    if (completed === 100) clearInterval(timer);
    return () => {
      clearInterval(timer);
    };
  }, [attachment.id, step, completed, coef]);

  return (
    <>
      {file.type.includes('image') ? null : (
        <AttachmentFormItems.Container>
          <AttachmentFormItems.Progress
            percent={completed}
            isTransparent={completed >= 100}
          />

          <AttachmentFormItems.FileName>
            {file.name}
          </AttachmentFormItems.FileName>
          {completed === 100 ? (
            <AttachmentFormItems.Button
              transparent
              height={30}
              width={20}
              onClick={() => handleAttachmentDelete(file.id)}
            >
              <FaTrashAlt size={16} />
            </AttachmentFormItems.Button>
          ) : (
            <AttachmentFormItems.CrossContainer>
              <CrossIcon width={20} height={20} />
            </AttachmentFormItems.CrossContainer>
          )}
        </AttachmentFormItems.Container>
      )}
    </>
  );
};

AttachmentFormItems.Container = styled.div`
  border: 1px solid #41ab5d;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 32px;
  overflow: hidden;
`;

AttachmentFormItems.FileName = styled.div`
  width: calc(100% - 40px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 15px;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  position: relative;
  z-index: 5;
`;

AttachmentFormItems.Progress = styled.div.attrs(props => ({
  style: {
    width: `${props.percent}%`,
  },
}))`
  border: none;
  background-color: #d0ead7;
  border-radius: 15px;
  height: 100%;
  position: absolute;
  z-index: 1;
  transition: all 0.5s;
`;

AttachmentFormItems.Button = styled(Button)`
  margin-right: 12px;
  position: relative;
  z-index: 5;
`;

AttachmentFormItems.CrossContainer = styled.div`
  margin-right: 10px;
`;

export default AttachmentFormItems;
