/**
 * Calculate decline nominal from effective
 * @param {number} declineEffective
 * @param {number} bFactor
 * @returns {number} - decline nominal
 */
const convertDeclineEffectiveToNominal = (
  declineEffective: number,
  bFactor: number,
): number => {
  if (declineEffective < 0) return declineEffective;
  if (bFactor === 0) return (-1 * Math.log(1 - declineEffective / 100)) / 365;
  if (bFactor > 0)
    return (
      (Math.pow(1 - declineEffective / 100, -1 * bFactor) - 1) / (bFactor * 365)
    );

  return declineEffective;
};

export default convertDeclineEffectiveToNominal;
