import { area } from 'd3-shape';
import * as React from 'react';

interface VarianceAreaProps {
  color: string;
  clipPath: string;
  varianceData: Array<{ date: Date; capacity: number }>;
  xScale: any;
  yScale: any;
}

const VarianceArea = ({
  clipPath,
  color,
  varianceData,
  xScale,
  yScale,
}: VarianceAreaProps) => {
  const createArea: (data: any) => any = React.useMemo(
    () =>
      area()
        .x((d: any) => xScale(d.date || 0))
        .y1((d: any) => yScale(d.capacity))
        .y0(yScale(0)),
    [xScale, yScale],
  );
  const pathD = React.useMemo(
    () => createArea(varianceData),
    [varianceData, createArea],
  );

  return <path fill={color} d={pathD} stroke={color} clipPath={clipPath} />;
};

export default React.memo<VarianceAreaProps>(VarianceArea);
