import { TextareaAutosize } from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';

type Props = {
  onChange: (value: string) => void;
  initialValue: string;
} & { [key: string]: any };

export const DescriptionForm = ({
  onChange,
  initialValue,
  disabled,
  ...props
}: Props) => {
  const [description, setDescription] = React.useState(initialValue);

  const handleInput = React.useCallback(e => {
    setDescription(e.target.value);
  }, []);

  const onSubmit = React.useCallback(() => {
    if (initialValue === description) return;
    onChange(description);
  }, [initialValue, description]);

  React.useEffect(() => {
    setDescription(initialValue);
  }, [initialValue]);

  return (
    <DescriptionForm.Container {...props}>
      <DescriptionForm.Textarea
        value={description}
        onChange={handleInput}
        onBlur={onSubmit}
        disabled={disabled}
      />
    </DescriptionForm.Container>
  );
};

DescriptionForm.Container = styled.div`
  border: 1px solid #c1c1c1;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  padding: 4px 6px 4px;
  width: 268px;
`;

DescriptionForm.Textarea = styled(TextareaAutosize)`
  width: 100%;
  height: auto;
  min-height: 18px;
  border: none;
  resize: none;
  ${({ isForEdit }) => (isForEdit ? 'padding: 0; line-height: 20px;' : '')}
  background-color: white;

  :focus {
    outline: 0;
  }

  :disabled {
    color: #a9a9a9;
  }
`;
