import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getLeftPanelActiveTabId } from 'modules/ui/UIReducer';
import { setLeftPanelActiveTab } from 'modules/ui/UIActions';

const useLeftPanel = () => {
  const dispatch = useDispatch();
  const activeTabId = useSelector(getLeftPanelActiveTabId);

  const setActiveTabId = React.useCallback(
    id => dispatch(setLeftPanelActiveTab(id)),
    [dispatch],
  );

  const isNotesTabOpen = React.useMemo(() => {
    return activeTabId === 'notes';
  }, [activeTabId]);

  return {
    activeTabId,
    setActiveTabId,
    isNotesTabOpen,
  };
};

export default useLeftPanel;
