import * as React from 'react';
import { v4 as uuid } from 'uuid';

export enum Code {
  KeyQ,
  KeyW,
  KeyE,
  KeyR,
  KeyT,
  KeyY,
  KeyU,
  KeyI,
  KeyO,
  KeyP,
  KeyA,
  KeyS,
  KeyD,
  KeyF,
  KeyG,
  KeyH,
  KeyJ,
  KeyK,
  KeyL,
  KeyZ,
  KeyX,
  KeyC,
  KeyV,
  KeyB,
  KeyN,
  KeyM,
  Digit1,
  Digit2,
  Digit3,
  Digit4,
  Digit5,
  Digit6,
  Digit7,
  Digit8,
  Digit9,
  Digit0,
  Escape,
  Enter,
  Slash,
}

type CancelCallback = () => void;
type Callback = (cancel: CancelCallback, event: KeyboardEvent) => void;
type CallbackEntity = { id: string; callback: Callback };

const binding: { [key in keyof typeof Code]?: CallbackEntity[] } = {};

const createCancellable = () => {
  const cancellationObject = { value: false };
  return {
    cancel: () => {
      cancellationObject.value = true;
    },
    isCancelled: () => cancellationObject.value,
  };
};

const queueDispatcher = (event: KeyboardEvent) => {
  const queue = binding[event.code];
  if (!queue || queue.length === 0) return;
  for (let i = queue.length - 1; i >= 0; i--) {
    const { callback } = queue[i];
    if (!callback) continue;

    const cancellable = createCancellable();
    callback(cancellable.cancel, event);

    if (cancellable.isCancelled()) break;
  }
};

/**
 * @param callback function to be called
 * @param code key to be binded
 * @param deps dependencies
 * */
const useShortcut = (callback: Callback, code: Code, deps: any[] = []) => {
  const removeHandlerFromQueue = React.useCallback((id: string) => {
    const queue = binding[Code[code]];
    const index = queue.findIndex(e => e.id === id);
    if (index > -1) queue.splice(index, 1);
  }, []);

  const id = React.useRef<string | null>(uuid());

  React.useEffect(() => {
    document.addEventListener('keydown', queueDispatcher);
    if (!binding[Code[code]]) binding[Code[code]] = [];
    const queue = binding[Code[code]];
    queue.push({ id: id.current, callback });

    return () => {
      document.removeEventListener('keydown', queueDispatcher);
      id.current && removeHandlerFromQueue(id.current);
    };
  }, deps.concat([id.current]));
};

export default useShortcut;
