import { area, curveStepAfter } from 'd3-shape';
import * as R from 'ramda';
import * as React from 'react';

interface EventVarianceAreaProps {
  color: string;
  varianceArea: { date: Date; variance: number }[];
  xScale: any;
  yScale: any;
}

const EventVarianceArea = ({
  varianceArea,
  color,
  yScale,
  xScale,
}: EventVarianceAreaProps) => {
  const createArea: (data: any) => any = React.useMemo(
    () =>
      area()
        .x((d: any) => xScale(d.date || 0))
        .y1(d => yScale(R.pathOr(0, ['variance'], d)))
        .y0(yScale(0))
        .curve(curveStepAfter),
    [yScale, xScale],
  );

  return <path fill={color} d={createArea(varianceArea)} />;
};

export default EventVarianceArea;
