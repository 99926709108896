import { utcDay } from 'd3-time';
import * as React from 'react';

import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';

interface AllocIssueProps {
  allocIssue: AllocIssue;
  xScale: any;
  height: number;
  isEditable: boolean;
  isHighlighted: boolean;
}

const AllocIssueArea = ({
  allocIssue,
  height,
  xScale,
  isEditable,
  isHighlighted,
}: AllocIssueProps) => {
  const startPos = xScale(allocIssue.dateStart);
  const endPos = xScale(utcDay.offset(allocIssue.dateEnd, 1));

  return (
    <>
      <defs>
        <pattern
          id={`diagonalHatch_${allocIssue.id}`}
          patternUnits="userSpaceOnUse"
          width="7"
          height="7"
        >
          <path
            d="M-1,1 l2,-2 M0,7 l7,-7 M6,8 l2,-2"
            stroke={isHighlighted ? '#D62728' : 'rgb(247, 180, 180)'}
            strokeWidth="1"
          />
        </pattern>
      </defs>
      <polygon
        points={`${startPos},0 ${endPos},0 ${endPos},${height} ${startPos},${height}`}
        fill={`url(#diagonalHatch_${allocIssue.id})`}
      />
      <line
        x1={startPos}
        x2={startPos}
        y1={0}
        y2={height}
        stroke={isHighlighted ? '#D62728' : 'rgb(247, 180, 180)'}
        strokeWidth={isEditable ? '2' : '1'}
      />
      <line
        x1={endPos}
        x2={endPos}
        y1={0}
        y2={height}
        stroke={isHighlighted ? '#D62728' : 'rgb(247, 180, 180)'}
        strokeWidth={isEditable ? '2' : '1'}
      />
    </>
  );
};

export default AllocIssueArea;
