import React from 'react';

import {
  CHART_TITLE_HEIGHT,
  TITLE_PARAMS,
  X_AXIS_HEIGHT,
  Y_AXIS_WIDTH,
} from '../models/chart';
import { ChartRectContext } from './useChartRect';

const useChartUIData = () => {
  const chartRect = React.useContext(ChartRectContext);
  const { width, height, leftOffset, rightOffset = 0 } = chartRect;

  const chartWidth = React.useMemo(() => {
    return width - Y_AXIS_WIDTH;
  }, [width]);

  const chartHeight = React.useMemo(() => {
    return height - CHART_TITLE_HEIGHT - X_AXIS_HEIGHT;
  }, [height]);

  const chartPos = React.useMemo(() => {
    const documentWidth = document.documentElement?.clientWidth || 0;
    const documentHeight = document.documentElement?.clientHeight || 0;
    const x1 = leftOffset + 10 + Y_AXIS_WIDTH;
    const x2 = documentWidth - rightOffset - 10 - TITLE_PARAMS.width;
    const y1 = 5;
    const y2 = documentHeight - X_AXIS_HEIGHT;
    return { x1, x2, y1, y2 };
  }, [leftOffset, rightOffset]);

  return {
    chartWidth,
    chartHeight,
    chartPos,
    chartRect,
  };
};

export default useChartUIData;
