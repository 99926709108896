import {
  OPEN_ALLOC_ISSUE_DIALOG,
  OPEN_CAPACITY_DIALOG,
  OPEN_CHART_OPTIONS_PANEL,
  OPEN_LEGEND_PANEL,
  OPEN_RIBBON_EVENT_DIALOG,
  OPEN_RIBBON_DETAILS_PANEL,
  OPEN_SHORTCUT_PANEL,
  OPEN_VARIANCE_DIALOG,
  OPEN_NOTES_DIALOG,
  CLOSE_ALLOC_ISSUE_DIALOG,
  CLOSE_CAPACITY_DIALOG,
  CLOSE_CHART_OPTIONS_PANEL,
  CLOSE_LEGEND_PANEL,
  CLOSE_RIBBON_EVENT_DIALOG,
  CLOSE_RIBBON_DETAILS_PANEL,
  CLOSE_SHORTCUT_PANEL,
  CLOSE_VARIANCE_DIALOG,
  CLOSE_NOTES_DIALOG,
} from '../UIActions';

export const DIALOG_TYPE_TO_OPEN_ACTION_TYPE = {
  AllocationIssue: OPEN_ALLOC_ISSUE_DIALOG,
  CapacityChangeEvent: OPEN_CAPACITY_DIALOG,
  ChartOptions: OPEN_CHART_OPTIONS_PANEL,
  Legend: OPEN_LEGEND_PANEL,
  // RequestHelp: ,
  RibbonEvent: OPEN_RIBBON_EVENT_DIALOG,
  RibbonDetails: OPEN_RIBBON_DETAILS_PANEL,
  Shortcuts: OPEN_SHORTCUT_PANEL,
  VarianceChangeEvent: OPEN_VARIANCE_DIALOG,
  WellNotes: OPEN_NOTES_DIALOG,
};

export const DIALOG_TYPE_TO_CLOSE_ACTION_TYPE = {
  AllocationIssue: CLOSE_ALLOC_ISSUE_DIALOG,
  CapacityChangeEvent: CLOSE_CAPACITY_DIALOG,
  ChartOptions: CLOSE_CHART_OPTIONS_PANEL,
  Legend: CLOSE_LEGEND_PANEL,
  // RequestHelp: ,
  RibbonEvent: CLOSE_RIBBON_EVENT_DIALOG,
  RibbonDetails: CLOSE_RIBBON_DETAILS_PANEL,
  Shortcuts: CLOSE_SHORTCUT_PANEL,
  VarianceChangeEvent: CLOSE_VARIANCE_DIALOG,
  WellNotes: CLOSE_NOTES_DIALOG,
};
