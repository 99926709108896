const countCapRateDecline = ({
  daysElapsed,
  rateInit,
  declineInitDailyNom,
  bFactor,
  defaultRate,
}: {
  daysElapsed: number;
  rateInit: number;
  declineInitDailyNom: number;
  bFactor: number;
  defaultRate: number;
}): { rateInit: number; declineInitDailyNom: number } => {
  if (declineInitDailyNom < 0) {
    const newRateInit =
      rateInit - declineInitDailyNom * daysElapsed > 0
        ? rateInit - declineInitDailyNom * daysElapsed
        : defaultRate;

    return { rateInit: newRateInit, declineInitDailyNom };
  }

  const newRateInit =
    declineInitDailyNom < 0
      ? rateInit
      : bFactor === 0
      ? Math.max(rateInit / Math.exp(declineInitDailyNom * daysElapsed), 0) || 0
      : Math.max(
          rateInit /
            Math.pow(
              1 + bFactor * declineInitDailyNom * daysElapsed,
              1 / bFactor,
            ),
          0,
        ) || 0;
  const newDeclineInit =
    declineInitDailyNom < 0
      ? declineInitDailyNom
      : bFactor === 0
      ? declineInitDailyNom
      : declineInitDailyNom /
          (1 + bFactor * declineInitDailyNom * daysElapsed) || 0;

  return { rateInit: newRateInit, declineInitDailyNom: newDeclineInit };
};

export default countCapRateDecline;
