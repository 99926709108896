import * as React from 'react';

interface ComponentSize {
  readonly left: number;
  readonly top: number;
  readonly right: number;
  readonly bottom: number;
  readonly x: number;
  readonly y: number;
  readonly width: number;
  readonly height: number;
}

const useComponentSize = (ref: React.RefObject<HTMLElement | null>) => {
  const [componentSize, setComponentSize] = React.useState<
    ComponentSize | Record<string, never>
  >(ref.current ? ref.current.getBoundingClientRect() : {});

  const handleResize = React.useCallback(() => {
    if (ref.current) {
      setComponentSize(ref.current.getBoundingClientRect());
    }
  }, [ref]);

  React.useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }
    handleResize();
    if (typeof ResizeObserver === 'function' && ref.current) {
      let resizeObserver = new ResizeObserver(entries => {
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) return;
          handleResize();
        });
      });
      resizeObserver.observe(ref.current);

      return () => {
        if (resizeObserver) {
          resizeObserver.disconnect();
          //@ts-expect-error
          resizeObserver = null;
        }
      };
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref.current, ref, handleResize]); //eslint-disable-line react-hooks/exhaustive-deps

  return componentSize;
};

export default useComponentSize;
