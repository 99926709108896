import Scrollbars from 'react-custom-scrollbars';
import styled from 'styled-components';

export const CommonScrollbar = styled(props => (
  <Scrollbars
    renderThumbVertical={props => <div {...props} className="scrollbar" />}
    renderTrackVertical={props => <div {...props} className="track-vertical" />}
    {...props}
  />
))`
  .scrollbar {
    height: 10px;
    background-color: #484848;
  }

  .track-vertical {
    right: 0px;
    height: 100%;
    width: 8px !important;
  }
`;
