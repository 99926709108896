const hexToRgb = (hex: string) => {
  if (!hex) return null;
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const parsedHex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(parsedHex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const getTextColorFromBgColor = (bgColor: string) => {
  const rgb = hexToRgb(bgColor);
  if (!rgb) return '#484848';
  const o = Math.round((rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000);
  const fore = o > 165 ? '#484848' : 'white';

  return fore;
};

export default getTextColorFromBgColor;
