import React from 'react';
import { TextField } from '@material-ui/core';
import ModalWindow from '.';
import { textIsEmpty, textLengthMatches } from './validators';
import useShortcut, { Code } from 'hooks/useShortcut';

interface EditModalWindowProps {
  close?: () => void;
  title: string;
  onCancel?: () => void;
  onEdit?: (value: string) => void;
  oldValue: string;
}

export const EditModalWindow = ({
  close,
  onCancel,
  onEdit,
  title,
  oldValue,
}: EditModalWindowProps) => {
  const [inputValue, setInputValue] = React.useState(oldValue);
  const [isEditButtonDisabled, setIsEditButtonDisabled] = React.useState(true);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const editAction = React.useCallback(() => {
    if (
      !textLengthMatches(inputValue) ||
      textIsEmpty(inputValue) ||
      oldValue === inputValue
    )
      return;
    onEdit?.call(null, inputValue);
  }, [onEdit, inputValue]);

  const onChange = React.useCallback(
    e => {
      if (!textLengthMatches(e.target.value)) return;
      setInputValue(e.target.value);
    },
    [setInputValue, setIsEditButtonDisabled],
  );

  React.useEffect(() => {
    setIsEditButtonDisabled(inputValue === oldValue || textIsEmpty(inputValue));
  }, [inputValue, setIsEditButtonDisabled]);

  useShortcut(
    cancel => {
      cancel();
      editAction();
    },
    Code.Enter,
    [inputValue, editAction],
  );

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current?.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <ModalWindow
      titleGap={0}
      close={close}
      title={title}
      controls={[
        {
          text: 'Cancel',
          action: () => onCancel?.call(null),
          type: 'danger',
          style: { width: '70px' },
        },
        {
          text: 'Rename',
          action: editAction,
          style: { width: '70px' },
          disabled: isEditButtonDisabled,
        },
      ]}
    >
      <TextField
        autoFocus
        margin="dense"
        id="name"
        type="text"
        inputRef={inputRef}
        fullWidth
        value={inputValue}
        onChange={onChange}
      />
    </ModalWindow>
  );
};
