import * as React from 'react';
import { IconType } from 'react-icons/lib';
import styled from 'styled-components';

type InputProps = {
  id: string;
  name: string;
  label: string;
  disabled?: boolean;
  marginBottom?: number;
  marginTop?: number;
  width?: string;
  paddingLeft?: string;
  Icon?: IconType;
} & { [key: string]: any };

const InputCheckbox = (props: InputProps, ref) => {
  const { Icon, marginBottom, marginTop, width, paddingLeft, ...inputProps } =
    props;

  const onKeyUp = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (e.code !== 'Enter') return;
      (e.target as HTMLElement).click();
    },
    [ref],
  );

  return (
    <InputCheckbox.Wrapper
      paddingLeft={paddingLeft}
      tabIndex="0"
      onKeyUp={onKeyUp}
      marginBottom={marginBottom}
      marginTop={marginTop}
      width={width}
      disabled={inputProps.disabled}
    >
      <InputCheckbox.Input type="checkbox" ref={ref} {...inputProps} />
      <InputCheckbox.Box>
        {Icon ? (
          <Icon />
        ) : (
          <svg width="15px" height="15px" viewBox="0 0 17 17">
            <path
              // eslint-disable-next-line max-len
              d="M7.02218914,8.41326922 L11.7898081,3.50981976 C12.0912028,3.19929778 12.5796074,3.60981059 12.3324547,3.96574126 L7.13044612,11.246725 C6.97339407,11.4728511 6.63614069,11.4434959 6.51971338,11.1935189 L4.43559641,7.36061345 C4.27377833,7.01270957 4.69977066,6.68636335 4.98754811,6.93794578 L7.02218914,8.41326922 Z"
              fill="#FFFFFF"
            />
          </svg>
        )}
      </InputCheckbox.Box>
      <InputCheckbox.Label>{inputProps.label}</InputCheckbox.Label>
    </InputCheckbox.Wrapper>
  );
};

InputCheckbox.Wrapper = styled.label`
  display: block;
  position: relative;
  padding-left: ${({ paddingLeft }) =>
    paddingLeft ? `${paddingLeft}px;` : '24px'};
  line-height: 1;
  font-size: 14px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-weight: normal;
  margin-bottom: ${({ marginBottom }) => (marginBottom ?? 15) + 'px'};
  margin-top: ${({ marginTop }) => (marginTop ?? 0) + 'px'};
  opacity: ${({ disabled }) => (disabled ? '0.7' : '1')};
  ${({ width }) => (width ? `width: ${width};` : '')};

  &:focus {
    outline: none;
  }

  min-height: 16px;
`;

InputCheckbox.Input = styled.input`
  display: none;
`;

InputCheckbox.Box = styled.div`
  display: block;
  position: absolute;
  top: -1px;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid #909090;

  svg {
    opacity: 0;
  }

  label:hover > &,
  label:focus > & {
    border: 1px solid #41ab5d;
    outline: 1px solid #d8f6e0;
    svg {
      opacity: 0.4;
    }
  }

  label:hover > & {
    outline: 3px solid #d8f6e0;
  }

  input:checked ~ & {
    background-color: #41ab5d;
    border: 1px solid #41ab5d;

    svg {
      opacity: 1;
    }
  }

  input:disabled ~ & {
    background-color: #909090;
    border: 1px solid #909090;
    outline: none;
  }
`;

InputCheckbox.Label = styled.p``;

export default React.forwardRef<Element, InputProps>(InputCheckbox);
