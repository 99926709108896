import * as React from 'react';
import styled from 'styled-components';

import Button from 'components/Button';
import { FaTrashAlt } from 'react-icons/fa';

interface NoteImagesProps {
  attachments: any;
  initialNoteAttachments?: any[];
  onAttacmentClick?: (fileId: string) => void;
  deleteExistedNoteAttachment?: (fileId: string) => void;
  deleteTempAttachment: (timestemp: any, fileId: string) => void;
  isEditing: boolean;
  isSentMessage?: boolean;
}

const NoteImages = ({
  attachments,
  onAttacmentClick,
  deleteTempAttachment,
  isEditing,
  deleteExistedNoteAttachment,
  initialNoteAttachments,
  isSentMessage,
}: NoteImagesProps) => {
  if (!attachments.length) return null;
  return (
    <NoteImages.Container isSentMessage={isSentMessage}>
      {attachments.map(data => (
        <NoteImages.Content key={data.id}>
          {isEditing && (
            <NoteImages.ButtonWrapper>
              <NoteImages.Button
                width={28}
                height={28}
                onClick={() => {
                  if (
                    initialNoteAttachments &&
                    deleteExistedNoteAttachment &&
                    initialNoteAttachments.includes(data)
                  ) {
                    deleteExistedNoteAttachment(data.id);
                  } else {
                    deleteTempAttachment(data.timestamp, data.id);
                  }
                }}
              >
                <FaTrashAlt size={16} />
              </NoteImages.Button>
            </NoteImages.ButtonWrapper>
          )}

          <NoteImages.ImageWrapper
            onClick={() =>
              !isEditing && onAttacmentClick && onAttacmentClick(data.id)
            }
          >
            <NoteImages.Image src={data.url} alt={data.name} />
          </NoteImages.ImageWrapper>
        </NoteImages.Content>
      ))}
    </NoteImages.Container>
  );
};

NoteImages.Content = styled.div`
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top: 1px solid rgba(0, 0, 0, 0.05);

  margin-top: 4px;

  :first-child {
    border-top: 0;
    margin-top: 0;
  }
`;

NoteImages.Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
  border: 1px solid #909090;
`;

NoteImages.ImageWrapper = styled.div`
  width: 100%;
  height: 195px;
  background: white;
  overflow: hidden;
  position: relative;
`;

NoteImages.ButtonWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: flex-end;
`;

NoteImages.Button = styled(Button)`
  margin: 8px 8px 0 0;
  padding: 0;
  z-index: 5;

  background: #e7e7e7;
  border: 1px solid #e7e7e7;
  border-radius: 50%;
  overflow: hidden;
`;

NoteImages.Container = styled.div`
  width: 100%;
  position: relative;
  padding: ${({ isSentMessage }) => (isSentMessage ? '0 4px 4px 4px' : 'none')};

  &:hover {
    cursor: pointer;
  }
`;

NoteImages.Spacing = styled.div`
  margin: 5px 0;
  border-bottom: 1px solid #c1c1c1;
`;

export default NoteImages;
