import { utcDay } from 'd3-time';

const getRibbonExtremeDates = (today: Date) => {
  const yesterday = utcDay.offset(today, -1);
  const min = new Date(0);
  const max = yesterday;
  return [min, max];
};

export default getRibbonExtremeDates;
