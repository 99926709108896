import gql from 'graphql-tag';
import type {
  Action,
  GraphqlMutationAction,
  GraphqlQueryAction,
} from 'store/models';
import { PageConfiguration, LatestEventNote, NoteFilter } from './models';

export const namespace = 'INBOX_CONVERSATION';
export const INIT_LATEST_EVENT_NOTES_FETCHING = `${namespace}/INIT_LATEST_EVENT_NOTES_FETCHING`;
export const INIT_BACKGROUND_LATEST_EVENT_NOTES_FETCHING = `${namespace}/INIT_BACKGROUND_LATEST_EVENT_NOTES_FETCHING`;
export const FETCH_ALL_LATEST_EVENT_NOTES = `${namespace}/FETCH_ALL_LATEST_EVENT_NOTES`;
export const FETCH_SENT_LATEST_EVENT_NOTES = `${namespace}/FETCH_SENT_LATEST_EVENT_NOTES`;
export const FETCH_INCOMING_LATEST_EVENT_NOTES = `${namespace}/FETCH_INCOMING_LATEST_EVENT_NOTES`;
export const FETCH_ALL_LATEST_EVENT_NOTES_BACKGROUND = `${namespace}/FETCH_ALL_LATEST_EVENT_NOTES_BACKGROUND`;
export const FETCH_SENT_LATEST_EVENT_NOTES_BACKGROUND = `${namespace}/FETCH_SENT_LATEST_EVENT_NOTES_BACKGROUND`;
export const FETCH_INCOMING_LATEST_EVENT_NOTES_BACKGROUND = `${namespace}/FETCH_INCOMING_LATEST_EVENT_NOTES_BACKGROUND`;
export const FETCH_LATEST_UNREAD_NOTES = `${namespace}/FETCH_LATEST_UNREAD_NOTES`;
export const OPEN_CAPACITY_CONVERSATION = `${namespace}/OPEN_CAPACITY_CONVERSATION`;
export const OPEN_RIBBON_CONVERSATION = `${namespace}/OPEN_RIBBON_CONVERSATION`;
export const OPEN_VARIANCE_CONVERSATION = `${namespace}/OPEN_VARIANCE_CONVERSATION`;
export const OPEN_CONVERSATION = `${namespace}/OPEN_CONVERSATION`;
export const REFETCH_LATEST_EVENT_NOTES = `${namespace}/REFETCH_LATEST_EVENT_NOTES`;
export const RESET_STATE = `${namespace}/RESET_STATE`;
export const SET_SELECTED_NOTE = `${namespace}/SET_SELECTED_NOTE`;
export const SET_ACTIVE_FILTER = `${namespace}/SET_ACTIVE_FILTER`;
export const MARK_AS_READ = `${namespace}/MARK_AS_READ`;
export const MARK_AS_UNREAD = `${namespace}/MARK_AS_UNREAD`;
export const MOVE_TO_INBOX = `${namespace}/MOVE_TO_INBOX`;
export const ARCHIVE = `${namespace}/ARCHIVE`;
export const GET_LATEST_UNREAD_NOTES = `${namespace}/GET_LATEST_UNREAD_NOTES`;
export const SET_URL_PARAMETERS = `${namespace}/SET_URL_PARAMETERS `;
export const GO_TO_PAGE = `${namespace}/GO_TO_PAGE`;

const FETCH_ALL_LATEST_EVENT_NOTES_QUERY = gql`
  query ($payload: LatestEventNotesIntervalInput!) {
    listAllLatestEventNotes(data: $payload) {
      authors
      eventType
      eventId
      noteTimestamp
      noteText
      hasAttachedPhotos
      hasAttachedFiles
      inbox
      read
      wellId
      wellName
      dateStart
      dateEnd
      urlSearch
      isWatching
    }
  }
`;

type fetchAllLatestEventNotesAC = (
  payload: PageConfiguration,
) => GraphqlQueryAction;

export const fetchAllLatestEventNotes: fetchAllLatestEventNotesAC = (
  payload: PageConfiguration,
) => ({
  type: FETCH_ALL_LATEST_EVENT_NOTES,
  payload: {
    key: 'listAllLatestEventNotes',
    graphql: {
      query: FETCH_ALL_LATEST_EVENT_NOTES_QUERY,
      variables: {
        payload,
      },
    },
  },
});

type fetchAllLatestEventNotesBackgroundAC = (payload: {
  count: number;
  fromDate: Date;
}) => GraphqlQueryAction;

export const fetchAllLatestEventNotesBackground: fetchAllLatestEventNotesBackgroundAC =
  (payload: { count: number; fromDate: Date }) => ({
    type: FETCH_ALL_LATEST_EVENT_NOTES_BACKGROUND,
    payload: {
      key: 'listAllLatestEventNotes',
      graphql: {
        query: FETCH_ALL_LATEST_EVENT_NOTES_QUERY,
        variables: {
          payload,
        },
      },
    },
  });

const FETCH_SENT_LATEST_EVENT_NOTES_QUERY = gql`
  query ($payload: LatestEventNotesIntervalInput!) {
    listSentLatestEventNotes(data: $payload) {
      authors
      eventType
      eventId
      noteTimestamp
      noteText
      hasAttachedPhotos
      hasAttachedFiles
      inbox
      read
      wellId
      wellName
      dateStart
      dateEnd
      urlSearch
      isWatching
    }
  }
`;

type fetchSentLatestEventNotesAC = (
  payload: PageConfiguration,
) => GraphqlQueryAction;

export const fetchSentLatestEventNotes: fetchSentLatestEventNotesAC = (
  payload: PageConfiguration,
) => ({
  type: FETCH_SENT_LATEST_EVENT_NOTES,
  payload: {
    key: 'listSentLatestEventNotes',
    graphql: {
      query: FETCH_SENT_LATEST_EVENT_NOTES_QUERY,
      variables: {
        payload,
      },
    },
  },
});

type fetchSentLatestEventNotesBackgroundAC = (payload: {
  count: number;
  fromDate: Date;
}) => GraphqlQueryAction;

export const fetchSentLatestEventNotesBackground: fetchSentLatestEventNotesBackgroundAC =
  (payload: { count: number; fromDate: Date }) => ({
    type: FETCH_SENT_LATEST_EVENT_NOTES_BACKGROUND,
    payload: {
      key: 'listSentLatestEventNotes',
      graphql: {
        query: FETCH_SENT_LATEST_EVENT_NOTES_QUERY,
        variables: {
          payload,
        },
      },
    },
  });

const FETCH_INCOMING_LATEST_EVENT_NOTES_QUERY = gql`
  query ($payload: LatestEventNotesIntervalInput!) {
    listIncomingLatestEventNotes(data: $payload) {
      authors
      eventType
      eventId
      noteTimestamp
      noteText
      hasAttachedPhotos
      hasAttachedFiles
      inbox
      read
      wellId
      wellName
      dateStart
      dateEnd
      urlSearch
      isWatching
    }
  }
`;

type fetchIncomingLatestEventNotesAC = (
  payload: PageConfiguration,
) => GraphqlQueryAction;

export const fetchIncomingLatestEventNotes: fetchIncomingLatestEventNotesAC = (
  payload: PageConfiguration,
) => ({
  type: FETCH_INCOMING_LATEST_EVENT_NOTES,
  payload: {
    key: 'listIncomingLatestEventNotes',
    graphql: {
      query: FETCH_INCOMING_LATEST_EVENT_NOTES_QUERY,
      variables: {
        payload,
      },
    },
  },
});

type fetchIncomingLatestEventNotesBackgroundAC = (payload: {
  count: number;
  fromDate: Date;
}) => GraphqlQueryAction;

export const fetchIncomingLatestEventNotesBackground: fetchIncomingLatestEventNotesBackgroundAC =
  (payload: { count: number; fromDate: Date }) => ({
    type: FETCH_INCOMING_LATEST_EVENT_NOTES_BACKGROUND,
    payload: {
      key: 'listIncomingLatestEventNotes',
      graphql: {
        query: FETCH_INCOMING_LATEST_EVENT_NOTES_QUERY,
        variables: {
          payload,
        },
      },
    },
  });
const FETCH_LATEST_UNREAD_NOTES_QUERY = gql`
  query {
    getUnreadConversationsCount
  }
`;

type fetchLatestUnreadNotesAC = () => GraphqlQueryAction;

export const fetchLatestUnreadNotes: fetchLatestUnreadNotesAC = () => ({
  type: FETCH_LATEST_UNREAD_NOTES,
  payload: {
    key: 'getUnreadConversationsCount',
    graphql: {
      query: FETCH_LATEST_UNREAD_NOTES_QUERY,
    },
  },
});

type initLatestEventNotesFetchingAC = () => Action;

export const initLatestEventNotesFetching: initLatestEventNotesFetchingAC =
  () => ({
    type: INIT_LATEST_EVENT_NOTES_FETCHING,
  });

type initBackgroundLatestEventNotesFetchingAC = () => Action;

export const initBackgroundLatestEventNotesFetching: initBackgroundLatestEventNotesFetchingAC =
  () => ({
    type: INIT_BACKGROUND_LATEST_EVENT_NOTES_FETCHING,
  });

type openConversationAC = (payload: LatestEventNote) => Action;

export const openConversation: openConversationAC = (
  payload: LatestEventNote,
) => ({
  type: OPEN_CONVERSATION,
  payload,
});

type openCapacityConversationAC = (payload: {
  id: string;
  wellId: string;
}) => Action;

export const openCapacityConversation: openCapacityConversationAC = (payload: {
  id: string;
  wellId: string;
}) => ({
  type: OPEN_CAPACITY_CONVERSATION,
  payload,
});

type openRibbonConversationAC = (payload: { id: string }) => Action;

export const openRibbonConversation: openRibbonConversationAC = (payload: {
  id: string;
}) => ({
  type: OPEN_RIBBON_CONVERSATION,
  payload,
});

type openVarianceConversationAC = (payload: {
  id: string;
  wellId: string;
}) => Action;

export const openVarianceConversation: openVarianceConversationAC = (payload: {
  id: string;
  wellId: string;
}) => ({
  type: OPEN_VARIANCE_CONVERSATION,
  payload,
});

type setSelectedNoteAC = (payload: LatestEventNote | null) => Action;

export const setSelectedNote: setSelectedNoteAC = (
  payload: LatestEventNote | null,
) => ({
  type: SET_SELECTED_NOTE,
  payload,
});

type refetchEventNotesAC = () => Action;

export const refetchEventNotes: refetchEventNotesAC = () => ({
  type: REFETCH_LATEST_EVENT_NOTES,
});

type setActiveFilterAC = (payload: NoteFilter) => Action;

export const setActiveFilter: setActiveFilterAC = (payload: NoteFilter) => ({
  type: SET_ACTIVE_FILTER,
  payload,
});

export const resetState: any = () => ({
  type: RESET_STATE,
});

type setUrlParametersAC = (payload: string) => Action;

export const setUrlParameters: setUrlParametersAC = payload => ({
  type: SET_URL_PARAMETERS,
  payload,
});

const ARCHIVE_MUTATION = gql`
  mutation ($payload: [ConversationInput!]) {
    archiveConversations(data: $payload)
  }
`;

type archiveAC = (
  payload: {
    eventId: string;
    eventType: string;
  }[],
) => GraphqlMutationAction;

export const archive: archiveAC = (
  payload: {
    eventId: string;
    eventType: string;
  }[],
) => ({
  type: ARCHIVE,
  payload: {
    key: 'archiveConversations',
    graphql: {
      mutation: ARCHIVE_MUTATION,
      variables: {
        payload,
      },
    },
  },
});

const MARK_AS_READ_MUTATION = gql`
  mutation ($payload: [ConversationInput!]) {
    readConversations(data: $payload)
  }
`;

type markAsReadAC = (
  payload: {
    eventId: string;
    eventType: string;
  }[],
) => GraphqlMutationAction;

export const markAsRead: markAsReadAC = (
  payload: {
    eventId: string;
    eventType: string;
  }[],
) => ({
  type: MARK_AS_READ,
  payload: {
    key: 'readConversations',
    graphql: {
      mutation: MARK_AS_READ_MUTATION,
      variables: {
        payload,
      },
    },
  },
});

const MARK_AS_UNREAD_MUTATION = gql`
  mutation ($payload: [ConversationInput!]) {
    unreadConversations(data: $payload)
  }
`;

type markAsUnreadAC = (
  payload: {
    eventId: string;
    eventType: string;
    isWatching: boolean;
  }[],
) => GraphqlMutationAction;

export const markAsUnread: markAsUnreadAC = (
  payload: {
    eventId: string;
    eventType: string;
    isWatching: boolean;
  }[],
) => ({
  type: MARK_AS_UNREAD,
  payload: {
    key: 'unreadConversations',
    graphql: {
      mutation: MARK_AS_UNREAD_MUTATION,
      variables: {
        payload,
      },
    },
  },
});

const MOVE_TO_INBOX_MUTATION = gql`
  mutation ($payload: [ConversationInput!]) {
    moveConversationsToInbox(data: $payload)
  }
`;

type moveToInboxAC = (
  payload: {
    eventId: string;
    eventType: string;
    isWatching: boolean;
  }[],
) => GraphqlMutationAction;

export const moveToInbox: moveToInboxAC = (
  payload: {
    eventId: string;
    eventType: string;
    isWatching: boolean;
  }[],
) => ({
  type: MOVE_TO_INBOX,
  payload: {
    key: 'moveConversationsToInbox',
    graphql: {
      mutation: MOVE_TO_INBOX_MUTATION,
      variables: {
        payload,
      },
    },
  },
});

export const getLatestUnreadNotes: any = () => ({
  type: GET_LATEST_UNREAD_NOTES,
});

type goToPageAC = (payload: number) => Action;

export const goToPage: goToPageAC = (payload: number) => ({
  type: GO_TO_PAGE,
  payload,
});
